import { memo, useCallback, useMemo } from 'react'

import { Badge, FilterAccordionPanel, MultiSelectControl } from '@cutover/react-ui'
import { useRunbookUserFilter, useRunbookVersionUsers, useUserTasksCountState } from 'main/recoil/data-access'

export const UserGroup = memo(() => {
  const [value, setValue] = useRunbookUserFilter()
  const users = useRunbookVersionUsers()
  const userTasksCount = useUserTasksCountState()
  const options = useMemo(
    () =>
      users.map(user => ({
        label: user.name,
        value: user.id,
        suffix: <Badge label={userTasksCount[user.id] ?? 0} textColor="white" color={user.color} />
      })),
    [userTasksCount, users]
  )

  const handleChange = useCallback(
    (val: any) => {
      // need to preserve none checked if it was also selected
      if (!val || val.length === 0) {
        setValue(prevVal => (prevVal?.includes(0) ? [0] : undefined))
      } else {
        const nextVals = val?.map(v => v.value)
        setValue(prevVal => (prevVal?.includes(0) ? [0, ...nextVals] : nextVals))
      }
    },
    [setValue]
  )

  return users?.length ? (
    <FilterAccordionPanel label="User" applied={value && !!value.length}>
      <MultiSelectControl
        a11yTitle="User"
        value={(value as any[])?.filter(v => v?.value !== 0 && v?.value !== '*' && v?.value !== null)}
        onChange={handleChange}
        options={options}
        plain
      />
    </FilterAccordionPanel>
  ) : null
})
