import { ChangeEvent, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Controller, useForm } from 'react-hook-form'
import { isEmpty } from 'lodash'

import { Box, Icon, IconButton, SettableFieldType, Text, TextArea, TextEditor, themeColor } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { useRunbookCommentCreate } from 'main/services/queries/use-runbook-comments'
import { isOnlyHTMLTags, isWhitespaceOnly } from '../activity/activity-helper'
import { CommentModel } from 'main/data-access'

export type ActivityFeedInputProps = {
  runbookId: string | number
  disabled?: boolean
  allowAttachments?: boolean
}

type NewActivityMessageType = { comment: string }

export const ActivityFeedInput = ({ runbookId, disabled, allowAttachments = false }: ActivityFeedInputProps) => {
  const { t } = useLanguage('activities')
  const commentInputRef = useRef<HTMLTextAreaElement | null>(null)
  const textEditorRef = useRef<SettableFieldType & { blur: () => void }>(null)
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const [file, setFile] = useState<File | undefined>()
  const [forceReRenderKey, setForceReRenderKey] = useState<number>(0)
  const [showTextEditor, setShowTextEditor] = useState<boolean>(false)
  const [comment, setComment] = useState('')
  const [touched, setTouched] = useState(false)

  const processCommentCreatedResponse = CommentModel.useOnAction('create')

  const { mutateAsync, isLoading } = useRunbookCommentCreate({ runbookId })
  const fileType = file?.type.startsWith('image/') ? 'image' : null
  const { handleSubmit, control, watch, reset } = useForm<NewActivityMessageType>()
  const textEditorCommentValue = watch('comment')

  const canSubmit =
    showTextEditor && !isLoading
      ? (!isEmpty(textEditorCommentValue?.trim()) && !isWhitespaceOnly(textEditorCommentValue)) || file
      : (!isEmpty(comment?.trim()) && !isWhitespaceOnly(comment)) || file

  const handleClickUpload = () => {
    fileInputRef.current?.click()
  }

  const sendComment = async () => {
    setTouched(true)
    await mutateAsync(
      { comment: comment.trim(), file },
      {
        onSuccess: res => {
          reset()
          if (res) processCommentCreatedResponse(res)
        }
      }
    )
    setComment('')
    setFile(undefined)
  }

  const onChangeHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.target.value)
  }

  useEffect(() => {
    if (commentInputRef.current && touched) {
      commentInputRef.current.focus()
    }
  }, [touched, comment])

  const onKeyDownHandler = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && comment.trim() && !e.shiftKey) {
      sendComment()
    }
  }

  const sendCommentFromEditor = async (data: NewActivityMessageType) => {
    await mutateAsync(
      { comment: data.comment?.trim(), file },
      {
        onSuccess: () => {
          textEditorRef.current?.clear()
          setFile(undefined)
          reset()
          setForceReRenderKey(i => i + 1)
          setTimeout(() => {
            textEditorRef.current?.focus()
          })
        }
      }
    )
    setComment('')
    setFile(undefined)
  }

  const handlePasteFile = (data: DataTransfer) => {
    if (!allowAttachments) return
    const fileList = data.files
    if (fileList && fileList.length > 0) {
      setFile(fileList[0])
    }
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e?.target.files
    if (fileList && !!fileList.length) {
      setFile(fileList[0])

      e.target.value = ''
    }
  }

  const handleToggle = () => {
    if (isOnlyHTMLTags(comment)) setComment('')
    setShowTextEditor(!showTextEditor)
  }

  return (
    <ActivityFeedInputWrapper>
      <Box
        direction="column"
        css={`
          flex: 1 1 auto;
          padding-bottom: ${file ? '32px' : undefined};
          max-height: 100%;
          min-width: 0;
          position: relative;
        `}
      >
        <Box
          css={`
            height: 100%;
            overflow: hidden;
            border-radius: ${!file ? '0 0 8px 8px' : undefined};
          `}
        >
          {showTextEditor ? (
            <Box overflow={{ horizontal: 'hidden', vertical: 'scroll' }}>
              <Controller
                name="comment"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <TextEditor
                    name="comment"
                    value={comment ?? value}
                    onChange={(value?: string) => {
                      setComment(value ?? '')
                      onChange(value)
                    }}
                    onPasteFile={handlePasteFile}
                    key={forceReRenderKey}
                    ref={textEditorRef}
                    disabled={isLoading || disabled}
                    placeholder={t('input.comment.placeholder')}
                    aria-label={t('input.comment.placeholder')}
                    aria-describedby="instructions"
                    plain
                    restrictedMode
                    invertColor
                  />
                )}
              />
            </Box>
          ) : (
            <TextArea
              ref={commentInputRef}
              plain={true}
              disabled={isLoading || disabled}
              data-testid="activityInput"
              placeholder={t('input.comment.placeholder')}
              aria-label={t('input.comment.placeholder')}
              aria-describedby="instructions"
              value={comment}
              alwaysShowPlaceholder={true}
              onChange={onChangeHandler}
              onKeyDown={onKeyDownHandler}
              css={`
                background: ${themeColor('bg-1')} !important;
              `}
              style={{
                outline: 'none',
                paddingLeft: '10px',
                boxSizing: 'border-box',
                borderRadius: `${file ? '8px 8px 0 0' : '8px'}`
              }}
            />
          )}
        </Box>
        {allowAttachments && file && (
          <Box
            background="bg-1"
            direction="row"
            align="center"
            justify-content="flex-start"
            gap="xsmall"
            pad={{ right: '12px', left: '12px' }}
            css={`
              flex-shrink: 0;
              border-radius: 0 0 8px 8px;
              width: 100%;
              position: absolute;
              bottom: 0;
              z-index: 1;
            `}
          >
            <Icon size="small" icon={fileType === 'image' ? 'image' : 'page-text'} color="primary" />
            <Text
              color="primary"
              size="13px"
              truncate="tip"
              tipPlacement="top"
              css="line-height: 32px; white-space: nowrap; max-width: 100%; text-overflow: ellipsis; overflow: hidden;"
            >
              {file.name}
            </Text>
            <IconButton
              data-testid="removeFileSelection"
              size="small"
              icon="close"
              label={t('feed.removeSelection')}
              onClick={() => setFile(undefined)}
            />
          </Box>
        )}
      </Box>
      <IconButton
        data-testid="toggleInput"
        disabled={isLoading || disabled}
        size="medium"
        icon="code-block"
        label={t('feed.toggleEditor')}
        css={`
          background: ${showTextEditor ? themeColor('bg-1') : 'transparent'};
        `}
        onClick={handleToggle}
      />
      {allowAttachments && (
        <>
          {/* TODO: WIP feature, using plain input here as a temporary solution. FileInputField within the Modal will be used instead */}
          <input
            data-testid="fileInput"
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
          <IconButton
            data-testid="uploadFileButton"
            size="medium"
            icon="attachment"
            disabled={isLoading || disabled}
            label={t('feed.uploadFile')}
            onClick={handleClickUpload}
          />
        </>
      )}
      <IconButton
        primary
        disabled={!canSubmit}
        data-testid="sendCommentButton"
        size="medium"
        icon={isLoading ? 'spinner' : 'send'}
        label={t('feed.send')}
        onClick={showTextEditor ? handleSubmit(sendCommentFromEditor) : handleSubmit(sendComment)}
      />
    </ActivityFeedInputWrapper>
  )
}

const ActivityFeedInputWrapper = styled.form.attrs(() => ({
  noValidate: true,
  role: 'form'
}))`
  display: flex;
  flex-direction: row;
  gap: 8px;
  min-width: 0;
  min-height: 0;
  padding: 8px 16px 8px 0;
  align-items: flex-end;
  width: 100%;
  max-height: 60%;
  overflow-y: auto;
`
