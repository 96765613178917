import { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'

import { Box, breakpoints } from '@cutover/react-ui'
import {
  CommentsWidget,
  CompletedLiveRunsWidget,
  ContentWidget,
  CustomFieldSummaryWidget,
  MonthlyActiveUsersWidget,
  RunbookAverageDurationWidget,
  RunbooksCreatedWidget,
  TaskCompletionUserCountWidget,
  TasksCompletedWidget,
  TaskWastageWidget
} from './widgets'
import { TaskCompletionOverTimeWidget } from './widgets/runbook/task-completion-over-time/task-completion-over-time-widget'
import { RtoStatusWidget } from './widgets/account/rto-status'
import { RtoConfigurationStatusWidget } from './widgets/account/rto-configuration-status'
import { DashboardComponent, DashboardComponentCollection, DashboardMediaType, DashboardWidgets } from './widgets/types'
import {
  // conflicting names between SRD and MRD Rag Status components
  AttributeCompletionWidget,
  AverageRunbookDurationOverTimeWidget,
  AverageRunbookDurationWidget,
  CompletionByStageWidget,
  LatenessWidget,
  LongestAverageTaskDurationSingleWidget,
  LongestAverageTaskDurationsWidget,
  LongestDurationRunbooksWidget,
  OverallTimingsWidget,
  ParticularTaskAverageDurationWidget,
  PerformanceVsPlannedWidget,
  PlannedVsActualDurationWidget,
  RagStatusWidget,
  RehearsalCountWidget,
  RunbookAdminWidget,
  RunbookCompletionWidget,
  RunbookCountByTypeOverTimeWidget,
  RunbookListWidget,
  RunbookMatrixWidget,
  RunbookRagStatusWidget,
  RunbookSizeSummaryWidget,
  RunbookSplitWidget,
  RunbookStageSummaryWidget,
  RunbookSummaryWidget,
  StreamSummaryWidget,
  TaskAttributeWastageWidget,
  TaskCompletionSummaryWidget,
  TaskListWidget,
  TaskSplitWidget,
  TaskStageSummaryWidget,
  TaskWastageStatisticsWidget,
  UserParticipationWidget,
  ValueRealisationWidget,
  VolumeTableWidget
} from 'main/components/dashboards/widgets'
import { useUserWebsocket } from 'main/services/hooks/websockets/use-user-websocket'
import { getFilterParams } from './widgets/account/mrd-filter-util'
import { DashboardContext } from './runbooks-dashboard-page'
import { RightPanelType, RightPanelTypeDefinition, useActiveRightPanelState } from '../layout/right-panel'

type DasboardWidgetCollectionProps = {
  components: DashboardComponentCollection
  media: DashboardMediaType
  requestId?: string
  isPir?: boolean
  readOnly?: boolean
}

const TestComponent = (component: any) => (
  <div style={{ border: '1px solid green' }}>
    <pre>{JSON.stringify(component)}</pre>
  </div>
)

export const RESOURCE_TYPE_FILTER_MAP: Record<string, string> = {
  Stage: 'stage',
  Team: 'team_id',
  TaskType: 'task_type_id',
  Level: 'level',
  Runbook: 'runbook_id',
  Stream: 'parent_stream_name',
  CustomField: 'field_option_id'
}

const DASHBOARD_RIGHT_PANELS = ['dashboard-task-list', 'dashboard-runbook-list']

const ACCOUNT_DASHBOARD_WIDGETS: Record<string, DashboardWidgets> = {
  // Analytics
  completed_live_runs: CompletedLiveRunsWidget,
  monthly_active_users: MonthlyActiveUsersWidget,
  runbook_template_creation_counts: RunbooksCreatedWidget,
  runbook_average_duration: RunbookAverageDurationWidget,
  tasks_completed_count: TasksCompletedWidget,
  user_count_task_completion: TaskCompletionUserCountWidget,
  // MRD
  average_runbook_duration: AverageRunbookDurationWidget,
  average_runbook_duration_over_time: AverageRunbookDurationOverTimeWidget,
  lateness: LatenessWidget,
  longest_average_task_duration_single: LongestAverageTaskDurationSingleWidget,
  longest_average_task_durations: LongestAverageTaskDurationsWidget,
  longest_duration_runbooks: LongestDurationRunbooksWidget,
  particular_task_average_duration: ParticularTaskAverageDurationWidget,
  rag_status: RagStatusWidget,
  rto_status: RtoStatusWidget,
  rto_configuration_status: RtoConfigurationStatusWidget,
  rehearsal_count: RehearsalCountWidget,
  runbook_attribute_completion: RunbookCompletionWidget,
  runbook_attribute_split: RunbookSplitWidget,
  runbook_count_by_type_over_time: RunbookCountByTypeOverTimeWidget,
  runbook_list: RunbookListWidget,
  runbook_matrix: RunbookMatrixWidget,
  runbook_size: RunbookSizeSummaryWidget,
  runbook_stage: RunbookStageSummaryWidget,
  stream_completion: AttributeCompletionWidget,
  task_attribute_split: TaskSplitWidget,
  task_stage: TaskStageSummaryWidget,
  task_attribute_completion: AttributeCompletionWidget,
  volume: VolumeTableWidget,
  // test for back end specs
  test: TestComponent
}

const RUNBOOK_DASHBOARD_WIDGETS: Record<string, DashboardWidgets> = {
  // SRD
  burn_chart: TaskCompletionOverTimeWidget,
  comments: CommentsWidget,
  header: TaskCompletionSummaryWidget,
  status: RunbookRagStatusWidget,
  stage_summary: CompletionByStageWidget,
  streams: StreamSummaryWidget,
  task_list: TaskListWidget,
  custom_field_donut: CustomFieldSummaryWidget,
  admins: RunbookAdminWidget,
  content: ContentWidget,
  timings_summary: OverallTimingsWidget,
  // PIR
  lateness: LatenessWidget,
  task_wastage: TaskWastageWidget,
  task_attribute_wastage: TaskAttributeWastageWidget,
  user_participation: UserParticipationWidget,
  runbook_summary: RunbookSummaryWidget,
  burndown_chart: PlannedVsActualDurationWidget,
  value_realisation: ValueRealisationWidget,
  performance_planned: PerformanceVsPlannedWidget,
  task_wastage_statistics: TaskWastageStatisticsWidget,
  // test for back end specs
  test: TestComponent
}

export type ActiveDashboardItemType = {
  componentId: number
  itemId: string | number
  subFilterKey?: string
  subFilterValue?: string | number
}

export type DashboardItemClickProps = ActiveDashboardItemType & {
  panelType: RightPanelType
  params?: Record<string, any>
}

type DashboardRightPanelType =
  | RightPanelTypeDefinition<'dashboard-runbook-list'>
  | RightPanelTypeDefinition<'dashboard-task-list'>

// merge the current array of components
const mergeComponentLists = (currentComponentsState: DashboardComponent[], newComponents: DashboardComponent[]) => {
  return currentComponentsState.map((component: DashboardComponent) => {
    const newComponent = newComponents.find((newComponent: DashboardComponent) => newComponent.id === component.id)
    return newComponent ? newComponent : component
  })
}

export const StatefulAccountWidgetCollection = ({ components, media, requestId }: DasboardWidgetCollectionProps) => {
  const [componentsState, setComponentsState] = useState<DashboardComponent[]>(components)
  const { accountId, filterParams } = useContext(DashboardContext)
  const [activeRightPanel, { updateRightPanel, closeRightPanel, openRightPanel }] = useActiveRightPanelState()
  const activeItem = (activeRightPanel as DashboardRightPanelType)?.activeItem

  useEffect(() => {
    if (activeRightPanel && DASHBOARD_RIGHT_PANELS.includes(activeRightPanel?.type)) {
      // reload right panel when context filter changes
      updateRightPanel({
        type: activeRightPanel?.type,
        // @ts-ignore - wierd type error on the below, can't fix
        params: activeRightPanel?.params,
        activeItem,
        contextParams: getRunbookFilterParams()
      } as DashboardRightPanelType)
    }
  }, [filterParams])

  const { listen } = useUserWebsocket()
  useEffect(() => {
    listen(data => handleWebsocketResponse(data))
  })

  const getRunbookFilterParams = () => {
    return {
      accountId,
      template_type: 'off',
      ...getFilterParams(filterParams)
    }
  }

  const handleWebsocketResponse = (data: any) => {
    if (data?.meta?.headers?.request_method === 'dashboard_results') {
      const { request_id, components } = data.messages[0]
      if (!requestId || request_id == requestId) {
        const mergedComponents = mergeComponentLists(componentsState, components)
        setComponentsState(mergedComponents)
      } else {
        console.log('dashboard-widget-collection handleWebsocketResponse ignored stale request', requestId, request_id)
      }
    }
  }

  // Note: this should be a generic function that 'drills down' on any segment of a dash comp
  // In order to indicate clicked items, as well as open right panel, need to store both
  // exactly what was clicked, plus any custom params the right panel may need (so it can be reloaded)
  const onClickDashboardItem = ({
    componentId,
    itemId,
    subFilterKey,
    subFilterValue,
    panelType: type,
    params
  }: DashboardItemClickProps) => {
    if (isSameClicked(componentId, itemId, subFilterKey, subFilterValue)) {
      closeRightPanel()
    } else {
      openRightPanel({
        type,
        params,
        activeItem: { componentId, itemId, subFilterKey, subFilterValue },
        contextParams: getRunbookFilterParams()
      } as DashboardRightPanelType)
    }
  }

  const isSameClicked = (
    componentId: number,
    itemId: string | number,
    subFilterKey?: string,
    subFilterValue?: string | number
  ) => {
    return (
      activeItem &&
      activeItem.componentId === componentId &&
      activeItem.itemId === itemId &&
      activeItem.subFilterKey === subFilterKey &&
      activeItem.subFilterValue === subFilterValue
    )
  }

  return componentsState ? (
    <WidgetGrid direction="row">
      {componentsState.map((component, i) => {
        const { type, id } = component
        const Component = ACCOUNT_DASHBOARD_WIDGETS[type]

        return Component ? (
          <Component
            key={id}
            media={media}
            data={component}
            activeItem={activeItem}
            onClickItem={onClickDashboardItem}
          />
        ) : (
          <NoComponentMessage key={id || i} />
        )
      })}
    </WidgetGrid>
  ) : (
    <NoComponentMessage />
  )
}

// (original) without the useState/useEffect complexity that may cause issues for SSR rendering:
export const AccountWidgetCollection = ({ components, media }: DasboardWidgetCollectionProps) => {
  return components ? (
    <WidgetGrid direction="row">
      {components.map((component, i) => {
        const { type, id } = component
        const Component = ACCOUNT_DASHBOARD_WIDGETS[type]

        return Component ? <Component key={id} media={media} data={component} /> : <NoComponentMessage key={id || i} />
      })}
    </WidgetGrid>
  ) : (
    <NoComponentMessage />
  )
}

export const RunbookWidgetCollection = ({
  components,
  media,
  readOnly,
  isPir = false
}: DasboardWidgetCollectionProps) => {
  return components && components.length ? (
    <WidgetGrid direction="row">
      {components.map((component, i) => {
        const { type, id } = component
        const Component = RUNBOOK_DASHBOARD_WIDGETS[type]

        return Component ? (
          <Component key={id} media={media} data={component} readOnly={readOnly} isPir={isPir} />
        ) : (
          <NoComponentMessage key={id || i} />
        )
      })}
    </WidgetGrid>
  ) : (
    <NoComponentMessage />
  )
}

const NoComponentMessage = () => {
  // TODO: future improvement on no component message (e.g. please add components via settings)
  return <></>
}

const WidgetGrid = styled(Box)`
  flex-wrap: wrap;
  @media ${breakpoints.sm} {
    padding: 0;
    padding-top: 12px;
  }
`
