import { memo, Ref, SyntheticEvent, useCallback, useEffect, useMemo, useRef } from 'react'

import { AvatarSubject, DependencyType, TaskItem, useLogPropsChanged } from '@cutover/react-ui'
import { useDropAssignment, useTaskListTaskUsers } from 'main/recoil/data-access'
import { useForceRerenderOnTimeout, useLanguage } from 'main/services/hooks'
import { useToggleRightPanel } from 'main/components/layout/right-panel'
import { TaskItemCreateToggle } from './task-item-create'
import { useTaskListItemProps } from './task-list-item-props'
import { useTaskListItemIntegrationStatus } from './use-task-list-item-integration-status'
import {
  ActiveRunbookModel,
  ActiveRunbookVersionModel,
  CommentModel,
  CurrentRunbookVersionModel,
  FieldValueModel,
  RunbookComponentModel,
  RunbookTeamModel,
  RunbookTypeModel,
  RunbookViewModel,
  StreamModel,
  TaskModel,
  TaskProgressionViewModel,
  TaskTypeModel
} from 'main/data-access'
import { useRouting } from 'main/services/routing'
import { getTask } from 'main/services/queries/use-task'
import { RunbookComponent } from 'main/services/queries/types'
import { RunbookViewStateType } from 'main/recoil/runbook'

type TaskContentProps = {
  id: number
  previousTaskId?: number
  nextTaskId?: number
  isEditing?: boolean
  isFocused?: boolean
  isFaded?: boolean
  activeTimezone?: string | null
  // index: number
  onFocus?: (index: number) => void
}

// TODO: remove when transaction updates have been made to recoil data updating
export const TaskListItem = memo(({ id, ...props }: TaskContentProps) => {
  const { active: activeModal } = RunbookViewModel.useGet('modal')
  const isUpdating = RunbookViewModel.useGet('loadingIds')[id] || false
  const isActioning = activeModal?.type === 'task-action' ? id === activeModal.id : undefined
  const isLoading = isActioning || isUpdating
  const selectedIds = RunbookViewModel.useGet('selectedIds')
  const isSelected = selectedIds.includes(id)
  const { open: isMenuOpen, taskId: menuTaskId, type: menuType } = RunbookViewModel.useGet('menu')

  const task = TaskModel.useGet(id)
  useLogPropsChanged({ ...props, id }, `TaskListItem - ${task.internal_id}`)
  if (!task) return null

  return (
    <TaskListItemInner
      id={id}
      {...props}
      isSelected={isSelected}
      isLoading={isLoading}
      isUpdating={isUpdating}
      menu={isMenuOpen && id === menuTaskId ? menuType : undefined}
    />
  )
})

type TaskContentInnerProps = TaskContentProps & {
  isLoading?: boolean
  isUpdating?: boolean
  menu?: RunbookViewStateType['menu']['type']
  isSelected?: boolean
}

const TaskListItemInner = memo((props: TaskContentInnerProps) => {
  const {
    isEditing,
    id,
    previousTaskId,
    nextTaskId,
    activeTimezone,
    isFocused,
    onFocus,
    // index,
    isSelected,
    isLoading,
    isUpdating
  } = props

  const first = !previousTaskId
  const ref = useRef<HTMLInputElement>(null)

  const toggleActionsMenu = RunbookViewModel.useAction('taskMenu:toggle:actions')
  const toggleDependenciesMenu = RunbookViewModel.useAction('taskMenu:toggle:dependencies')

  const { toRunbook } = useRouting()

  const toggleSelectedIds = RunbookViewModel.useAction('selectedIds:toggle')
  const deselectAllIds = RunbookViewModel.useAction('selectedIds:removeAll')

  const notify = RunbookViewModel.useAction('notify')

  const taskOverride = TaskModel.useGetOverride(id)
  const togglePeoplePanel = useToggleRightPanel(
    'user-team-details-edit',
    useCallback((openPanel: any, openingPanel: any) => openPanel.userOrTeam?.id === openingPanel.userOrTeam?.id, [])
  )
  const toggleCommentsPanel = useToggleRightPanel('runbook-comments')
  const toggleTaskEditPanel = useToggleRightPanel('task-edit')
  const { t } = useLanguage('runbook', { keyPrefix: 'taskListItem' })
  const openModal = RunbookViewModel.useAction('modal:open')
  const toggleTaskCreate = RunbookViewModel.useAction('taskCreate:toggle')

  const runbookVersion = ActiveRunbookVersionModel.useGet()
  const task = TaskModel.useGet(id)
  useLogPropsChanged(props, `TaskListItemInner - ${task.internal_id}`)
  const stream = StreamModel.useGet(task.stream_id)
  const runbookCurrentVersion = CurrentRunbookVersionModel.useGet()
  const runbook = ActiveRunbookModel.useGet()
  const userCanUpdate = ActiveRunbookModel.useCan('update')
  const taskComments = CommentModel.useGetAllBy({ taskInternalId: task.internal_id })
  const commentsCount = taskComments.length
  const parentStream = StreamModel.useGet(stream?.parent_id || 0)
  const previousTask = TaskModel.useGet(previousTaskId || 0)
  const previousTaskType = TaskTypeModel.useGet(previousTask?.task_type_id)
  const runbookComponent = RunbookComponentModel.useGet(task.runbook_component_id || 0) as RunbookComponent | undefined
  const teams = RunbookTeamModel.useGetAllBy({ taskId: id })
  const users = useTaskListTaskUsers(id)
  const taskType = TaskTypeModel.useGet(task.task_type_id)
  const fieldValues = FieldValueModel.useGetAllBy({ taskId: id }, { scope: 'extended' })
  const showTaskDueTimes = RunbookTypeModel.useGet(runbook.runbook_type_id)?.show_task_due_times

  const getCreateFromTaskPermission = TaskModel.usePermissionCallbackSync('create')
  const { can: canCreateTasks } = RunbookViewModel.usePermission('create:tasks')
  const displayCreateFromIcon = canCreateTasks && (task.stage === 'default' || runbook.stage === 'paused')

  const getProgressTaskPermission = TaskModel.usePermissionCallbackSync('progress')
  const getUpdateTaskPermission = TaskModel.usePermissionCallbackSync('update')
  const getIsTaskSkippablePermission = TaskModel.usePermissionCallbackSync('skip')
  const getIsTaskActionablePermission = TaskModel.usePermissionCallbackSync()
  const float = TaskModel.useGetFloat(id)
  const critical = TaskModel.useGetIsCritical(id)

  const { can: canProgressTask } = getProgressTaskPermission(id)
  const { can: canUpdateTask } = getUpdateTaskPermission(id)
  const { can: taskIsActionable } = getIsTaskActionablePermission(id)
  const { can: showSkip } = getIsTaskSkippablePermission(id)

  useEffect(() => {
    if (ref?.current && isFocused) {
      ref.current.focus()
    }
  }, [isFocused])

  const taskItemProps = useTaskListItemProps({
    activeTimezone,
    showCreateAfter: displayCreateFromIcon,
    canUpdate: userCanUpdate,
    critical,
    commentsCountState: commentsCount,
    float,
    first,
    iconDisabled: !taskIsActionable,
    isLoading: !!isUpdating,
    nextTaskId,
    parentStream,
    previousTask,
    previousTaskType,
    run: runbookVersion.run,
    runbookComponent,
    runbookCurrentVersion,
    runbookVersion,
    runbook,
    stream,
    task,
    taskType,
    teams,
    users,
    fieldValues,
    showTaskDueTimes
  })

  const linkedUrl =
    !!task.linked_resource && taskType.key !== 'snippet' && taskType.linkable
      ? '#' + toRunbook({ accountSlug: runbook.account_slug as string, runbookId: task.linked_resource.id })
      : undefined

  // Fixed-start icon should be changed to play-arrow if fixed start time in the past.
  const delay =
    task.start_fixed && taskItemProps.iconProps.stageIcon === 'fixed-start'
      ? task.start_fixed * 1000 - Date.now()
      : undefined

  useForceRerenderOnTimeout(delay)

  const onSkipTask = TaskModel.useAction('skip')
  const progressTask = TaskModel.useAction('progress')
  const updateFunctionalOauthUser = TaskModel.useAction('update_functional_oauth_user')
  const getNextProgressionModal = TaskProgressionViewModel.getCallback('nextModal')

  const { handleDropAssign, isLoadingAvatar, isDropPermitted } = useDropAssignment({
    canDrop: canUpdateTask,
    teams,
    users
  })

  const { integrationActionItem, integrationOptions, integrationStatusProps } = useTaskListItemIntegrationStatus(
    task
  ) ?? {
    integrationActionItem: undefined,
    integrationOptions: {},
    integrationStatusProps: undefined
  }

  const handleOptionsClickMemoed = useCallback(
    (triggerRef: Ref<HTMLElement>) => {
      toggleActionsMenu({ triggerRef, task, integrationActionItem, integrationOptions })
    },
    [
      // toggleActionsMenu,  // THIS IS THE ISSUE
      task,
      integrationActionItem,
      integrationOptions
    ]
  )

  const handleOptionsClickNotMemoed = useCallback(
    (triggerRef: Ref<HTMLElement>) => {
      toggleActionsMenu({ triggerRef, task, integrationActionItem, integrationOptions })
    },
    [
      toggleActionsMenu, // THIS IS THE ISSUE
      task,
      integrationActionItem,
      integrationOptions
    ]
  )

  const handleDependenciesClick = useCallback(
    (triggerRef: Ref<HTMLElement>, dependencyType: DependencyType) => {
      toggleDependenciesMenu({ triggerRef, dependencyType, task })
    },
    [toggleDependenciesMenu, task]
  )

  const handleClickTaskItem = useCallback(() => {
    toggleTaskEditPanel({ taskId: id })
  }, [id])

  const handleBulkTaskSelection = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation()
      const nativeEvent = e.nativeEvent as MouseEvent
      toggleSelectedIds(id, nativeEvent.shiftKey)
    },
    [toggleSelectedIds, id]
  )

  const onAdd = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation()
      toggleTaskCreate({ predecessor: id })
    },
    [toggleTaskCreate, id]
  )

  const clickable =
    taskItemProps.iconProps.hoverIcon === 'add' ||
    (['comms_email', 'comms_sms'].includes(taskType.comms) && task.stage === 'startable') ||
    canProgressTask ||
    taskOverride?.type === 'fixed-start'

  const handleClickTaskIcon = useCallback(
    async (e: SyntheticEvent) => {
      e.stopPropagation()
      // If taskType is 'comms_email' but cannot progress we want to show a toaster message
      if (['comms_email', 'comms_sms'].includes(taskType.comms) && !canProgressTask && !displayCreateFromIcon) {
        notify.warning(taskItemProps.errors?.join(', ') ?? '', { title: t('commsWarning.title') })
        return
      }

      // We need to do this check here because we still want to show the add icon if all other checks to show it pass
      // so we can show specific messaging for this limitation vs just disabling click.
      if (displayCreateFromIcon) {
        const { can: canCreateFromHere } = getCreateFromTaskPermission(id)
        if (!canCreateFromHere) {
          // TODO: use error that is returned from getCreateFromPermission to determine this properly
          notify.warning(t('startedSuccessorWarning.message'), { title: t('startedSuccessorWarning.title') })
        } else onAdd(e)
        return
      }

      // When the integration action item uses OAuth, get the task to check for a functional oauth user
      const isOauthIntegration = integrationActionItem?.settings?.auth_type === 'OAuth'
      if (isOauthIntegration) {
        const { task } = await getTask({
          runbookId: runbookVersion.runbook_id,
          runbookVersionId: runbookVersion.id,
          taskId: id
        })
        updateFunctionalOauthUser(task)
      }

      const nextModal = await getNextProgressionModal(id)
      return nextModal ? openModal(nextModal) : progressTask(id)
    },
    [getNextProgressionModal, id, getCreateFromTaskPermission, displayCreateFromIcon, canProgressTask]
  )

  const handleCommentsClick = useCallback((e: SyntheticEvent) => {
    e.stopPropagation()
    toggleCommentsPanel({ taskId: id, taskInternalId: task.internal_id })
  }, [])

  const handleSkipClick = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation()
      deselectAllIds()
      onSkipTask(id)
    },
    [deselectAllIds, id]
  )

  const handleClickAvatar = useCallback(
    (e: SyntheticEvent, subject?: AvatarSubject) => {
      if (subject) {
        e.stopPropagation()
        togglePeoplePanel({ userOrTeam: subject })
      }
    },
    [togglePeoplePanel]
  )

  const handleErrorClick = useCallback(() => {}, [])

  const handleDrop = useCallback(
    (e: React.DragEvent) => {
      if (!isDropPermitted(e)) {
        return false
      }

      handleDropAssign({ taskId: task.id, data: JSON.parse(e.dataTransfer.getData('text')) })
    },
    [handleDropAssign, isDropPermitted, task.id]
  )

  const getFilteredTaskIds = TaskModel.useGetIdsCallback({ scope: 'filtered' })

  const handleOnFocus = useCallback(async () => {
    const index = (await getFilteredTaskIds()).indexOf(id)
    onFocus?.(index)
  }, [getFilteredTaskIds, id, onFocus])

  const iconPropsMemo = useMemo(() => {
    return {
      ...taskItemProps.iconProps,
      isLoading,
      onClick: clickable ? handleClickTaskIcon : undefined
    }
  }, [taskItemProps.iconProps, isLoading, clickable, handleClickTaskIcon])

  return (
    <>
      <TaskItem
        {...taskItemProps}
        ref={ref}
        showSkip={showSkip}
        isOptionsMenuOpen={props.menu === 'options'}
        draggable={false}
        highlight={isEditing}
        isLoadingTeams={isLoadingAvatar}
        activeTimezone={props.activeTimezone}
        onChangeSelect={handleBulkTaskSelection}
        onClick={handleClickTaskItem}
        onClickAvatar={handleClickAvatar}
        onClickComments={handleCommentsClick}
        onClickError={handleErrorClick}
        // THIS IS STILL CAUSING THE ISSUE
        onClickOptions={
          localStorage.getItem('memoed') === 'false' ? handleOptionsClickNotMemoed : handleOptionsClickMemoed
        }
        onClickDependencies={handleDependenciesClick}
        onClickSkip={handleSkipClick}
        onDrop={handleDrop}
        onFocus={handleOnFocus}
        selected={isSelected}
        dropEnabled={canUpdateTask}
        updating={isUpdating}
        iconProps={iconPropsMemo}
        labelCommentsButton={t('comments', { count: commentsCount || task.comments_count })}
        labelEarly={t('taskStartDiffText.early')}
        labelLate={t('taskStartDiffText.late')}
        labelMenuButton={t('moreOptions')}
        labelSelectCheckbox={t('selectTask')}
        labelSkipButton={t('skipTaskButtonLabel')}
        labelStarted={
          taskItemProps.startableOrDefault ? t('taskStartDiffText.forecastToStart') : t('taskStartDiffText.started')
        }
        integrationStatusProps={integrationStatusProps}
        isFaded={props.isFaded}
        isLinkedArchived={task.linked_resource?.archived}
        linkedUrl={linkedUrl}
        isFocused={isFocused}
        a11yTitle={t('a11yTitle', {
          stage: taskItemProps.stage,
          taskTypeName: taskType.name,
          number: taskItemProps.internalId,
          name: taskItemProps.name
        })}
        showTaskDueTimes={showTaskDueTimes}
        endFixed={task.end_fixed}
        duration={task.duration}
      />
      <TaskItemCreateToggle predecessorId={id} nextTaskId={nextTaskId} />
    </>
  )
})
