import { ReactNode } from 'react'

import { Box } from '../../layout/box/box'
import { Text } from '../../typography/text/text'

const DEFAULT_TIME_DISPLAY = '--:--'

export type CompletionMetricTileProps = {
  label: string
  timeString?: string
  dateString?: string
  diff?: {
    value: string
    type: 'early' | 'late'
  }
  dataTestId?: string
  customText?: {
    type: 'early' | 'late'
    value: string
  }
}

export const CompletionMetricTile = (props: CompletionMetricTileProps) => {
  const { label, timeString, dateString, diff, dataTestId, customText } = props
  return (
    <Box pad="medium" background="bg-1" height="100%" data-testid={dataTestId} round="8px">
      <Label>{label}</Label>
      {customText ? (
        <CustomText type={customText.type}>{customText.value}</CustomText>
      ) : (
        <Time>{timeString || DEFAULT_TIME_DISPLAY}</Time>
      )}
      <Date>{dateString}</Date>
      <Diff value={diff?.value} type={diff?.type} />
    </Box>
  )
}

const Label = ({ children }: { children: ReactNode }) => {
  return (
    <Text
      weight={700}
      size="medium"
      color="text-light"
      css={`
        text-transform: uppercase;
      `}
    >
      {children}
    </Text>
  )
}

const Time = ({ children }: { children: ReactNode }) => {
  // max height = size + vertical padding so that if the content wraps multiple
  // lines (really only happens on duration tiles), it doesn't expand the overall height of the tile
  return (
    <Text
      size="xxlarge"
      weight={300}
      color="text-light"
      css={`
        padding: '16px';
        max-height: '40px';
      `}
    >
      {children}
    </Text>
  )
}

const Date = ({ children }: { children: ReactNode }) => {
  return (
    <Text color="text-light" size="small">
      {children}
    </Text>
  )
}

const Diff = ({ value, type }: { value?: string; type?: 'early' | 'late' }) => {
  return (
    <Text size="small" color={type && type === 'early' ? 'success' : 'warning'}>
      {value && type ? (type === 'early' ? '-' : '+') : ''} {value}
    </Text>
  )
}

const CustomText = ({ children, type }: { children: ReactNode; type?: 'early' | 'late' }) => {
  return (
    <Text
      size="xxlarge"
      weight={300}
      color={type && type === 'early' ? 'success' : 'warning'}
      css={`
        padding: '16px';
        max-height: '40px';
      `}
    >
      {children}
    </Text>
  )
}
