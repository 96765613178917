import { DataSourceValue } from '../../queries/types'
import { DataSourceValueFilterContext } from './filter-context'

export type DataSourceValueFilterType = {
  /** search string (query) */
  q?: string
  /** has template */
  t?: number
  /** custom fields JSON */
  scf?: { [key: number]: string[] | number[] | string | number | object }
}

export type DataSourceValueFilterFunction = (
  dataSourceValues: DataSourceValue[],
  filters: DataSourceValueFilterType,
  context: DataSourceValueFilterContext
) => DataSourceValue[]

/**
 * Creates a wrapper function with the code common to all filters
 *
 * @param key The filter key to look for
 * @param callback The filter function to call: takes tasks and filters and return boolean
 * @returns A function that takes an array of tasks, a filters object and returns an array of tasks
 */
export const createDataSourceValueFilterFunction = (
  key: string,
  callback: (
    dataSourceValue: DataSourceValue,
    filters: DataSourceValueFilterType,
    context: DataSourceValueFilterContext
  ) => boolean
) => {
  return (
    dataSourceValues: DataSourceValue[],
    filters: DataSourceValueFilterType,
    context: DataSourceValueFilterContext = {}
  ): DataSourceValue[] => {
    if (!Object.prototype.hasOwnProperty.call(filters, key)) {
      return dataSourceValues
    }
    return dataSourceValues.filter(dataSourceValue => {
      return callback(dataSourceValue, filters, context)
    })
  }
}
