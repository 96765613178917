import { memo, useCallback, useEffect, useMemo } from 'react'
import { Stack } from 'grommet'
import { pick } from 'lodash'
import styled from 'styled-components'

import { Box, resolveColor, ThemeContext, useTheme } from '@cutover/react-ui'
import { Footer } from './footer'
import { useViewPermissions } from './hooks'
import { LeftSidebarSize, useSidebarNavContext } from './nav-context'
import { NavDefault } from './nav-default'
import { NavGlobalSearch } from './nav-global-search'
import { NavSettings, NavSettingsContainer } from './nav-settings'
import { ToggleButtonContainer } from './shared'
import { useAngularAppControllerReadyState } from 'main/connectors/angular-app-controller-ready-provider'
import { MY_CUTOVER_PERMISSIONS, MyCutoverPermissionsType } from 'main/services/queries/types'

export const Sidebar = memo(() => {
  console.log('Sidebar')
  const isAngularReady = useAngularAppControllerReadyState()
  const viewPermissions = useViewPermissions()

  const { view, setView, isLeftSidebarOpen, leftSidebarSize } = useSidebarNavContext()
  const isShowingDefaultNav = view === 'default'
  const isShowingSettings = view === 'settings'
  const isShowingSearch = view === 'search'

  const toggleSettings = useCallback(
    (show?: boolean) => {
      if (show !== undefined) {
        setView(show ? 'settings' : 'default')
      } else if (isShowingSettings) {
        setView('default')
      } else {
        setView('settings')
      }
    },
    [isShowingSettings, setView]
  )

  const toggleSearchResults = useCallback(
    (show?: boolean) => {
      if (show !== undefined) {
        setView(show ? 'search' : 'default')
      } else if (isShowingSearch) {
        setView('default')
      } else {
        setView('search')
      }
    },
    [isShowingSearch, setView]
  )

  const theme = useTheme()

  useEffect(() => {
    if (isLeftSidebarOpen) {
      // whenever the sidebar first opens, show the main content, not the search results even if they were showing before
      setView(isShowingSettings ? 'settings' : 'default')
    }
  }, [isLeftSidebarOpen])

  // NOTE: temporary until react manages the page. This adjusts the height of the sidebar content for the
  // Development Environment notification banner at the bottom of the screen during dev.
  const offset = process.env.NODE_ENV === 'development' ? '160px' : '136px'
  const headerHeight = '72px'
  const footerHeight = leftSidebarSize === 'small' ? '192px' : '64px'

  const settingsViewPermissions = pick(viewPermissions, MY_CUTOVER_PERMISSIONS) as Record<
    MyCutoverPermissionsType,
    boolean
  >
  const hasSettings = Object.values(settingsViewPermissions).some(Boolean)

  const returnVal = useMemo(() => {
    return (
      <ThemeContext.Extend
        value={{
          global: {
            focus: {
              border: undefined,
              outline: {
                color: 'primary-3',
                size: '2px'
              },
              shadow: undefined
            }
          }
        }}
      >
        <SidebarBox
          data-testid="sidebar"
          id="layout-left-nav"
          responsive={false}
          width={{ width: SIDEBAR_WIDTH_MAP[leftSidebarSize], min: SIDEBAR_WIDTH_MAP[leftSidebarSize] }}
          fill="vertical"
          css={`
            position: relative;
            z-index: 2;
            height: 100vh;
            min-height: 100vh;
            background-color: ${resolveColor('nav-bg', theme)};
            display: ${leftSidebarSize === 'hidden' && 'none'};
            > div {
              z-index: 1;
            }
          `}
        >
          {isAngularReady ? (
            <>
              <Stack
                css={`
                  height: calc(100vh - ${footerHeight});
                  overflow-y: ${isShowingSettings && 'clip'};
                  > div {
                    &:first-child {
                      height: ${isShowingDefaultNav && headerHeight};
                    }
                    &:last-child {
                      height: calc(100vh - ${footerHeight} - ${headerHeight});
                      top: ${headerHeight};
                      display: ${!isShowingDefaultNav && 'none'};
                    }
                  }
                `}
              >
                <Box height={!isShowingDefaultNav ? `calc(100% - ${offset} - ${footerHeight})` : headerHeight}>
                  {isShowingSettings ? (
                    <NavSettings
                      css={`
                        ${NavSettingsContainer} {
                          height: calc(100vh - ${headerHeight} - ${footerHeight});
                          overflow: auto;
                        }
                      `}
                      toggleSettings={toggleSettings}
                      compact={leftSidebarSize === 'small'}
                      permissions={settingsViewPermissions}
                    />
                  ) : (
                    <NavGlobalSearch showResults={isShowingSearch} toggleSearchResults={toggleSearchResults} />
                  )}
                </Box>
                <Box responsive={false} overflow="auto" height="100%" pad="12px">
                  <NavDefault hidden={!isShowingDefaultNav} compact={leftSidebarSize === 'small'} />
                </Box>
              </Stack>

              {leftSidebarSize !== 'hidden' && (
                <Footer
                  hasSettings={hasSettings}
                  toggleSettings={toggleSettings}
                  isShowingSettings={isShowingSettings}
                />
              )}
            </>
          ) : null}
        </SidebarBox>
      </ThemeContext.Extend>
    )
  }, [
    footerHeight,
    hasSettings,
    isAngularReady,
    isShowingDefaultNav,
    isShowingSearch,
    isShowingSettings,
    leftSidebarSize,
    offset,
    settingsViewPermissions,
    theme,
    toggleSearchResults,
    toggleSettings
  ])

  return returnVal

  // return (
  //   <ThemeContext.Extend
  //     value={{
  //       global: {
  //         focus: {
  //           border: undefined,
  //           outline: {
  //             color: 'primary-3',
  //             size: '2px'
  //           },
  //           shadow: undefined
  //         }
  //       }
  //     }}
  //   >
  //     <SidebarBox
  //       data-testid="sidebar"
  //       id="layout-left-nav"
  //       responsive={false}
  //       width={{ width: SIDEBAR_WIDTH_MAP[leftSidebarSize], min: SIDEBAR_WIDTH_MAP[leftSidebarSize] }}
  //       fill="vertical"
  //       css={`
  //         position: relative;
  //         z-index: 2;
  //         height: 100vh;
  //         min-height: 100vh;
  //         background-color: ${resolveColor('nav-bg', theme)};
  //         display: ${leftSidebarSize === 'hidden' && 'none'};
  //         > div {
  //           z-index: 1;
  //         }
  //       `}
  //     >
  //       {isAngularReady ? (
  //         <>
  //           <Stack
  //             css={`
  //               height: calc(100vh - ${footerHeight});
  //               overflow-y: ${isShowingSettings && 'clip'};
  //               > div {
  //                 &:first-child {
  //                   height: ${isShowingDefaultNav && headerHeight};
  //                 }
  //                 &:last-child {
  //                   height: calc(100vh - ${footerHeight} - ${headerHeight});
  //                   top: ${headerHeight};
  //                   display: ${!isShowingDefaultNav && 'none'};
  //                 }
  //               }
  //             `}
  //           >
  //             <Box
  //               responsive={false}
  //               height={!isShowingDefaultNav ? `calc(100% - ${offset} - ${footerHeight})` : headerHeight}
  //             >
  //               {isShowingSettings ? (
  //                 <NavSettings
  //                   css={`
  //                     ${NavSettingsContainer} {
  //                       height: calc(100vh - ${headerHeight} - ${footerHeight});
  //                       overflow: auto;
  //                     }
  //                   `}
  //                   toggleSettings={toggleSettings}
  //                   compact={leftSidebarSize === 'small'}
  //                   permissions={settingsViewPermissions}
  //                 />
  //               ) : (
  //                 <NavGlobalSearch showResults={isShowingSearch} toggleSearchResults={toggleSearchResults} />
  //               )}
  //             </Box>
  //             <Box responsive={false} overflow="auto" height="100%" pad="12px">
  //               <NavDefault hidden={!isShowingDefaultNav} compact={leftSidebarSize === 'small'} />
  //             </Box>
  //           </Stack>

  //           {leftSidebarSize !== 'hidden' && (
  //             <Footer hasSettings={hasSettings} toggleSettings={toggleSettings} isShowingSettings={isShowingSettings} />
  //           )}
  //         </>
  //       ) : null}
  //     </SidebarBox>
  //   </ThemeContext.Extend>
  // )
})

const SIDEBAR_WIDTH_MAP: Record<LeftSidebarSize, string> = {
  default: '280px',
  small: '64px',
  full: '100vw',
  hidden: '0'
}

const SidebarBox = styled(Box)`
  ${ToggleButtonContainer} {
    display: none;
  }

  &:hover,
  &:focus-within {
    ${ToggleButtonContainer} {
      display: block;
    }
  }
`
