import { useState } from 'react'

import { Box, Icon, LoadingPanel, Message, Text } from '@cutover/react-ui'
import {
  CustomPromptType,
  SuggestionsResponseType,
  useRunbookGenerateSuggestions
} from 'main/services/queries/use-runbook-ai-suggestions'
import { useLanguage } from 'main/services/hooks'

type AiSuggestionSubItemProps = {
  promptItem: CustomPromptType
  runbookId: number
}

export const AiSuggestionItem = ({ promptItem, runbookId }: AiSuggestionSubItemProps) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'aiSuggestionsPanel' })
  const [startGenerating, setStartGenerating] = useState(true)
  const [suggestionResponse, setSuggestionResponse] = useState<SuggestionsResponseType | undefined>(undefined)

  const { isFetching } = useRunbookGenerateSuggestions(runbookId, promptItem.key, {
    enabled: startGenerating,
    onSuccess: (response: any) => {
      setStartGenerating(false)
      setSuggestionResponse(response)
    },
    onError: () => {
      setStartGenerating(false)
    }
  })

  return (
    <>
      {isFetching ? (
        <LoadingPanel />
      ) : suggestionResponse?.suggestions ? (
        <>
          <Box css="word-wrap: break-word; white-space: normal; padding-bottom: 20px;">
            {`${t('subPanelText')}\n'${promptItem.name}'`}
          </Box>
          {suggestionResponse?.suggestions?.map(item => (
            <Box
              direction="row"
              pad="xxsmall"
              css={`
                position: relative;
                padding-top: 4px;
              `}
              key={`${item.headline}-${item.suggestionDetails}`}
            >
              <Box flex={false} css="padding-top: 4px;">
                <Icon icon="lightbulb" />
              </Box>

              <Box direction="column" pad="xxsmall">
                <Text weight="bold" css="white-space: normal; overflow-wrap: break-word;">
                  {item.headline}
                </Text>
                <Text color="text-light" css="white-space: normal; overflow-wrap: break-word;">
                  {item.suggestionDetails}
                </Text>
              </Box>
            </Box>
          ))}
        </>
      ) : (
        <Message type="warning" message={t('customErrorMessage')} />
      )}
    </>
  )
}
