import { useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { DashboardRunbookList } from 'main/components/workspace/pages/runbooks-dashboard'
import { useRightPanelTypeState } from 'main/components/layout/right-panel'

export const DashboardRunbookListPanel = () => {
  const { dashboardId, accountId } = useParams()
  const [{ params, contextParams }, { closeRightPanel }] = useRightPanelTypeState('dashboard-runbook-list')

  // TODO: remove when runbook feature flag is turned on
  const onClickRunbookItem = useCallback(() => {
    closeRightPanel()
    localStorage.setItem('previousTargetState', 'app.root.runbooks.dashboard')
    localStorage.setItem('previousQuery', JSON.stringify({ dashboardId, accountId }))
  }, [dashboardId, closeRightPanel])

  return (
    <>
      {!!params && (
        <DashboardRunbookList
          params={{ ...params, ...contextParams }}
          onClose={closeRightPanel}
          onClickRunbookItem={onClickRunbookItem}
        />
      )}
    </>
  )
}
