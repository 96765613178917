import { DataSourceValue } from 'main/services/queries/types'
import { useGetCount, useGetFilteredValues } from '../hooks/recoil/data-source-value'

export type DataSourceValueModelType = {
  useGetFilteredValues: ({
    customFieldId,
    accountId
  }: {
    customFieldId: number
    accountId: number
  }) => DataSourceValue[]
  useGetCount: ({ customFieldId, accountId }: { customFieldId: number; accountId: number }) => {
    valuesCount: number
    filteredCount: number
  }
}

export const DataSourceValueModel: DataSourceValueModelType = {
  useGetFilteredValues,
  useGetCount
}
