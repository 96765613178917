import { ReactNode } from 'react'

import {
  Box,
  Button,
  Icon,
  IconName,
  LinkButton,
  MessageType,
  SanitizedString,
  Text,
  themeColor,
  useResponsiveContext
} from '@cutover/react-ui'
import { BANNER_HEIGHT } from '../../../../reactApp/src/main/components/layout/layout-constants'

const BANNER_TYPE_ICON_MAP: Record<MessageType, IconName> = {
  error: 'task-deleted',
  success: 'check',
  warning: 'alert',
  info: 'info',
  idea: 'lightbulb'
}
export type BannerProps = {
  type: MessageType
  message?: string
  linkText?: string
  linkTextSecondary?: string
  linkTextInline?: string
  icon?: boolean
  iconName?: IconName
  onClick?: () => void
  onClickSecondary?: () => void
  onClickInlineLink?: () => void
  children?: ReactNode
}

export const Banner = ({
  message,
  type,
  icon,
  iconName,
  linkText,
  linkTextSecondary,
  linkTextInline,
  onClick,
  onClickSecondary,
  onClickInlineLink,
  children
}: BannerProps) => {
  const screenSize = useResponsiveContext()

  return (
    <Box
      data-testid="banner"
      direction={screenSize === 'small' ? 'column' : 'row'}
      pad={{ horizontal: 'large', vertical: 'small' }}
      gap="medium"
      background={`message-${type}-bg`}
      aria-live="polite"
      role="banner"
      a11yTitle="banner"
      flex={false}
      justify="between"
      align={screenSize === 'small' ? 'auto' : 'center'}
      height={`${BANNER_HEIGHT}px`}
      css={`
        white-space: normal;
        color: ${themeColor(`message-${type}`)};
      `}
    >
      {children ? (
        <>{children}</>
      ) : (
        <>
          <Box
            {...(linkTextInline ? { as: 'span' } : { direction: 'row' })}
            align="center"
            gap="small"
            css="z-index: 1"
          >
            {icon && <Icon icon={iconName ? iconName : BANNER_TYPE_ICON_MAP[type]} color={`message-${type}`} />}
            <SanitizedString size="18px" input={message ?? ''} />
            {linkTextInline && (
              <LinkButton
                plain
                onClick={onClickInlineLink}
                css={`
                  text-decoration: underline;
                  font-size: 18px;
                  color: ${themeColor(`message-${type}`)};
                  cursor: pointer;
                  &:hover {
                    color: ${themeColor(`message-${type}`)};
                  }
                `}
                a11yTitle={linkTextInline}
                label={linkTextInline}
              />
            )}
          </Box>
          {(linkText || linkTextSecondary) && (
            <Box
              direction={screenSize === 'small' ? 'column' : 'row'}
              flex="grow"
              justify={linkText && linkTextSecondary ? 'between' : 'end'}
              gap={screenSize === 'small' ? 'auto' : 'small'}
            >
              {linkTextSecondary && (
                <Button
                  plain
                  onClick={onClickSecondary}
                  css={`
                    text-decoration: underline;
                    opacity: 0.7;
                  `}
                  a11yTitle={`${message} ${linkTextSecondary}`}
                >
                  <Text size="18px">{linkTextSecondary}</Text>
                </Button>
              )}
              {linkText && (
                <Button
                  plain
                  onClick={onClick}
                  css={`
                    text-decoration: underline;
                    opacity: 0.7;
                  `}
                  a11yTitle={`${message} ${linkText}`}
                  data-testid="banner-link"
                >
                  <Text size="18px">{linkText}</Text>
                </Button>
              )}
            </Box>
          )}
        </>
      )}
    </Box>
  )
}
