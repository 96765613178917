import { DataSourceValue } from '../../queries/types'
import { DataSourceValueFilterContext } from './filter-context'
import { createDataSourceValueFilterFunction, DataSourceValueFilterType } from './filters'

export const custom = createDataSourceValueFilterFunction(
  'scf',
  (
    dataSourceValue: DataSourceValue,
    filters: DataSourceValueFilterType,
    context: DataSourceValueFilterContext
  ): boolean => {
    if (filters.scf === undefined) return false
    if (!context.dataSourceValues) return false

    // TODO: build filter logic
    return true
  }
)
