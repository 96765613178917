import { useMutation, useQuery } from 'react-query'

import { apiClient } from 'main/services/api'
import { QueryKeys } from './query-keys'
import { DataSourceValuesResponse } from './types'

type DataSourceValuesImportType = {
  id: number
}

export function useDataSourceValuesImport(params: DataSourceValuesImportType) {
  return useQuery<DataSourceValuesResponse, Error>([QueryKeys.DataSourceValues, params], async () => {
    const { data } = await apiClient.get<DataSourceValuesResponse>({
      url: `data_source_values/${params.id}/import`,
      params
    })

    return data
  })
}

export function useDataSourceValuesImportMutation() {
  return useMutation<any, Error, { id: number }>(QueryKeys.DataSource, async (payload): Promise<any> => {
    const id = payload.id
    const { data } = await apiClient.patch({
      url: `data_source_values/${id}/import`,
      data: payload
    })

    return data
  })
}
