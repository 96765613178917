import { ResponsivePie } from '@nivo/pie'

import { ColorProp, resolveColor, useTheme } from '../../theme'
import { Box } from '../../layout'
import { Text } from '../../typography'

export type InteractiveDonutChartDatum = {
  id: string | number
  label: string | number
  value: number
  color: string
  name: string | number
}

export type InteractiveDonutProps = {
  data: InteractiveDonutChartDatum[]
  total?: boolean
  centerValue?: string
  withKey?: boolean
  segmentGap?: number
  hover?: boolean
  onSegmentClick?: (segmentNode: any) => void
  selectedId?: string | number | null
}

export const InteractiveDonutChart = ({
  data,
  centerValue,
  segmentGap = 1,
  hover,
  selectedId,
  onSegmentClick
}: InteractiveDonutProps) => {
  const theme = useTheme()

  return (
    <Box direction="row" gap="24px" align="center" height="200px" pad={{ left: '16px' }}>
      <Box width="50%" height="100%">
        <Box fill pad={'16px'} css="min-width: 100px;">
          <ResponsivePie
            data={data}
            tooltip={({
              datum: {
                value,
                data: { name }
              }
            }) => (
              <div
                style={{
                  color: resolveColor('tooltip-text', theme),
                  background: resolveColor('tooltip-bg', theme),
                  borderRadius: '4px',
                  padding: '4px 8px',
                  fontSize: '15px',
                  fontWeight: 400
                }}
              >
                <strong>
                  {name}: {value}
                </strong>
              </div>
            )}
            animate={true}
            colors={({ id }) => data.find(d => d.id === id)?.color || resolveColor('primary', theme)}
            innerRadius={0.8}
            layers={['arcs', 'legends', centerValue ? createCenterValue(centerValue) : () => null]}
            padAngle={segmentGap}
            isInteractive={hover}
            onClick={node => onSegmentClick?.(node.data)}
          />
        </Box>
      </Box>
      <Box width="50%" className="donut-legend">
        {data.map(item => (
          <Box
            key={item.id}
            direction="row"
            onClick={() => onSegmentClick?.(item)}
            align="center"
            background={selectedId === item.id ? 'surface-selected' : undefined}
            className={selectedId === item.id ? 'selected' : item.id === 'other' ? 'disabled' : undefined}
            css={`
              height: 32px;
              padding: 0 8px;
              border-radius: 8px;
              cursor: default;
              &:not(.disabled) {
                cursor: pointer;
                &:hover {
                  background-color: ${resolveColor('surface-hover', theme)};
                }
              }
              &.selected:hover {
                background-color: ${resolveColor('surface-selected', theme)};
              }
            `}
          >
            <Box
              round="8px"
              width="16px"
              height="16px"
              css={`
                margin-right: 8px;
                flex-shrink: 0;
                flex-grow: 0;
                border: 1px solid ${resolveColor('surface', theme)};
              `}
              background={item.color as ColorProp}
            />
            <Text color={selectedId === item.id ? 'text' : 'text-light'} truncate="tip">
              {item.label}
            </Text>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

// NOTE: Nivo does not type the props for layers; we could do it manually at some point.

/**
 * A higher order function to create the layer function in order to capture the string we want to display as
 * the label
 */
const createCenterValue: (value: string) => (props: any) => JSX.Element = (value: string) => {
  return (props: any) => {
    return (
      <text
        data-testid="donut-total-count"
        x={props.centerX}
        y={props.centerY}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontFamily: 'Inter, sans-serif',
          fontSize: '24px',
          fill: '#97a4aa',
          fontWeight: 400
        }}
      >
        {value}
      </text>
    )
  }
}
