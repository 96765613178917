import { SyntheticEvent } from 'react'
import styled from 'styled-components'

import { Avatar, BannerRagStatus, BarTable, Box, themeColor } from '@cutover/react-ui'
import { DashboardMediaType } from '../../../widgets/types'
import { generateBarValues } from '../bar-table-widget-utils'
import { MrdDashboardWidget } from '../mrd-dashboard-widget'
import { ConfigModel } from 'main/data-access'
import {
  ActiveDashboardItemType,
  DashboardItemClickProps
} from 'main/components/dashboards/dashboard-widget-collection'

export type RunbookCompletionComponentProps = {
  media: DashboardMediaType
  data: RunbookCompletionComponentData
  activeItem?: ActiveDashboardItemType
  onClickItem: (item: DashboardItemClickProps) => void
}

export type RunbookCompletionComponentData = {
  id: number
  errors?: string[]
  filters?: {
    resource_type: string
    resource_id: string | null
    value: string
  } | null
  name: string
  notes?: any
  type: string
  filter_key: string
  resource_type?: string
  values: RunbookCompletionValues[]
}

export type RunbookCompletionValues = {
  total: number
  red: number
  amber: number
  green: number
  cancelled: number
  complete: number
  ontime: number
  late: number
  other: number
  id: number
  name: string
  color?: string
  parent_id?: number | null
  image: boolean
  status: string | null
  status_message: string | null
}

const SEGMENT_FILTER_VALUES: Record<string, { displayStage?: string; late?: boolean }> = {
  cancelled: { displayStage: 'cancelled', late: false },
  completed: { displayStage: 'complete' },
  in_progress: { displayStage: 'in_progress', late: false },
  late: { displayStage: 'in_progress', late: true },
  other: { displayStage: 'planning', late: false }
}

export const RunbookCompletionWidget = (props: RunbookCompletionComponentProps) => {
  const { media } = props
  switch (media) {
    case 'screen':
      return <BrowserWidget {...props} />
    case 'email':
      return <ServerSideWidget {...props} />
    case 'print':
      return <ServerSideWidget {...props} />
  }
}

const BrowserWidget = (props: RunbookCompletionComponentProps) => {
  const isReactWorkspaceEnabled = ConfigModel.useIsFeatureEnabled('react_workspace')
  return <RunbookCompletionWidgetContent {...props} reactWorkspace={isReactWorkspaceEnabled} />
}

const ServerSideWidget = (props: RunbookCompletionComponentProps) => <RunbookCompletionWidgetContent {...props} />

const RunbookCompletionWidgetContent = ({
  media,
  data: { id: componentId, name, errors, notes, values, filter_key, filters, resource_type: resourceType },
  onClickItem,
  activeItem
}: RunbookCompletionComponentProps & { reactWorkspace?: boolean }) => {
  const groupBy = name.includes('Team') ? 'Team' : 'Other' //not optimal way to differentiate..

  let additionalFilters: { [key: string]: string | string[] } = {}

  if (filters) {
    additionalFilters =
      filters.resource_type === 'CustomField'
        ? { [`f[${filters.resource_id}]`]: filters.value }
        : filters.resource_type === 'Stage'
        ? { display_stage: [filters.value] }
        : { [filters.resource_type.toLowerCase()]: filters.value }
  }

  const handleRowClick = (id: string | number, displayStageId?: string, ragStatus?: string) => {
    let property = resourceType == 'CustomField' ? `f[${filter_key}]` : filter_key
    let value = id

    let displayStage, late
    if (displayStageId) {
      ;({ displayStage, late } = SEGMENT_FILTER_VALUES[displayStageId])
    }

    const params = {
      display_stage: displayStage ? displayStage : undefined,
      late: late !== undefined ? (late ? 1 : 0) : undefined,
      status: ragStatus ? ragStatus : undefined,
      ...{ [property]: [value] },
      ...additionalFilters
    }

    // This stores info about what the user clicked on, so can detect if same
    const subFilterKey = displayStageId ? 'stage' : ragStatus ? 'rag' : undefined
    const subFilterValue = displayStageId ? displayStageId : ragStatus ? ragStatus : undefined

    onClickItem({ componentId, itemId: id, panelType: 'dashboard-runbook-list', params, subFilterKey, subFilterValue })
  }

  const selectedRowId = activeItem?.componentId === componentId ? activeItem.itemId : null

  return (
    <MrdDashboardWidget title={name} errors={errors} notes={notes} fullWidth>
      <BarTable
        data={{ rowData: convertValuesToRowDataFormat(values, handleRowClick, groupBy) }}
        media={media}
        selectedRowId={selectedRowId}
        onRowClick={handleRowClick}
        onSegmentClick={handleRowClick}
      />
    </MrdDashboardWidget>
  )
}

const convertValuesToRowDataFormat = (
  values: RunbookCompletionValues[],
  handleRowClick: (id: string | number, displayStageId?: string, ragStatus?: string) => void,
  groupBy: string
) => {
  return values.map(value => {
    const { name, color, parent_id, complete, cancelled, ontime, late, other, total, red, amber, green, id } = value
    const defaultBarColors = [
      'progress-complete',
      'progress-inprogress',
      'progress-late',
      'progress-planning',
      'progress-cancelled'
    ]

    return {
      id,
      name,
      mainLabel: (
        <Box direction="row" align="center">
          {groupBy === 'Team' && (
            <Box margin={{ right: '4px' }} width="24px" css="flex-shrink: 0;">
              <Avatar subject={{ name, color }} size="small" />
            </Box>
          )}
          {parent_id ? `\u00A0 \u00A0 \u00A0${name}` : `${name}`}
        </Box>
      ),
      rowInfo: `${total} runbooks`,
      optionalComponent: <RagStatus red={red} amber={amber} green={green} id={id} onRagClick={handleRowClick} />,
      values: generateBarValues({
        bars: [
          { count: complete, tooltip: 'with complete live run', displayStage: 'completed' },
          { count: ontime, tooltip: 'in live run and on time', displayStage: 'in_progress' },
          { count: late, tooltip: 'in live run and late', displayStage: 'late' },
          { count: other, tooltip: 'in planning or rehearsal', displayStage: 'other' },
          { count: cancelled, tooltip: 'with cancelled live run', displayStage: 'cancelled' }
        ],
        total,
        context: 'runbook',
        barColors: defaultBarColors,
        displayPercentBarIndex: 0
      })
    }
  })
}

type RagProps = {
  status: BannerRagStatus
  active: boolean
}

type RagStatusProps = {
  red: number
  amber: number
  green: number
  id: number
  onRagClick: (id: string | number, displayStageId?: string, ragStatus?: string) => void
}

export const RagStatus = (props: RagStatusProps) => {
  const { red, amber, green, id, onRagClick } = props

  return (
    <RagStatusWrapper>
      <Rag
        status="red"
        background="rag-red"
        active={!!red}
        onClick={(e: SyntheticEvent) => {
          e.stopPropagation()
          onRagClick(id, undefined, 'red')
        }}
      >
        {red}
      </Rag>
      <Rag
        status="amber"
        background="rag-amber"
        active={!!amber}
        onClick={(e: SyntheticEvent) => {
          e.stopPropagation()
          onRagClick(id, undefined, 'amber')
        }}
      >
        {amber}
      </Rag>
      <Rag
        status="green"
        background="rag-green"
        active={!!green}
        onClick={(e: SyntheticEvent) => {
          e.stopPropagation()
          onRagClick(id, undefined, 'green')
        }}
      >
        {green}
      </Rag>
    </RagStatusWrapper>
  )
}

const Rag = styled(Box)`
  border-radius: 8px;
  width: 16px;
  height: 16px;
  font-size: 12px;
  font-weight: 400;
  color: ${themeColor('bg')};
  margin-right: 3px;
  justify-content: center;
  align-items: center;
  opacity: ${(props: RagProps) => (props.active ? '0.8' : '0.2')};
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
`

const RagStatusWrapper = styled(Box)`
  flex-shrink: 0;
  padding-left: 4px;
  flex-direction: row;
`
