import { parseISO } from 'date-fns'
import { format } from 'date-fns-tz'
import { sortBy } from 'lodash'

import { BarChart, resolveColor, useTheme } from '@cutover/react-ui'
import { createChartDeltas } from './chart-deltas'
import { NoDataAvailable } from 'main/components/dashboards/widgets/account/common'
import { MrdDashboardWidget } from 'main/components/dashboards/widgets/account/mrd-dashboard-widget'
import { DashboardComponentProps } from 'main/components/dashboards/widgets/types'
import { DashboardItemClickProps } from 'main/components/dashboards/dashboard-widget-collection'

type Delta = {
  duration: number
  percentage: number
}

type AverageRunbookDurationApi = {
  timestamp: string
  average_runbook_duration: number
  duration: string
  delta?: Delta
}

export type AverageRunbookDurationOverTimeWidgetData = {
  id: number
  name: string
  values: AverageRunbookDurationApi[]
}

export type AverageRunbookDurationOverTimeWidgetProps =
  DashboardComponentProps<AverageRunbookDurationOverTimeWidgetData> & {
    onClickItem: (item: DashboardItemClickProps) => void
  }

export const AverageRunbookDurationOverTimeWidget = (props: AverageRunbookDurationOverTimeWidgetProps) => {
  const context = props.media

  switch (context) {
    case 'screen':
      return <BrowserWidget {...props} />
    case 'email':
      return <EmailWidget {...props} />
    case 'print':
      return <PrintWidget {...props} />
  }
}

const BrowserWidget = ({
  data,
  onClickItem
}: {
  data: AverageRunbookDurationOverTimeWidgetData
  onClickItem: (item: DashboardItemClickProps) => void
}) => {
  return <WidgetContent data={data} onClickItem={onClickItem} />
}

const EmailWidget = ({ data }: { data: AverageRunbookDurationOverTimeWidgetData }) => (
  <WidgetContent data={data} width={550} />
)

const PrintWidget = ({ data }: { data: AverageRunbookDurationOverTimeWidgetData }) => (
  <WidgetContent data={data} width={850} />
)

function WidgetContent({
  data,
  width,
  onClickItem
}: {
  data: AverageRunbookDurationOverTimeWidgetData
  width?: number
  onClickItem?: (item: DashboardItemClickProps) => void
}) {
  const values: AverageRunbookDurationApi[] = data.values

  const durations = (values ?? []).map(e => ({
    date: parseISO(e.timestamp.substring(0, 11)), // Ignore time to avoid midnight conversion to local time
    hours: e.average_runbook_duration,
    delta: e.delta
  }))

  const isEmpty = durations.length === 0 || durations.every(d => d.hours === 0)

  return (
    <MrdDashboardWidget title={data.name} fullWidth>
      {isEmpty ? (
        <NoDataAvailable />
      ) : (
        <AverageRunbookDurationOverTime
          yAxisTitle="Average (Hours)"
          durations={durations}
          width={width}
          onSelect={date => {
            const startMonth = format(date, 'yyyy-MM-dd')
            const params = {
              stage: ['complete'],
              run_type: ['live'],
              start_actual_month: [startMonth]
            }
            onClickItem?.({ componentId: data.id, itemId: startMonth, panelType: 'dashboard-runbook-list', params })
          }}
        />
      )}
    </MrdDashboardWidget>
  )
}

export type RunbookDuration = {
  date: Date
  hours: number
  delta?: Delta
}

export type AverageRunbookDurationOverTimeProps = {
  yAxisTitle: string
  durations: RunbookDuration[]
  onSelect?: (date: Date, event: any) => void
  width?: number
}

export const AverageRunbookDurationOverTime = ({
  yAxisTitle,
  durations,
  onSelect,
  width
}: AverageRunbookDurationOverTimeProps) => {
  const theme = useTheme()
  const sortedDurations = sortBy(durations, e => e.date).map(e => ({
    category: {
      name: 'Date',
      date: e.date,
      delta: e.delta,
      value: format(e.date, 'LLLL')
    },
    values: [
      {
        group: 'Hours',
        value: e.hours
      }
    ]
  }))

  // BarChart doesn't currently support nivo xAxis time format or xFormat props
  // The index of the selected bar is used to look up stored date object
  return (
    <BarChart
      data={sortedDurations}
      height={400}
      width={width}
      padding={'30px 0 30px 50px'}
      colors={{
        Hours: resolveColor('primary', theme)
      }}
      xAxis={{
        id: 'Date',
        label: ''
      }}
      yAxis={{
        id: 'Hours',
        label: yAxisTitle
      }}
      layers={[
        createChartDeltas(
          sortedDurations.map(d => ({
            id: d.category.value,
            delta: d.category.delta
          }))
        )
      ]}
      onSelect={(data, event) => {
        const date = sortedDurations[data.index]?.category?.date

        if (date) {
          onSelect?.(date, event)
        }
      }}
    />
  )
}
