import { useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { InfiniteList, RightPanel } from '@cutover/react-ui'
import { RunbookListItem } from 'main/components/shared/runbook-list-item'
import { useLanguage } from 'main/services/hooks'
import { RunbookListRunbook } from 'main/services/queries/types'
import { useAccountRunbooks } from 'main/services/queries/use-runbooks-infinite'
import { useRouting } from 'main/services/routing/hooks'

export type DashboardRunbookListProps = {
  params: { [key: string]: any }
  onClose: () => void
  onClickRunbookItem: () => void
}

export const DashboardRunbookList = ({ params, onClose, onClickRunbookItem }: DashboardRunbookListProps) => {
  const { accountId: accountSlug } = useParams()
  const { t } = useLanguage('dashboard', { keyPrefix: 'runbookList' })
  const { toRunbook } = useRouting()

  const { data, hasNextPage, fetchNextPage, isFetching, isFetchingNextPage } = useAccountRunbooks({
    accountSlug,
    params,
    includeMeta: false
  })

  const runbooks = data?.pages.flatMap(page => page.runbooks)
  const totalResultCount = data?.pages[0]?.meta?.filtered_results_count

  const createLinkTo = useCallback(
    (id: number) => toRunbook({ accountSlug: accountSlug as string, runbookId: id }),
    [accountSlug]
  )

  return (
    <RightPanel
      title={totalResultCount === undefined ? '' : t('title', { count: totalResultCount })}
      onClose={onClose}
      loading={isFetching && !isFetchingNextPage}
    >
      {runbooks && runbooks.length > 0 && (
        <InfiniteList<RunbookListRunbook>
          items={runbooks}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
          renderItem={(_, runbook: RunbookListRunbook) => (
            <RunbookListItem runbook={runbook} createLinkTo={createLinkTo} onClickItem={onClickRunbookItem} />
          )}
        />
      )}
    </RightPanel>
  )
}
