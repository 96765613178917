import { Values } from '@cutover/react-ui'

type barSetup = {
  bars: { count: number; tooltip: string; displayStage?: string }[]
  total: number
  context: string
  barColors?: string[]
  displayPercentBarIndex?: number
  includePercentageInTooltip?: boolean
}

export const generateBarValues = ({
  bars,
  total,
  context,
  barColors,
  displayPercentBarIndex = 1,
  includePercentageInTooltip = false
}: barSetup): Values[] => {
  const defaultBarColors = ['text-light', 'primary', 'success', 'error', 'bg-1']

  const values: Values[] = bars.map((bar, index) => {
    const { count, tooltip, displayStage } = bar
    const percentage = convertToPercentage(count, total)
    return {
      width: percentage,
      color: barColors && barColors.length ? barColors[index] : defaultBarColors[index],
      tooltipText: `${count} ${context}${count > 1 ? 's' : ''} ${tooltip}${
        includePercentageInTooltip ? ` (${Math.round(percentage)}%)` : ''
      }`,
      ...(displayStage ? { displayStage: displayStage } : {})
    }
  })
  // make an element main bar - to display percentage
  values[displayPercentBarIndex].main = true
  return values
}

const convertToPercentage = (count: number, total: number) => (count / total) * 100
