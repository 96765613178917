import { useCallback } from 'react'

import { Box, NoResourceFound, Table } from '@cutover/react-ui'
import { TaskModel } from 'main/data-access'
import { useClearAllFilterState } from 'main/recoil/data-access'
import { useTaskColumns } from './use-task-columns'
import { TaskListTask } from 'main/services/queries/types'
import { useRightPanelTypeValue, useToggleRightPanel } from 'main/components/layout/right-panel'

export const TaskTable = () => {
  const clearAllFilters = useClearAllFilterState()
  const filteredTasks = TaskModel.useGetAll({ scope: 'filtered' })
  const toggleTaskEditPanel = useToggleRightPanel('task-edit')
  const { taskId } = useRightPanelTypeValue('task-edit')

  const columns = useTaskColumns()

  const handleClickRow = useCallback(({ datum }: { datum: TaskListTask }) => {
    toggleTaskEditPanel({ taskId: datum.id })
  }, [])

  return (
    <Box css="padding-right: 16px;">
      {filteredTasks.length === 0 ? (
        <NoResourceFound context="task" clearAllFilters={clearAllFilters} />
      ) : (
        <Table
          onClickRow={handleClickRow}
          sortable="client"
          columns={columns}
          data={filteredTasks ?? undefined}
          selectedItem={taskId ? { key: 'id', value: taskId } : undefined}
        />
      )}
    </Box>
  )
}
