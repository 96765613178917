import { Children, ReactElement, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

import {
  Box,
  Button,
  ButtonProps,
  IconButtonProps,
  IconName,
  Pill,
  PillProps,
  resolveColor,
  themeColor,
  useTheme
} from '@cutover/react-ui'

type SubHeaderButtonProps = {
  badge?: IconButtonProps['badge']
  active?: boolean
  tip?: string
  navTo?: string
  onClick?: ButtonProps['onClick']
  icon?: IconName
  label?: string
  'aria-label'?: string
  tipPlacement?: ButtonProps['tipPlacement']
  alt?: boolean
  className?: string
  id?: string
  pill?: PillProps
}

export const SubHeaderButton = ({
  badge,
  active,
  tip,
  navTo: to,
  icon,
  label,
  tipPlacement,
  className,
  ...props
}: SubHeaderButtonProps) => {
  return to ? (
    <StyledLink $active={active} to={to} className={className} {...props}>
      {label}
      {props.pill && (
        <>
          &nbsp;
          <Pill {...props.pill} />
        </>
      )}
    </StyledLink>
  ) : (
    <StyledButton
      className={className}
      $active={active}
      active={active}
      tertiary
      icon={icon}
      label={label}
      tip={tip}
      tipPlacement={tipPlacement ?? 'top'}
      badge={badge}
      {...props}
    />
  )
}

type SubHeaderButtonGroupProps = {
  children: ReactNode
}

export const SubHeaderButtonGroup = ({ children }: SubHeaderButtonGroupProps) => {
  const childNodes = Children.toArray(children)
  const theme = useTheme()

  return (
    <Box background="bg-1-alpha" round="20px" height="40px" flex={false}>
      <ButtonGroup>
        {childNodes.map((child, index) => {
          const prevElement = childNodes[index - 1] as ReactElement<SubHeaderButtonProps> | undefined
          const nextElement = childNodes[index + 1] as ReactElement<SubHeaderButtonProps> | undefined
          const childElement = child as ReactElement<SubHeaderButtonProps>

          const isSelected = childElement.props.active

          return (
            <Box
              key={index}
              css={`
                background: ${resolveColor('bg', theme)};
                border-radius: 20px;
                border-top-right-radius: ${isSelected && nextElement?.props?.active ? '0' : undefined};
                border-bottom-right-radius: ${isSelected && nextElement?.props?.active ? '0' : undefined};
                border-top-left-radius: ${isSelected && prevElement?.props?.active ? '0' : undefined};
                border-bottom-left-radius: ${isSelected && prevElement?.props?.active ? '0' : undefined};
              `}
            >
              <SubHeaderButton {...childElement.props} />
            </Box>
          )
        })}
      </ButtonGroup>
    </Box>
  )
}

const ButtonGroup = styled(Box)`
  outline-offset: -2px !important;
  display: inline-flex;
  flex-direction: row;
  width: max-content;
`

export const linkButtonCss = css<SubHeaderButtonProps & { $active?: boolean }>`
  background: ${props => resolveColor(props['aria-label'] ? 'bg-1' : 'bg-2', props.theme)};
  padding: 12px;
  height: 40px;
  border-radius: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  text-decoration: none;
  font-size: 15px;
  color: ${props => resolveColor(props.$active ? 'text' : 'text-light', props.theme)};
  font-weight: ${props => props.$active && '500'};

  &,
  &:hover,
  &:focus,
  &:active,
  &:active:focus:not([disabled]),
  &:active:hover:not([disabled]) {
    background: ${props => resolveColor(props.$active ? 'bg' : props['aria-label'] ? 'bg-1' : 'bg-2', props.theme)};

    svg {
      fill: ${props => props.$active && resolveColor('text', props.theme)};
    }
  }

  &:hover {
    background: ${props => !props.$active && resolveColor('bg-2', props.theme)};
    color: ${themeColor('text')};
  }
`

const StyledLink = styled(Link)<SubHeaderButtonProps & { $active?: boolean }>`
  ${linkButtonCss}
`

const StyledButton = styled(Button)<SubHeaderButtonProps & { $active?: boolean }>`
  ${linkButtonCss}
`

export const activeSubHeaderButtonCss = css`
  color: ${themeColor('text')};
  font-weight: 500;

  &,
  &:hover,
  &:focus,
  &:active,
  &:active:focus:not([disabled]),
  &:active:hover:not([disabled]) {
    background: ${themeColor('bg')};
    svg {
      fill: ${themeColor('text')};
    }
  }
`
