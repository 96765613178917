import { memo } from 'react'
import { capitalize } from 'lodash'

import { Box, DateTimePicker, TextArea, TextInput } from '@cutover/react-ui'
import { useRightPanelTypeState } from 'main/components/layout/right-panel'
import { useLanguage } from 'main/services/hooks'
import { FormEditPanel } from 'main/components/shared/form'
import { RunbookViewModel } from 'main/data-access'
import { AuditLogType } from 'main/services/queries/types'
import { useGetAuditLogItem } from 'main/services/queries/use-audit-log'

export const AuditLogItemPanel = memo(() => {
  const [{ logItemId }, { closeRightPanel }] = useRightPanelTypeState('audit-log-item')
  const { data, isLoading } = useGetAuditLogItem(logItemId ?? 0)

  if (!logItemId || !data) return null
  return <LogItemDetails onClose={closeRightPanel} key={logItemId} logItem={data.actionLog} isLoading={!!isLoading} />
})

type LogItemChange = {
  label: string
  oldValue?: string | null
  newValue?: string
}

const LogItemDetails = ({
  onClose,
  logItem,
  isLoading
}: {
  onClose: () => void
  logItem: AuditLogType
  isLoading: boolean
}) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'auditLog' })
  const activeTimezone = RunbookViewModel.useGet('activeTimezone')
  const isTaskAction = logItem.object_type === 'Task' || logItem.object_type === 'Task assignments'
  const isStreamAction = logItem.object_type === 'Stream' || logItem.object_type === 'Substream'
  const date = logItem.created_at ? new Date(logItem.created_at * 1000) : null
  const action = `${logItem.object_type} ${logItem.event_type}`

  const changeToValue = (changeValue: string | string[]) => {
    const isArray = Array.isArray(changeValue)
    return isArray ? changeValue.join(', ') : changeValue
  }

  const processCustomFieldChanges = (changes: any[] | null): Record<string, any> | null => {
    if (!changes || changes.length === 0) return null

    return changes.reduce((cfChanges, change) => {
      cfChanges[change.field_name] = change.changes
      return cfChanges
    }, {} as Record<string, any>)
  }

  // Note: association_changes can be in randomly inconsistent format - this is a bandaid and needs API fix
  // This fixes a bug in angular where predecessor change logs dont show
  // Also the pred changes are in the format [old, new] instead of other way around
  const processAssociationChanges = (changes: any): Record<string, any> | null => {
    if (!changes) return null
    if (changes.hasOwnProperty('association_name')) {
      return { [changes['association_name']]: changes['changes'] }
    } else if (changes.hasOwnProperty('predecessors')) {
      return { predecessors: [changes.predecessors[1], changes.predecessors[0]] }
    } else {
      return null
    }
  }

  const cfChanges = JSON.parse(logItem.custom_field_changes)
  const associationChanges = JSON.parse(logItem.association_changes)

  const rawChanges = {
    ...logItem.raw_change_description,
    ...(processCustomFieldChanges(cfChanges) || {}),
    ...(processAssociationChanges(associationChanges) || {})
  }

  const changes = [] as LogItemChange[]
  for (var key in rawChanges) {
    const change = rawChanges[key] //can be array [new,old], [[new],[old]] or string
    const isAddition = !Array.isArray(change)
    changes.push({
      label: capitalize(key),
      newValue: isAddition ? change : changeToValue(change[0]),
      oldValue: isAddition ? null : changeToValue(change[1])
    })
  }

  const objectName = logItem.event_type === 'bulk delete' ? logItem.bulk_tasks.join(',\n') : logItem.summary

  const snippetString =
    logItem.object_type === 'Snippet'
      ? 'Snippet ID #' + logItem.object_id + 'Version #' + logItem.object_version_id
      : null

  return (
    <FormEditPanel title={t('detailsPanelTitle')} onClose={onClose} formElementWrapper={false} loading={isLoading}>
      <Box flex={false}>
        <TextInput label={t('fields.action')} value={action} readOnly />
        {(isTaskAction || isStreamAction) && (
          <TextArea label={isStreamAction ? logItem.object_type : t('fields.task')} value={objectName} readOnly />
        )}
        <TextInput label={t('fields.author')} value={logItem.author_name} readOnly />
        <DateTimePicker label={t('fields.date')} timezone={activeTimezone} value={date} readOnly onChange={() => {}} />
        {snippetString && <TextInput icon="snippet" label={t('fields.snippetInfo')} value={snippetString} readOnly />}

        {changes.map(change => {
          return (
            <LogItemChange
              key={change.label}
              label={change.label}
              oldValue={change.oldValue}
              newValue={change.newValue}
            />
          )
        })}
      </Box>
    </FormEditPanel>
  )
}

const LogItemChange = ({ label, oldValue, newValue }: LogItemChange) => {
  const oldLabel = oldValue && newValue ? undefined : label

  return (
    <>
      {newValue && (
        <Box
          css={`
            svg {
              fill: green;
            }
          `}
        >
          {newValue.length > 32 ? (
            <TextArea leftIcon="task-added" label={label} value={newValue} readOnly />
          ) : (
            <TextInput icon="task-added" label={label} value={newValue} readOnly />
          )}
        </Box>
      )}
      {oldValue && (
        <Box
          css={`
            svg {
              fill: red;
            }
            div input,
            div textarea {
              text-decoration: line-through;
            }
            margin-top: ${oldValue && newValue ? '-28px' : undefined};
          `}
        >
          {oldValue.length > 32 ? (
            <TextArea leftIcon="task-deleted" label={oldLabel} value={oldValue} readOnly />
          ) : (
            <TextInput icon="task-deleted" label={oldLabel} value={oldValue} readOnly />
          )}
        </Box>
      )}
    </>
  )
}
