import { memo } from 'react'
import styled from 'styled-components/macro'

import { ChevronRight } from '@cutover/icons'
import { Box } from '../box'
import { themeColor } from '../../theme'
import { Text } from '../../typography'

export const EXPAND_COLLAPSE_TRANSITION_MS = 200
// TODO: is this stored/standardized anywhere?
const TRANSITION_TIMING_FN = 'cubic-bezier(0.35, 0, 0.25, 1)'

export const AccordionItemContentWrapper = styled(Box)`
  display: block;
  height: 0;
  overflow: hidden;
  opacity: 1;
  will-change: height, min-height;
  transition: min-height ${EXPAND_COLLAPSE_TRANSITION_MS}ms ${TRANSITION_TIMING_FN},
    height ${EXPAND_COLLAPSE_TRANSITION_MS}ms ${TRANSITION_TIMING_FN},
    opacity ${EXPAND_COLLAPSE_TRANSITION_MS}ms ${TRANSITION_TIMING_FN};

  .accordion-item--disable-animation & {
    transition: none;
    height: unset;
  }

  .accordion-item--active:not(.accordion-item--animating) & {
    overflow: visible;
  }
`

export const AccordionPanelItem = memo(styled.li`
  list-style: none;
  padding: 0;
  margin: 0;
  border-bottom: 1px solid ${themeColor('bg-3')};

  &.accordion-item {
    .accordion-item-heading-button {
      color: ${themeColor('text-light')};
      background: none;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      height: 54px;
      border: none;
    }

    .accordion-item-expand-collapse-icon {
      transition: transform ${EXPAND_COLLAPSE_TRANSITION_MS}ms ${TRANSITION_TIMING_FN};
      will-change: transform;
      transform: rotate(0deg);
      fill: ${themeColor('text-disabled')};
    }

    &--active {
      .accordion-item-heading-button {
        color: ${themeColor('text')};
        font-weight: 500;
      }

      .accordion-item-expand-collapse-icon {
        transform: rotate(90deg);
        fill: ${themeColor('text-light')};
      }
    }

    &--disabled {
      .accordion-item-heading-button {
        color: ${themeColor('text-disabled')};
      }

      .accordion-item-expand-collapse-icon {
        visibility: hidden;
      }
    }

    &--error {
      .accordion-item-heading-button {
        color: ${themeColor('error')};
      }
    }

    &--disable-animation {
      .accordion-item-expand-collapse-icon {
        transition: none;
      }
    }
  }
`)

export const AccordionItemButton = styled.button`
  margin: 0;
  padding: 0;
  padding: 4px;
`

export const AccordionLabelLeft = styled.div`
  display: flex;
  max-width: 100%;
  align-items: center;
  min-width: 0;
  min-height: 0;
  padding-left: 0;
  gap: 8px;
`

export const CollapseExpandIcon = styled(ChevronRight).attrs({
  'aria-hidden': true
})`
  .accordion-item:hover & {
    fill: ${themeColor('text')};
  }
`

export const StyledHeading = styled(Text).attrs(() => ({
  truncate: 'tip'
}))``

export const AccordionHeading = styled(StyledHeading).attrs(({ headingLevel }: { headingLevel: number }) => ({
  tag: `h${headingLevel}`,
  responsive: false,
  truncate: 'tip'
}))<{
  headingLevel: number
}>`
  font-size: 18px;
  font-weight: inherit;
  line-height: normal;
  margin: 0;
  padding: 0;
  color: ${themeColor('text-light')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: inherit;

  .accordion-item-heading-button:hover & {
    color: ${themeColor('text')};

    .accordion-item--error &,
    .accordion-item--disabled & {
      color: inherit;
    }
  }
`

export const AccordionList = styled(Box).attrs({
  tag: 'ul',
  flex: false
})`
  list-style: none;
  padding: 0;
  margin: 0;
  margin-block: 0;
  margin-inline: 0;
  padding-inline: 0;
`

export const AccordionLabelRight = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const SuffixContainer = styled.div`
  display: flex;
  color: ${themeColor('text-light')};
  flex-shrink: 0;
`
