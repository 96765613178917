import { BarTable } from '@cutover/react-ui'
import { DashboardMediaType } from '../../../widgets/types'
import { generateBarValues } from '../bar-table-widget-utils'
import { MrdDashboardWidget } from '../mrd-dashboard-widget'
import {
  ActiveDashboardItemType,
  DashboardItemClickProps
} from 'main/components/dashboards/dashboard-widget-collection'

export type RunbookListWidgetProps = {
  media: DashboardMediaType
  data: RunbookListWidgetData
  activeItem?: ActiveDashboardItemType
  onClickItem: (item: DashboardItemClickProps) => void
}

export type RunbookListWidgetData = {
  errors?: string[]
  filters?: any
  id: number
  name: string
  notes?: any
  type: string
  width?: number
  values: RunbookListValues[]
}

type RunbookListValues = {
  id: number
  name: string
  account_id: number
  account_slug: string
  status: 'red' | 'amber' | 'green' | 'off'
  status_message: string | null
  stage: string
  end_display: number
  start_display: number
  end_diff: EndDiff
  streams_count: number
  complete: number
  in_progress: number
  startable: number
  total: number
  default: number
}

type EndDiff = {
  value: string
  early: boolean
}

export const RunbookListWidget = ({ media, data, onClickItem, activeItem }: RunbookListWidgetProps) => {
  const { id: componentId, name, errors, notes, values } = data

  const handleRowClick = (id: string | number, stage?: string) => {
    const params = {
      'task[runbook_id]': id,
      'task[stage]': stage ? stage : undefined
    }

    onClickItem({
      componentId,
      itemId: id,
      panelType: 'dashboard-task-list',
      params: params,
      subFilterKey: 'stage',
      subFilterValue: stage
    })
  }
  const selectedRowId = activeItem?.componentId === componentId ? activeItem.itemId : null

  return (
    <MrdDashboardWidget title={name} errors={errors} notes={notes} fullWidth>
      <BarTable
        data={{ rowData: convertValuesToRowDataFormat(values) }}
        media={media}
        selectedRowId={selectedRowId}
        onRowClick={handleRowClick}
        onSegmentClick={handleRowClick}
      />
    </MrdDashboardWidget>
  )
}

const convertValuesToRowDataFormat = (values: RunbookListValues[]) => {
  const rowData = values.map(value => {
    const { name, complete, in_progress, startable, total, end_diff, stage, end_display, id, status } = value

    const durationValue = end_diff.value
    const durationColor = end_diff.early ? 'success' : 'warning'

    return {
      id,
      mainLabel: name,
      rowInfo: getEndDateString(stage, end_display),
      additionalRowInfo:
        stage === 'cancelled' || !durationValue ? null : { value: durationValue, color: durationColor },
      values: generateBarValues({
        bars: [
          { count: complete, tooltip: 'complete', displayStage: 'complete' },
          { count: in_progress, tooltip: 'in progress', displayStage: 'in-progress' },
          { count: startable, tooltip: 'startable', displayStage: 'startable' },
          { count: value.default, tooltip: 'not yet startable', displayStage: 'default' }
        ],
        total,
        context: 'task',
        barColors: ['progress-complete', 'progress-inprogress', 'progress-startable', 'progress-default'],
        displayPercentBarIndex: 0,
        includePercentageInTooltip: true
      }),
      status: status
    }
  })
  return rowData
}

const getEndDateString = (stage: string, endDateInSeconds: number): string | null => {
  if (!endDateInSeconds) return null
  let statusString: string = ''
  if (stage === 'cancelled') {
    statusString = 'Cancelled'
  } else if (stage === 'active' || stage === 'paused') {
    statusString = 'Due'
  } else if (stage === 'complete') {
    statusString = 'Completed'
  }
  // quick js way of cropping out date info we need
  const dateString = new Date(endDateInSeconds * 1000).toString().slice(4, 21)
  return `${statusString} ${dateString}`
}
