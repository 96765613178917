import { DonutChart, InteractiveDonutChart } from '@cutover/react-ui'
import { MrdDashboardWidget } from './mrd-dashboard-widget'
import { getDonutChartData, useDonutChart } from './use-donut-chart'
import { DashboardComponentProps, MrdDonutData } from '../types'
import { RightPanelType } from 'main/components/layout/right-panel'
import { RESOURCE_TYPE_FILTER_MAP } from '../../dashboard-widget-collection'

// ONLY BECAUSE OF LATENESS - very inconsistent / no pattern way of handling subFilterKey and subFilterValue
const LATENESS_SUB_FILTER_KEY_MAPPING: Record<
  string,
  { latenessSubFilterKey: string; latenessSubFilterValue: string | boolean }
> = {
  ontime: { latenessSubFilterKey: 'ontime', latenessSubFilterValue: true },
  late: { latenessSubFilterKey: 'latelessthan', latenessSubFilterValue: '3600' },
  vlate: { latenessSubFilterKey: 'latemorethan', latenessSubFilterValue: '3600' },
  other: { latenessSubFilterKey: 'display_stage', latenessSubFilterValue: 'planning' }
}

const getFilterValueFromSubfilter = (subFilter: string | number) => {
  // if string that is an array e.g. '76,77,78'
  if (typeof subFilter === 'string' && subFilter.includes(',')) {
    return subFilter.split(',')
  }

  // e.g. f[44]: [75]
  return [subFilter]
}

// Note: centralized wrappers for mrd dashboard donuts
// interactive for browser & simple donut for server
// TODO: rename to something relevant like 'DonutWidget'
export const BrowserWidget = ({ data, onClickItem, activeItem }: DashboardComponentProps<MrdDonutData>) => {
  const { id: componentId, filter_key: filterKey, filters, threshold, settings } = data
  const { resource_type: resourceType } = settings || {}

  let additionalFilters: { [key: string]: string | string[] } = {}

  if (filters) {
    additionalFilters =
      filters.resource_type === 'CustomField'
        ? { [`f[${filters.resource_id}]`]: filters.value }
        : filters.resource_type === 'Stage'
        ? { display_stage: [filters.value] }
        : { [filters.resource_type.toLowerCase()]: filters.value }
  }

  const handleSegmentClick = (segmentNode: any) => {
    if (segmentNode.id === 'other') return
    let subFilterKey = filterKey
    let subFilterValue = resourceType === 'Stream' ? segmentNode.name : segmentNode.id

    if (data.resource_type == 'CustomField') {
      subFilterKey = `f[${data.filter_key}]`
      subFilterValue = subFilterValue === 'not_set' ? 0 : subFilterValue
    }

    if (data.type === 'lateness') {
      const { latenessSubFilterKey, latenessSubFilterValue } = LATENESS_SUB_FILTER_KEY_MAPPING[segmentNode.id]

      if (latenessSubFilterKey === 'latelessthan' || latenessSubFilterKey === 'latemorethan') {
        subFilterValue = threshold ? threshold : latenessSubFilterValue // if threshold provided, use it, otherwise just use default
      } else {
        subFilterValue = latenessSubFilterValue
      }

      subFilterKey = latenessSubFilterKey
    }

    let panelType, params
    if (data.type === 'task_attribute_split' || data.type === 'task_stage') {
      panelType = 'dashboard-task-list' as RightPanelType
      const property = data.type === 'task_stage' ? 'task[stage]' : `task[${RESOURCE_TYPE_FILTER_MAP[resourceType]}]`
      params = {
        [property]: subFilterValue
      }
    } else {
      panelType = 'dashboard-runbook-list' as RightPanelType
      params = { [subFilterKey]: getFilterValueFromSubfilter(subFilterValue), ...additionalFilters }
    }

    const itemId = data.type === 'lateness' ? segmentNode.id : subFilterValue
    // Note: we need subFilterKey here to uniquely identify a clicked item when they dont share the same key attribute, eg 'lateness'
    onClickItem({ componentId, itemId, subFilterKey, panelType, params })
  }

  const { name, total, values, notes, errors } = useDonutChart(data)

  const getSelectedId = () => {
    if (activeItem?.componentId !== componentId) return null
    if (resourceType === 'Stream') {
      return values.find(item => item.name === activeItem.itemId)?.id
    }
    return activeItem.itemId
  }

  const selectedId = getSelectedId()

  return (
    <MrdDashboardWidget title={name} notes={notes} errors={errors}>
      <InteractiveDonutChart
        total
        centerValue={total}
        data={values}
        selectedId={selectedId}
        onSegmentClick={handleSegmentClick}
        hover
      />
    </MrdDashboardWidget>
  )
}

export const EmailWidget = ({ data }: DashboardComponentProps<MrdDonutData>) => {
  const { name, total, values } = getDonutChartData(data)

  return (
    <MrdDashboardWidget title={name}>
      <DonutChart total centerValue={total} data={values} height={125} />
    </MrdDashboardWidget>
  )
}

export const PirWidget = ({ data }: DashboardComponentProps<MrdDonutData>) => {
  const values = data.values.map(({ id, color, name, count, total }) => ({
    id: name?.toString() || id,
    color,
    name: name?.toString(),
    label: `${count ?? 0} ${name}`,
    value: count ?? total ?? 0
  }))

  return (
    <MrdDashboardWidget title={data.name}>
      <DonutChart total centerValue={data.total} data={values} height={125} />
    </MrdDashboardWidget>
  )
}
