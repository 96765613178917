import { Suspense, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'

import { Box, LoadingPanel } from '@cutover/react-ui'
import {
  SearchType,
  SubHeader,
  SubHeaderButton,
  SubHeaderButtonGroup,
  SubHeaderResultsText,
  SubHeaderSearch
} from 'main/components/layout/shared/sub-header'
import { DataSourceValueModel } from 'main/data-access/models/data-source-value-model'
import { useLanguage } from 'main/services/hooks'
import { useAccount } from 'main/services/api/data-providers/account/account-data'
import { useClearFilterState, useHasTemplateFilter, useSearchQueryFilter } from 'main/recoil/data-access'

export const DataSourceSubHeader = () => {
  const { account } = useAccount()
  const accountId = account?.id
  const { customFieldId } = useParams()

  return (
    <>
      {accountId && customFieldId && (
        <Suspense fallback={<LoadingPanel />}>
          <DataSourceSubHeaderInner accountId={accountId} customFieldId={parseInt(customFieldId)} />
        </Suspense>
      )}
    </>
  )
}

type DataSourceSubHeaderInnerProps = {
  accountId: number
  customFieldId: number
}

export const DataSourceSubHeaderInner = ({ accountId, customFieldId }: DataSourceSubHeaderInnerProps) => {
  const { t } = useLanguage('dataSources')
  const { valuesCount, filteredCount } = DataSourceValueModel.useGetCount({ customFieldId, accountId })

  const searchRef = useRef<SearchType>(null)
  const [searchFilter, setSearchFilter] = useSearchQueryFilter()
  const [hasTemplateFilter, setHasTemplateFilter] = useHasTemplateFilter()
  const clearSearchQuery = useClearFilterState('q')

  // TODO: hold for filter work (POL-96)
  // const toggleFilterPanel = useToggleFilterPanel()
  // const numFilters = useFilterCount()

  const handleSearch = (input?: string) => {
    if (input?.length === 0) {
      clearSearchQuery()
    } else {
      setSearchFilter(input)
    }
  }

  const handleClose = () => {
    if (searchFilter) {
      clearSearchQuery()
    }
  }

  useEffect(() => {
    if (searchFilter === undefined) {
      searchRef.current?.clear()
    }
  }, [searchFilter])

  const handleSetHasTemplateFilter = (hasTemplate?: number) => {
    setHasTemplateFilter(hasTemplate)
  }

  return (
    <SubHeader.Items>
      <SubHeader.LeftItems>
        {/* <FilterPanelToggleButton filterCount={numFilters} onClick={() => toggleFilterPanel()} onKeyUp={() => {}} /> */}
        <SubHeaderSearch ref={searchRef} onSearch={handleSearch} onClose={handleClose} initialValue={searchFilter} />
        <SubHeaderButtonGroup>
          <SubHeaderButton
            label={t('filter.all')}
            active={hasTemplateFilter === undefined}
            onClick={() => handleSetHasTemplateFilter(undefined)}
          />
          <SubHeaderButton
            label={t('filter.hasTemplates')}
            active={Number(hasTemplateFilter) === 1}
            onClick={() => handleSetHasTemplateFilter(1)}
          />
          <SubHeaderButton
            label={t('filter.noTemplates')}
            active={hasTemplateFilter !== undefined && Number(hasTemplateFilter) === 0}
            onClick={() => handleSetHasTemplateFilter(0)}
          />
        </SubHeaderButtonGroup>
        <Box justify="center" margin={{ left: '4px' }}>
          <SubHeaderResultsText value={t('results', { amount: `${filteredCount}/${valuesCount}` })} />
        </Box>
      </SubHeader.LeftItems>
    </SubHeader.Items>
  )
}
