import 'react-datepicker/dist/react-datepicker.css'
import { FocusEvent, forwardRef, RefObject, useCallback, useEffect, useId, useMemo, useRef, useState } from 'react'
import { Placement as PopperPlacement } from '@popperjs/core'
import { format, isValid, parse, startOfDay } from 'date-fns'
import enGB from 'date-fns/locale/en-GB'
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import DatePicker, { CalendarContainerProps, registerLocale } from 'react-datepicker'
import styled, { css } from 'styled-components/macro'
import { useMergeRefs } from 'use-callback-ref'

import { createLabelWithTimezone, getTimezoneOffsetString } from './date-utils'
import { IconButton } from '../../button'
import { Box } from '../../layout'
import { ColorProp, themeColor, themeElevation } from '../../theme'
import { formatTimeDiff } from '../../utilities'
import { FormField } from '../internal/form-field'
import { TextInput } from '../text-input'
import { TimePicker } from '../time-picker/time-picker'

const DEFAULT_CAL_WIDTH = 300

export type DateTimePickerProps = {
  value: Date | null
  label?: string
  name?: string
  onChange: (date: Date | null) => void
  disabled?: boolean
  readOnly?: boolean
  datePickerOnly?: boolean
  timePickerOnly?: boolean
  tooltipText?: string
  required?: boolean
  timezone?: string | null
  inlineError?: string
  hasError?: boolean
  inputRef?: any
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void
  'data-testid'?: string
  placeholder?: string
  minDate?: Date
  maxDate?: Date
  calendarPlacement?: PopperPlacement // TODO: remove direct deps to library types in our component props
  /**
   * This will have a fixed display of calendar drop down; prevents being hidden behind overflow of parent
   * @type {boolean}
   */
  fixed?: boolean
  a11yTitle?: string
  /**
   * Fixed width of calendar dropdown. If set as true (boolean), width defaults to 300px.
   * @type {number | boolean}
   */
  fixedWidth?: boolean | number
  labelSuffix?: string
  labelSuffixColor?: ColorProp
  diff?: {
    start: number // ms
    end: number // ms
  }
}

registerLocale('enGB', enGB)

export const DateTimePicker = ({ timePickerOnly, inputRef, ...props }: DateTimePickerProps) => {
  return timePickerOnly ? (
    <TimePicker {...props} hourInputRef={inputRef} plain={false} a11yTitle={props.a11yTitle ?? props.label} />
  ) : (
    <DateTime {...props} inputRef={inputRef} />
  )
}

// Note: date-time-picker now has 2 parts that is rendered inside the form-field / animated form-field
// 1) date text input that triggers date calendar when focused
// 2) time picker parts - includes cancel button and time-picker
export const DateTime = ({ placeholder, ...props }: DateTimePickerProps) => {
  const {
    value,
    label,
    timezone,
    tooltipText,
    hasError,
    inlineError,
    inputRef,
    disabled,
    readOnly,
    datePickerOnly,
    required,
    onFocus,
    onBlur,
    onChange,
    a11yTitle,
    fixedWidth,
    labelSuffix,
    labelSuffixColor,
    diff
  } = props
  const labelId = useId()
  const hasInitialValue = useMemo(() => !!value, [])
  const datePickerRef = useRef<any>(null) // TODO: update any type; not sure what date picker ref type is
  const formFieldContainerRef = useRef<HTMLDivElement>(null)
  const [dateInputWidth, setDateInputWidth] = useState<number | undefined>(undefined)
  const [isValidInput, setIsValidInput] = useState(true)
  const timezoneOffsetString = useMemo(
    () => (timezone ? getTimezoneOffsetString({ timezone, targetDate: value }) : ''),
    [timezone, value]
  )
  const [isLabelShrunk, setLabelShrunk] = useState(hasInitialValue)
  const [isFocused, setFocused] = useState(false)

  // [animated label related] variables and logic - similar to form components that uses animated form field (e.g. select, text-input-base)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const hasValueOrFocus = () => Boolean(datePickerRef?.current?.input.value) || (isFocused && !readOnly)

  useEffect(() => {
    setLabelShrunk(Boolean(datePickerRef?.current?.input.value))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setLabelShrunk(hasValueOrFocus())
  }, [hasValueOrFocus])

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    onFocus?.(event)
    setFocused(true)
    setLabelShrunk(true)
  }

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setIsValidInput(true)
    onBlur?.(event)
    setFocused(false)
    setLabelShrunk(hasValueOrFocus())
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isValidDateString(e.target.value)) {
      setIsValidInput(false)
    } else {
      setIsValidInput(true)
    }
    setLabelShrunk(e.target.value.length > 0)
  }

  const labelSize = isLabelShrunk || value ? 'small' : 'medium'
  // end of [animated label related]

  // Note: effect on mount to add observer monitoring width size of the form field; width used in CalendarContainer
  useEffect(() => {
    if (!formFieldContainerRef.current) {
      return
    }
    setDateInputWidth(formFieldContainerRef?.current?.offsetWidth)
    const getwidth = () => {
      if (fixedWidth) {
        return
      }
      setDateInputWidth(formFieldContainerRef?.current?.offsetWidth)
    }
    const inputWidthObserver = new ResizeObserver(getwidth)
    inputWidthObserver.observe(formFieldContainerRef?.current)
    return () => inputWidthObserver.disconnect()
  }, [])

  const showCancelButton = !(disabled || readOnly || required) && !!value
  const showTimePicker = !datePickerOnly && !!value

  const duration = useMemo(() => {
    if (!diff) return {}
    return formatTimeDiff(diff)
  }, [diff?.end, diff?.start])

  // type is any because grommet annoyingly types event as either button or link
  const handleClickClear = useCallback(
    (e: any) => {
      e.stopPropagation()
      e.preventDefault()
      onChange(null)
      datePickerRef.current?.setOpen(false)
      handleBlur(e)
    },
    [datePickerRef]
  )

  return (
    <FormField
      containerRef={formFieldContainerRef}
      labelSize={labelSize}
      hasError={!isValidInput || hasError || !!inlineError}
      // @ts-ignore form-field label type is just a string
      label={
        props.timezone
          ? createLabelWithTimezone({ label: label, timezoneOffsetString, required })
          : `${label}${required ? ' *' : ''}`
      }
      clickable={false}
      disabled={disabled}
      startIcon="calendar"
      helpText={tooltipText}
      labelProps={{ id: labelId, 'aria-label': a11yTitle ?? label }}
      labelSuffix={duration.label ?? labelSuffix}
      labelSuffixColor={duration.color ?? labelSuffixColor}
    >
      <Box direction="row" height="32px" width="100%" align="center">
        <DateInput
          {...props}
          inputPlaceholder={placeholder}
          inputRef={inputRef}
          datePickerRef={datePickerRef}
          dateInputWidth={dateInputWidth}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onInputChange={handleInputChange}
          aria-labelledby={labelId}
        />
        {showCancelButton && (
          <Box css="flex: 0 auto;">
            <IconButton
              data-testid="date-picker-clear"
              icon="close"
              label="Clear date"
              size="small"
              onClick={handleClickClear}
              tipPlacement="top"
            />
          </Box>
        )}
        {showTimePicker && isValidInput && (
          <TimePicker
            value={value ? new Date(value) : new Date()}
            disabled={disabled}
            readOnly={readOnly}
            a11yTitle={a11yTitle ?? label}
            onChange={onChange}
            timezone={timezone}
            hasError={hasError || !!inlineError}
            aria-labelledby={labelId}
          />
        )}
      </Box>
    </FormField>
  )
}

type DateInputProps = Omit<DateTimePickerProps, 'placeholder'> & {
  datePickerRef: RefObject<any>
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  dateInputWidth?: number
  inputPlaceholder?: string
  'aria-labelledby'?: string
  a11yTitle?: string
}

const DateInput = ({
  value,
  onChange,
  disabled,
  readOnly,
  tooltipText,
  timezone,
  inlineError,
  hasError,
  inputRef,
  onBlur,
  minDate,
  maxDate,
  calendarPlacement = 'bottom',
  'data-testid': dataTestId,
  datePickerRef,
  dateInputWidth,
  onFocus,
  onInputChange,
  label,
  inputPlaceholder,
  fixed = false,
  a11yTitle,
  fixedWidth
}: DateInputProps) => {
  const selectedDate = timezone ? (value ? utcToZonedTime(value, timezone) : null) : value

  const handleChange = (date: Date, e: any) => {
    e.preventDefault() // Note: this prevents calendar re-opening after change / selection
    const updatedDate = timezone ? zonedTimeToUtc(date, timezone) : date
    onChange(updatedDate)
  }

  const handleSelectDate = () => {
    setTimeout(() => {
      datePickerRef.current?.input.focus()
      // hack to refocus the input after selecting. The input itself is a new input so need to do this
    }, 0)
  }

  const [calendarRef, setCalendarRef] = useState<HTMLElement | null>(null)

  const calendarFixedWidth = fixedWidth ? (typeof fixedWidth === 'number' ? fixedWidth : DEFAULT_CAL_WIDTH) : undefined

  const calendarContainer = useMemo(
    () => createCalendarContainer(dateInputWidth, setCalendarRef, calendarFixedWidth),
    [dateInputWidth]
  )

  useEffect(() => {
    calendarRef?.addEventListener('mousedown', e => e.preventDefault())
  }, [calendarRef])

  const CustomInput = useMemo(
    () =>
      forwardRef<HTMLInputElement, any>((props, ref) => {
        const [inputString, setInputString] = useState(value ? format(value, 'EEE, dd MMM yyyy') : '')
        // Note: had to handle placeholder logic within custom input, if coming from DateTimeInput props (and placeholder as dep for memoized CustomInput)
        // it causes some rerendering issue and loses focus on input on initial click
        const [placeholder, setPlaceholder] = useState('')
        const { onClick } = props

        // To allow triggering the calendar when tabbing through the form
        const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === 'Enter') {
            e.preventDefault()
            e.stopPropagation()

            if (!datePickerRef.current.isCalendarOpen()) {
              datePickerRef.current?.setOpen(true)
            } else {
              datePickerRef.current?.setOpen(false)
              mergedRef.current?.blur()
            }
          }
        }, [])

        const mergedRef = useMergeRefs<HTMLInputElement>(inputRef ? [inputRef, ref] : [ref])

        const handleInputChange = (e: any) => {
          onInputChange?.(e)
          setInputString(e.target.value)
          if (e.target.value === '') {
            onChange(null)
          }
        }

        // Note: manual input gets validated on blur
        const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
          onBlur?.(e)
          setPlaceholder('') // always set the placeholder to blank onBlur; if its a invalid input string, it would get set to original value
          const inputValue = e.target.value
          const currentDate = value || startOfDay(new Date())
          if (!inputValue) return
          if (inputValue === format(currentDate, 'EEE, dd MMM yyyy')) return

          if (isValidDateString(inputValue)) {
            const validManualInputDate = new Date(inputValue)
            validManualInputDate.setHours(currentDate.getHours())
            validManualInputDate.setMinutes(currentDate.getMinutes())
            onChange(validManualInputDate)
          } else {
            e.target.value = value ? format(value, 'EEE, dd MMM yyyy') : ''
            setInputString(format(currentDate, 'EEE, dd MMM yyyy'))
          }
        }

        const getPlaceholder = useCallback(
          () => (datePickerRef.current?.input.value ? undefined : placeholder),
          [placeholder]
        )

        const handleFocus = useCallback(
          (e: any) => {
            onFocus?.(e)
            if (!datePickerRef.current?.input.value) {
              setPlaceholder(inputPlaceholder ?? 'Select date')
            }
          },
          [datePickerRef]
        )

        return (
          <TextInput
            ref={mergedRef}
            data-testid={dataTestId}
            disabled={disabled}
            readOnly={readOnly}
            hasError={hasError || !!inlineError}
            value={inputString}
            onBlur={handleBlur}
            label={label}
            onFocus={handleFocus}
            onKeyDown={handleKeyDown}
            onClick={onClick}
            placeholder={getPlaceholder()}
            tooltipText={tooltipText}
            onChange={handleInputChange}
            a11yTitle={`${a11yTitle ?? label} date`} // how to i18n for 'date'?
            inlineError={inlineError}
            plain
            css={`
               {
                padding: 0px;
                :focus {
                  outline: none;
                }
              }
            `}
          />
        )
      }),
    [value, inputRef, dataTestId, disabled, readOnly, hasError, inlineError, datePickerRef]
  )

  return (
    <Box
      direction="row"
      align="center"
      margin={{ left: '4px' }}
      css={`
        flex: 1;
        .react-datepicker-popper {
          z-index: 3;
        }
      `}
    >
      <DatePicker
        ref={datePickerRef}
        disabled={disabled}
        readOnly={readOnly}
        selected={selectedDate}
        onSelect={handleSelectDate}
        onChange={handleChange}
        customInput={<CustomInput />}
        popperPlacement={calendarPlacement}
        minDate={minDate}
        maxDate={maxDate}
        calendarContainer={calendarContainer}
        useWeekdaysShort
        dateFormat="EEE, dd MMM yyyy"
        locale="enGB"
        popperProps={fixed ? { strategy: 'fixed' } : {}}
        popperModifiers={[
          {
            name: 'offset',
            // Note: this was needed to adjust the popper (calendar) dropdown position
            // TODO: fix issue of position issue when resizing the calendar / container
            options: {
              offset: [!tooltipText ? (fixedWidth ? (fixed ? 10 : 28) : fixed ? 70 : 28) : value ? 34 : 26, 0]
            }
          }
        ]}
      />
    </Box>
  )
}

const isValidDateString = (dateString: string) => {
  const dateStringParts = dateString.split(' ')
  const yearStringLength = dateStringParts[dateStringParts.length - 1].length

  return yearStringLength === 4 ? isValid(parse(dateString, 'EEE, dd MMM yyyy', new Date())) : false
}

const createCalendarContainer =
  (width: number | undefined, calendarRef: (el: HTMLElement | null) => void, fixedWidth: number | undefined) =>
  ({ className, children }: CalendarContainerProps) => {
    return (
      <Calendar widthNumber={width} fixedWidth={fixedWidth} className={className} ref={calendarRef ?? undefined}>
        {children}
      </Calendar>
    )
  }

const Calendar = styled(Box)<{ widthNumber: number | undefined; fixedWidth: number | undefined }>`
  border-radius: 8px;
  border: none !important;
  box-shadow: ${themeElevation('medium')};
  font-family: 'Inter', sans-serif !important;
  overflow: hidden;
  padding-bottom: 8px;
  background-color: ${themeColor('menu-bg')} !important;

  .react-datepicker__month-container {
    ${({ fixedWidth, widthNumber }) =>
      fixedWidth
        ? css`
            max-width: ${fixedWidth}px !important;
            width: 100% !important;
          `
        : css`
            width: ${widthNumber}px !important;
          `}
  }
  .react-datepicker__header {
    background-color: ${themeColor('menu-bg')} !important;
    border: none !important;
    padding-bottom: 0 !important;
    .react-datepicker__current-month {
      color: ${themeColor('text')} !important;
    }
  }

  .react-datepicker__triangle {
    display: none !important;
  }

  .react-datepicker__day-names {
    display: flex;
    justify-content: space-between;
    margin: 0 14px;
  }

  .react-datepicker__day-name {
    color: ${themeColor('text-light')} !important;
  }

  .react-datepicker__day {
    margin: 1px !important;
    color: ${themeColor('text')};
    border-radius: 50% !important;

    &:hover {
      background-color: ${themeColor('bg-1')} !important;
    }

    &--outside-month {
      color: ${themeColor('text-disabled')} !important;
    }

    &--today {
      background-color: ${themeColor('primary-bg-2')} !important;
      border-radius: 50% !important;
      &:not(.react-datepicker__day--selected) {
        color: ${themeColor('text')} !important;
      }
    }

    &--keyboard-selected {
      background-color: ${themeColor('bg')} !important;
      box-shadow: inset 0 0 0 -2px ${themeColor('primary')};
      border-radius: 50% !important;
    }

    &--disabled {
      color: ${themeColor('text-disabled')} !important;
      &:hover {
        background-color: initial !important;
      }
    }

    &--selected {
      border-radius: 0 !important;
      background-color: ${themeColor('primary')} !important;
      color: ${themeColor('menu-bg')} !important;
      &:hover {
        background-color: ${themeColor('primary')} !important;
      }
      border-radius: 50% !important;
      font-weight: 700 !important;
    }
  }

  .react-datepicker__month {
    margin: 0 !important;
  }

  .react-datepicker__week {
    display: flex;
    justify-content: space-between;
    margin: 0 14px;
  }

  // Left/Right nav arrows
  .react-datepicker__navigation {
    height: 18px !important;
    width: 18px !important;
    margin: 8px 14px !important;
    background-size: contain !important;
    fill: ${themeColor('text-light')};
    border-radius: 50% !important;
    filter: ${({ theme }) => (theme.dark ? 'invert(1)' : 'invert(0)')};
    opacity: 0.5;
    &:hover {
      background-color: ${themeColor('bg-1')} !important;
    }
    &.react-datepicker__navigation--previous {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgMCA0OCA0OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTI0LjU4NTggNDRMNC43ODY3NyAyNC4yMDFMMjQuNTg1OCA0LjQwMjAyTDI2LjcwNzEgNi41MjMzNEwxMC41Mjk0IDIyLjcwMUw0MiAyMi43MDFMNDIgMjUuNzAxTDEwLjUyOTQgMjUuNzAxTDI2LjcwNzEgNDEuODc4N0wyNC41ODU4IDQ0WiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg==') !important;
    }
    &.react-datepicker__navigation--next {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgMCA0OCA0OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIyLjQxNDIgNEw0Mi4yMTMyIDIzLjc5OUwyMi40MTQyIDQzLjU5OEwyMC4yOTI5IDQxLjQ3NjdMMzYuNDcwNiAyNS4yOTlINVYyMi4yOTlIMzYuNDcwNkwyMC4yOTI5IDYuMTIxMzJMMjIuNDE0MiA0WiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg==') !important;
    }
    //Hide original icons
    .react-datepicker__navigation-icon--previous {
      display: none !important;
    }
    .react-datepicker__navigation-icon--next {
      display: none !important;
    }
  }
`
