import { DataSourceValue } from '../../queries/types'
import { DataSourceValueFilterContext } from './filter-context'
import { createDataSourceValueFilterFunction, DataSourceValueFilterType } from './filters'

export const hasTemplate = createDataSourceValueFilterFunction(
  't',
  (
    dataSourceValue: DataSourceValue,
    filters: DataSourceValueFilterType,
    context: DataSourceValueFilterContext
  ): boolean => {
    if (filters.t === undefined) return false
    if (!context.templates) return false

    const idExists = dataSourceValue.id in context.templates
    return Number(filters.t) === 0 ? !idExists : idExists
  }
)
