import { Avatar, BarTable, Box } from '@cutover/react-ui'
import { DashboardMediaType } from '../../types'
import { generateBarValues } from '../bar-table-widget-utils'
import { MrdDashboardWidget } from '../mrd-dashboard-widget'
import {
  ActiveDashboardItemType,
  DashboardItemClickProps,
  RESOURCE_TYPE_FILTER_MAP
} from 'main/components/dashboards/dashboard-widget-collection'

export type AttributeCompletionWidgetProps = {
  media: DashboardMediaType
  data: AttributeCompletionWidgetData
  activeItem?: ActiveDashboardItemType
  onClickItem: (item: DashboardItemClickProps) => void
}

export type AttributeCompletionWidgetData = {
  id: number
  name: string
  errors?: string[]
  filters?: {
    resource_type: string
    resource_id: string | null
    value: string
  } | null
  notes?: any
  type: string
  complete: number
  startable: number
  in_progress: number
  not_live: number
  default: number
  values: AttributeCompletionValues[]
  runbook_total: number
  total: number
  settings: any
}

type AttributeCompletionValues = {
  id: number
  name: string
  color: string
  complete: number
  in_progress: number
  startable: number
  not_live: number
  default: number
  runbook_total: number
  total: number
}

// This should be called 'runbook task completion'
export const AttributeCompletionWidget = ({ media, data, activeItem, onClickItem }: AttributeCompletionWidgetProps) => {
  const { id: componentId, name, errors, notes, values, type, settings } = data
  const { resource_type: resourceType } = settings || {}

  const attributeType = type === 'stream_completion' ? 'runbook' : 'task'
  const groupBy = name.includes('Team') ? 'Team' : 'Other' //not optimal way to differentiate..

  const handleRowClick = (id: string | number, displayStage?: string) => {
    const panelType = attributeType === 'task' ? 'dashboard-task-list' : 'dashboard-runbook-list'
    let params = {}
    if (attributeType === 'runbook') {
      // should never be 'runbook' - this appears a hack for stream completion
      params = {
        stream_name: values.find(value => value.id === id)?.name,
        stream_stage: displayStage === 'in-progress' ? 'in_progress' : displayStage || undefined
      }
    } else {
      params = {
        [`task[${RESOURCE_TYPE_FILTER_MAP[resourceType]}]`]: id,
        'task[stage]': displayStage || undefined
      }
    }

    onClickItem({ componentId, itemId: id, panelType, params, subFilterKey: 'stage', subFilterValue: displayStage })
  }

  const selectedRowId = activeItem?.componentId === componentId ? activeItem.itemId : null

  return (
    <MrdDashboardWidget title={name} errors={errors} notes={notes} fullWidth>
      <BarTable
        mainLabelAsComponent
        data={{ rowData: convertValuesToRowDataFormat(values, attributeType, groupBy) }}
        onRowClick={handleRowClick}
        onSegmentClick={handleRowClick}
        nameAsId={resourceType === 'Stream'}
        selectedRowId={selectedRowId}
        media={media}
      />
    </MrdDashboardWidget>
  )
}

const convertValuesToRowDataFormat = (values: AttributeCompletionValues[], attributeType: string, groupBy: string) => {
  const rowData = values.map(value => {
    const { name, complete, in_progress, startable, not_live, runbook_total, total, color, id } = value
    const runbookCountLabel = attributeType === 'task' ? ' in ' + runbook_total + ' runbooks' : ''
    const defaultBarColors = [
      'progress-complete',
      'progress-inprogress',
      'progress-startable',
      'progress-default',
      'progress-planning'
    ]
    const attributeLabel = attributeType === 'runbook' ? 'stream(s) ' : ''

    return {
      id,
      name,
      mainLabel: (
        <Box direction="row" align="center">
          {groupBy === 'Team' && (
            <Box margin={{ right: '4px' }} width="24px" css="flex-shrink: 0;">
              <Avatar subject={{ name, color }} size="small" />
            </Box>
          )}
          {` ${name}`}
        </Box>
      ),
      rowInfo: `${total} ${attributeType}${total > 1 ? 's' : ''} ${runbookCountLabel}`, // TODO: implement language hook
      values: generateBarValues({
        bars: [
          { count: complete, tooltip: `${attributeLabel}complete`, displayStage: 'complete' },
          { count: in_progress, tooltip: `${attributeLabel}in progress`, displayStage: 'in-progress' },
          { count: startable, tooltip: `${attributeLabel}startable`, displayStage: 'startable' },
          { count: value.default, tooltip: `${attributeLabel}not yet startable`, displayStage: 'default' },
          { count: not_live, tooltip: `${attributeLabel}in planning or rehearsal`, displayStage: 'not_live' }
        ],
        total,
        context: attributeType,
        barColors: defaultBarColors,
        displayPercentBarIndex: 0
      })
    }
  })
  return rowData
}
