import { Fragment, useState } from 'react'
import { Editor } from 'slate'
import { useSlate } from 'slate-react'

import { themeColor } from '../../../theme'
import { Button, IconButton } from '../../../button'
import { Menu, MenuListItem, MenuListItemDivider } from '../../../menu'
import { Box } from '../../../layout/box'
import {
  addColumn,
  addRow,
  getParentBlock,
  insertTable,
  isBlockActive,
  isBlockDisabled,
  isMarkActive,
  removeColumn,
  removeMarksInRange,
  removeRow,
  removeTable,
  toggleBlock,
  toggleMark
} from '../utils'
import { IconName } from '../../../icon'
import { Align, CustomElementTypes, Format, isAlignType } from '../text-editor-types'
import { useRovingTabIndex } from '../../../utilities/use-roving-tab-index'
import { SectionWrapper, ToolbarButton, ToolbarContainer } from './text-editor-toolbar-styled-components'
type MenuOption = {
  label: string
  shortLabel?: string
  key: string
  options?: any
  icon?: IconName
  destructive?: boolean
  newSection?: boolean
  hasNext?: boolean
  hideInRestrictedMode?: boolean
}

const blockMenuOptions: MenuOption[] = [
  { label: 'Normal', shortLabel: 'Normal', key: 'paragraph', icon: 'paragraph' },
  { label: 'Heading', shortLabel: 'Heading', key: 'heading', icon: 'heading' },
  { label: 'Quote', shortLabel: 'Quote', key: 'quote', icon: 'blockquote' },
  { label: 'Code Block', shortLabel: 'Code', key: 'code', icon: 'code-block', hideInRestrictedMode: true },
  {
    label: 'Alert box',
    shortLabel: 'Alert',
    key: 'message',
    icon: 'message-box',
    hasNext: true,
    options: { messageType: 'info' },
    hideInRestrictedMode: true
  },
  { label: 'Bulleted List', shortLabel: 'List', key: 'bulleted-list', icon: 'list-bulleted', newSection: true },
  { label: 'Numbered List', shortLabel: 'List', key: 'numbered-list', icon: 'list-numbered' }
]

const messageOptions: MenuOption[] = [
  { label: 'Info', key: 'message', icon: 'info', options: { messageType: 'info' } },
  { label: 'Idea', key: 'message', icon: 'lightbulb', options: { messageType: 'idea' } },
  { label: 'Success', key: 'message', icon: 'check', options: { messageType: 'success' } },
  { label: 'Warning', key: 'message', icon: 'alert', options: { messageType: 'warning' } },
  { label: 'Error', key: 'message', icon: 'task-deleted', options: { messageType: 'error' } }
]

const tableMenuOptions: MenuOption[] = [
  { label: 'Add row above', key: 'add-row-above', icon: 'row-add-before' },
  { label: 'Add row below', key: 'add-row-below', icon: 'row-add-after' },
  { label: 'Remove row', key: 'remove-row', icon: 'row-delete' },
  { label: 'Add column left', key: 'add-column-left', icon: 'column-add-before', newSection: true },
  { label: 'Add column right', key: 'add-column-right', icon: 'column-add-after' },
  { label: 'Remove column', key: 'remove-column', icon: 'column-delete' },
  { label: 'Remove table', key: 'remove-table', icon: 'delete', destructive: true, newSection: true }
]

const markMenuOptions: MenuOption[] = [
  { label: 'Italic', key: 'italic', icon: 'italic' },
  { label: 'Code', key: 'code', icon: 'code', hideInRestrictedMode: true },
  { label: 'Status Label', key: 'status', icon: 'tag', hasNext: true, hideInRestrictedMode: true },
  { label: 'Underline', key: 'underline', icon: 'underline', newSection: true },
  { label: 'Strikethrough', key: 'strikethrough', icon: 'strikethrough' },
  { label: 'Superscript', key: 'superscript', icon: 'superscript' },
  { label: 'Subscript', key: 'subscript', icon: 'subscript' },
  { label: 'Clear formatting', key: 'clear-formatting', icon: 'clear-format', destructive: true, newSection: true }
]

const statusMenuOptions: MenuOption[] = [
  { label: 'Info Label', key: 'status', icon: 'info', options: { statusType: 'info' } },
  { label: 'Idea Label', key: 'status', icon: 'lightbulb', options: { statusType: 'idea' } },
  { label: 'Success Label', key: 'status', icon: 'check', options: { statusType: 'success' } },
  { label: 'Warning Label', key: 'status', icon: 'alert', options: { statusType: 'warning' } },
  { label: 'Error Label', key: 'status', icon: 'task-deleted', options: { statusType: 'error' } }
]

export const TextEditorToolbar = ({
  openLinkModal,
  restrictedMode,
  plain,
  invertColor
}: {
  openLinkModal: () => void
  restrictedMode?: boolean
  plain?: boolean
  invertColor?: boolean
}) => {
  const editor = useSlate()
  const { setToolbarElement } = useRovingTabIndex()
  const [activeContextMenu, setActiveContextMenu] = useState<string>()
  const [activeOptionsMenu, setActiveOptionsMenu] = useState<string>()

  // Note this toolbar appears to re-init on EVERY change, eg while selecting
  // Is there a way to avoid this?
  // const [activeBlock, setActiveBlock] = useState(menuOptions[0])
  // Since always re-inited currently, we may as well do this:

  // What a user can do depends on width, what parent block they are in, and if they have something selected
  const activeBlock = getParentBlock(editor)
  const activeMarks = Editor.marks(editor)

  const handleTableOperation = (operation: string) => {
    if (operation === 'add-row-above') {
      addRow(editor, 'above')
    } else if (operation === 'add-row-below') {
      addRow(editor, 'below')
    } else if (operation === 'remove-row') {
      removeRow(editor)
    } else if (operation === 'add-column-left') {
      addColumn(editor, 'left')
    } else if (operation === 'add-column-right') {
      addColumn(editor, 'right')
    } else if (operation === 'remove-column') {
      removeColumn(editor)
    } else {
      removeTable(editor)
    }
  }

  const clickLinkIcon = () => {
    if (isBlockActive(editor, 'link')) return
    openLinkModal()
  }

  const getBlockLabel = () => {
    if (!activeBlock) {
      return 'Normal'
    } else if (activeBlock.type === 'table') {
      return 'Table'
    }
    const selectedBlockLabel = blockMenuOptions.find(opt => opt.key === activeBlock.type)?.shortLabel

    return selectedBlockLabel
  }

  return (
    <>
      <ToolbarContainer
        direction="row"
        ref={setToolbarElement}
        role="toolbar"
        gap="none"
        className="text-editor-toolbar"
        css={`
          background: ${invertColor ? themeColor('bg') : themeColor('bg-1')};
          padding: 4px;
          border-radius: ${invertColor ? 'none' : '8px'};
          overflow: hidden;
          flex-shrink: 0;
          position: sticky;
          top: 0;
          margin-top: ${plain ? '0' : '4px'};
          box-shadow: ${invertColor ? undefined : `0 2px 0 4px ${themeColor('bg')}`};
          z-index: 2;
        `}
      >
        {/* NOTE: this hidden button is a hack becuase the first button in the toolbar is not rendering properly. Can't figure out why
          so for now the below is in place. Need to figure out a long term fix. */}
        <IconButton disableTooltip data-roving-tabindex-ignore="true" label="dummy" icon="add" css="display: none;" />

        <Box css="width: 104px; flex-shrink: 0;">
          <Menu
            className="text-editor-toolbar"
            trigger={
              <Button
                tertiary
                full
                icon="caret-down"
                reverse
                onClick={event => {
                  event.stopPropagation()
                  // Note: activeContextMenu is a temporary state, so resets every time this menu is open
                  setActiveContextMenu('')
                  //ReactEditor.focus(editor)
                }}
                label={getBlockLabel()}
                size="medium"
                css="align-self: flex-start; justify-content: space-between"
              />
            }
          >
            {activeBlock && activeBlock.type === 'table'
              ? tableMenuOptions.map((option, i) => {
                  return (
                    <Fragment key={option + '-' + i}>
                      {option.newSection && <MenuListItemDivider />}
                      <MenuListItem
                        icon={option.icon}
                        label={option.label}
                        value={option.label}
                        destructive={option.destructive}
                        onClick={event => {
                          event.syntheticEvent.stopPropagation()
                          handleTableOperation(option.key)
                        }}
                      />
                    </Fragment>
                  )
                })
              : (activeBlock && activeBlock.type === 'message' && activeContextMenu !== 'default') ||
                activeContextMenu === 'message'
              ? messageOptions.map((option, i) => {
                  return (
                    <Fragment key={option + '-' + i}>
                      {i === 0 && (
                        <MenuListItem
                          icon="arrow-back"
                          label="Back"
                          value="back"
                          onClick={event => {
                            event.syntheticEvent.stopPropagation()
                            event.keepOpen = true
                            setActiveContextMenu('default')
                          }}
                        />
                      )}

                      <MenuListItem
                        icon={option.icon}
                        label={option.label}
                        value={option.label}
                        destructive={option.destructive}
                        selected={isBlockActive(editor, option.key as CustomElementTypes, 'type', option.options)}
                        onClick={event => {
                          event.syntheticEvent.stopPropagation()
                          toggleBlock(editor, option.key as CustomElementTypes, option.options)
                        }}
                      />
                    </Fragment>
                  )
                })
              : blockMenuOptions.map((option, i) => {
                  if (restrictedMode && option.hideInRestrictedMode) {
                    return
                  }
                  return (
                    <Fragment key={option + '-' + i}>
                      {option.newSection && <MenuListItemDivider />}

                      <MenuListItem
                        icon={option.icon}
                        label={option.label}
                        value={option.label}
                        hasNext={option.hasNext}
                        selected={isBlockActive(editor, option.key as CustomElementTypes, 'type')}
                        onClick={event => {
                          event.syntheticEvent.stopPropagation()
                          if (option.key === 'table') {
                            insertTable(editor)
                          } else if (option.key === 'message') {
                            setActiveContextMenu('message')
                            event.keepOpen = true
                          } else {
                            toggleBlock(editor, option.key as CustomElementTypes)
                          }
                        }}
                      />
                    </Fragment>
                  )
                })}
          </Menu>
        </Box>

        <SectionWrapper className="toolbar-section mark-section">
          <MarkButton format="bold" icon="bold" label="Bold" invertColor={invertColor} />
          <MarkButton
            format="italic"
            icon="italic"
            label="Italic"
            className="show-above-medium"
            invertColor={invertColor}
          />
          <MarkButton
            format="underline"
            icon="underline"
            label="Underline"
            className="show-above-xlarge"
            invertColor={invertColor}
          />
          <MarkButton
            format="strikethrough"
            icon="strikethrough"
            label="Strikethrough"
            className="show-above-xlarge"
            invertColor={invertColor}
          />
          {!restrictedMode && (
            <MarkButton format="code" icon="code" className="show-above-large" label="Code" invertColor={invertColor} />
          )}
          <Menu
            align="end"
            className="text-editor-toolbar"
            trigger={
              <IconButton
                icon="more-horizontal"
                onClick={event => {
                  event.stopPropagation()
                  setActiveOptionsMenu('')
                }}
                label="More options"
                tipPlacement="top"
                invertColor={invertColor}
                size="medium"
              />
            }
          >
            {(activeMarks && activeMarks.status && activeOptionsMenu !== 'default') || activeOptionsMenu === 'status'
              ? statusMenuOptions.map((option, i) => {
                  return (
                    <Fragment key={option + '-' + i}>
                      {i === 0 && (
                        <MenuListItem
                          icon="arrow-back"
                          label="Back"
                          value="back"
                          onClick={event => {
                            event.syntheticEvent.stopPropagation()
                            event.keepOpen = true
                            setActiveOptionsMenu('default')
                          }}
                        />
                      )}

                      <MenuListItem
                        key={option + '-' + i}
                        icon={option.icon}
                        label={option.label}
                        value={option.label}
                        selected={isMarkActive(editor, option.key as Format, option.options)}
                        onClick={event => {
                          event.syntheticEvent.stopPropagation()
                          toggleMark(editor, option.key as Format, option.options)
                        }}
                      />
                    </Fragment>
                  )
                })
              : markMenuOptions.map((option, i) => {
                  if (restrictedMode && option.hideInRestrictedMode) return
                  return (
                    <MenuListItem
                      key={option + '-' + i}
                      icon={option.icon}
                      label={option.label}
                      value={option.label}
                      hasNext={option.hasNext}
                      destructive={option.destructive}
                      selected={isMarkActive(editor, option.key as Format)}
                      onClick={event => {
                        event.syntheticEvent.stopPropagation()
                        if (option.key === 'status') {
                          setActiveOptionsMenu('status')
                          event.keepOpen = true
                        } else if (option.key === 'clear-formatting') {
                          removeMarksInRange(editor)
                        } else {
                          toggleMark(editor, option.key as Format)
                        }
                      }}
                    />
                  )
                })}
          </Menu>
        </SectionWrapper>

        <SectionWrapper className="toolbar-section link-section">
          <ToolbarButton
            icon="link"
            label="Link"
            isActive={isBlockActive(editor, 'link')}
            onClick={e => {
              e.stopPropagation()
              clickLinkIcon()
            }}
            invertColor={invertColor}
          />
        </SectionWrapper>

        <SectionWrapper className="toolbar-section list-section">
          <BlockButton format="bulleted-list" icon="list-bulleted" label="Bulleted list" />
          <BlockButton
            format="numbered-list"
            icon="list-numbered"
            label="Numbered list"
            className="show-above-medium"
            invertColor={invertColor}
          />
        </SectionWrapper>

        <SectionWrapper className="toolbar-section table-section">
          <ToolbarButton
            icon="table"
            label="Insert Table"
            isActive={isBlockActive(editor, 'table')}
            onClick={() => insertTable(editor)}
            invertColor={invertColor}
          />
        </SectionWrapper>

        {/* <SectionWrapper className="toolbar-section block-section show-above-large">
          <BlockButton format="heading" icon="heading" label="Heading" />
          <BlockButton format="quote" icon="blockquote" label="Quote" />
          <BlockButton format="code" icon="code-block" label="Code block" />
          {!restrictedMode && <BlockButton format="message" icon="message-box" label="Message" />}
        </SectionWrapper> */}

        <Box css="flex-grow:1;"></Box>
      </ToolbarContainer>
    </>
  )
}

const BlockButton = ({
  format,
  icon,
  className,
  label,
  invertColor
}: {
  label: string
  format: CustomElementTypes | Align
  icon: IconName
  className?: string
  invertColor?: boolean
}) => {
  const editor = useSlate()

  return (
    <ToolbarButton
      icon={icon}
      label={label}
      className={className}
      isActive={isBlockActive(editor, format, isAlignType(format) ? 'align' : 'type')}
      disabled={isBlockDisabled(editor)}
      onClick={() => toggleBlock(editor, format)}
      invertColor={invertColor}
      onMouseDown={event => {
        event.preventDefault()
      }}
    />
  )
}

const MarkButton = ({
  label,
  format,
  icon,
  className,
  invertColor
}: {
  label: string
  format: Format
  icon: IconName
  className?: string
  invertColor?: boolean
}) => {
  const editor = useSlate()

  return (
    <ToolbarButton
      icon={icon}
      label={label}
      tipDelay={800}
      className={className}
      isActive={isMarkActive(editor, format)}
      onClick={() => toggleMark(editor, format)}
      invertColor={invertColor}
      onMouseDown={event => {
        event.preventDefault()
      }}
    />
  )
}
