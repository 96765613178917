import { AppsContainer } from 'main/components/apps/apps-container'
import { ReactAngularConnector } from 'main/connectors/react-angular-connector'

export const AppsConnector = () => {
  return (
    <ReactAngularConnector mountEvent="open-apps-project" unmountEvent="close-apps-project">
      <AppsContainer />
    </ReactAngularConnector>
  )
}

export const HeaderAppConnector = () => {
  return (
    <ReactAngularConnector mountEvent="show-app-header-component" unmountEvent="close-apps-header">
      <AppsContainer appPosition="header" />
    </ReactAngularConnector>
  )
}

export const MobileHeaderAppConnector = () => {
  return (
    <ReactAngularConnector mountEvent="show-mobile-app-header-component" unmountEvent="close-apps-mobile-header">
      <AppsContainer appPosition="header" />
    </ReactAngularConnector>
  )
}
