import { Suspense, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { isArray, isObject } from 'lodash'

import { Box, LoadingPanel, NoResourceFound, Pill, Table, Text } from '@cutover/react-ui'
import { useRightPanelTypeValue, useSetActiveRightPanelState } from 'main/components/layout/right-panel'
import { useAccount, useAccountCustomFields } from 'main/services/api/data-providers/account/account-data'
import { useSetDefaultDatasource } from './use-default-data-source'
import { CustomField } from 'main/services/queries/types'
import { useRouting } from 'main/services/routing'
import { useLanguage } from 'main/services/hooks'
import { DataSourceValueModel } from 'main/data-access/models/data-source-value-model'

export const DataSourceDetails = () => {
  const navigate = useNavigate()
  const { toWorkspace } = useRouting()

  const { account } = useAccount()
  const accountId = account?.id
  const { customFieldId } = useParams<{ customFieldId?: string }>()
  const { customFieldsLookup } = useAccountCustomFields()

  if (accountId && customFieldId && customFieldsLookup) {
    const customField = customFieldsLookup[parseInt(customFieldId)]

    if (customField && isCustomFieldApplicable(customField, accountId)) {
      return (
        <Suspense fallback={<LoadingPanel />}>
          <DataSourceDetailsInner
            accountId={accountId}
            customField={customField}
            customFieldsLookup={customFieldsLookup}
          />
        </Suspense>
      )
    } else {
      navigate(toWorkspace({ accountSlug: account.slug }))
      return null
    }
  }

  return null
}

type DataSourceValuesRow = {
  id: number
  key: string
  templates: number
} & Record<string, any>

type DataSourceDetailsInnerProps = {
  accountId: number
  customField: CustomField
  customFieldsLookup: Record<number, CustomField>
}

const DataSourceDetailsInner = ({ accountId, customField, customFieldsLookup }: DataSourceDetailsInnerProps) => {
  const { t } = useLanguage('dataSources')
  const filteredData = DataSourceValueModel.useGetFilteredValues({ customFieldId: customField.id, accountId })
  const { openRightPanel, closeRightPanel } = useSetActiveRightPanelState()
  const { key } = useRightPanelTypeValue('runbook-templates-with-datasources')

  useSetDefaultDatasource({ accountId, customFieldId: customField.id })

  const dependentCustomFields = Object.values(customFieldsLookup).filter(
    cf => (cf as CustomField).source_custom_field_id === customField.id
  )

  const tableColumns = useMemo(
    () => [
      {
        header: customField.display_name || customField.name,
        property: customField.value_key || '',
        pin: true,
        render: (datum: DataSourceValuesRow) => <Text weight="bold">{datum.key}</Text>
      },
      {
        header: t('dataSources.table.columnHeaders.templates'),
        property: 'templates',
        size: '80px',
        render: (datum: DataSourceValuesRow) =>
          datum.templates > 0 ? (
            <Pill color="primary" label={t('dataSources.templatePill', { count: datum.templates })} />
          ) : (
            'None'
          )
      },
      ...((dependentCustomFields as CustomField[])?.map(dependentCustomField => ({
        header: dependentCustomField.display_name || dependentCustomField.name,
        property: dependentCustomField.value_key || '',
        render: (datum: any) => {
          const value = dependentCustomField.value_key ? datum[dependentCustomField.value_key] : undefined

          if (isArray(value)) {
            return (
              <ul>
                {value.map(i => (
                  <li key={i}>{i}</li>
                ))}
              </ul>
            )
          } else if (isObject(value)) {
            // Value of type object is not supported for attribute
            return ''
          } else {
            return value
          }
        }
      })) || [])
    ],
    [customFieldsLookup, dependentCustomFields]
  )

  const rowData = useMemo(
    () =>
      filteredData?.map(data => {
        const values = data.values || {}
        const { id, key, templates } = data
        return {
          id,
          key,
          templates,
          ...values
        }
      }),
    [filteredData]
  )

  const handleClickRow = ({ datum }: { datum: DataSourceValuesRow }) => {
    if (datum.key === key) {
      closeRightPanel()
    } else {
      openRightPanel({
        type: 'runbook-templates-with-datasources',
        key: datum.key,
        customField: customField,
        templates: datum.templates ?? 0
      })
    }
  }

  return (
    <Box css="padding-right: 16px;">
      {filteredData?.length > 0 ? (
        <Table
          columns={tableColumns}
          data={rowData}
          onClickRow={handleClickRow}
          selectedItem={key ? { key: 'key', value: key } : undefined}
        />
      ) : (
        <NoResourceFound context={t('context')} />
      )}
    </Box>
  )
}

// Note: might need to rename to something like isDataViewEnabled and use this as a filter function in the workspace-header too
const isCustomFieldApplicable = (customField: CustomField, selectedAccountId: number) => {
  const {
    field_type: { slug },
    global,
    account_id
  } = customField

  return (slug === 'searchable' || slug === 'multi_searchable') && (global || selectedAccountId === account_id)
}
