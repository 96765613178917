import { ReactNode, useEffect } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import { operations, useComponentPropsStateHandler } from './apps-component-props-helper'
import { AppFieldValues, AppsChannelResponse } from 'main/components/apps/apps-types'
import {
  useSetAppHeaderView,
  useSetAppsComponentProps,
  useSetAppsFieldValues,
  useSetAppsModalLoadingState,
  useSetAppsModalState,
  useSetAppViews
} from 'main/recoil/data-access'
import { useWebsockets } from 'main/services/hooks'
import { CurrentUserModel } from 'main/data-access'
import { WebsocketKeys } from './websocket-keys'

const APPS_USER_CHANNEL_NAME = 'AppUserChannel'

export const AppsUserChannelSubscriber = ({ children }: { children: ReactNode }) => {
  const websockets = useWebsockets()
  const user = CurrentUserModel.useGet()

  const setAppViews = useSetAppViews()
  const setAppModal = useSetAppsModalState()
  const setAppFieldValues = useSetAppsFieldValues()
  const setComponentProps = useSetAppsComponentProps()
  const setHeaderApp = useSetAppHeaderView()
  const setModalLoading = useSetAppsModalLoadingState()

  const componentPropsStateHandler = useComponentPropsStateHandler()
  const [searchParams, _setSeachParams] = useSearchParams()
  const runbookIdInParams = useParams().runbookId
  const runbookId =
    window.location.href.indexOf('/app/integration_action_item') != -1
      ? searchParams.get('runbook_id')
      : runbookIdInParams
  const subscriptionId = user.id

  const findAppsResourceChannelSubscription = (id: number, uniqueChannelName: string) => {
    return websockets.findExistingSubscription(APPS_USER_CHANNEL_NAME, id, uniqueChannelName)
  }

  const subscribeForRunbook = (id: number, uniqueChannelName: string) => {
    if (!findAppsResourceChannelSubscription(id, uniqueChannelName)) {
      websockets.subscribe(APPS_USER_CHANNEL_NAME, id, uniqueChannelName, {
        received: data => {
          if (data.response.resource_id === runbookId) {
            updateAppViewsState(data.response)
          }
        }
      })
    }
  }

  useEffect(() => {
    const uniqueChannelName = WebsocketKeys.appsUserSubscriber(subscriptionId)
    subscribeForRunbook(subscriptionId, uniqueChannelName)

    return () => {
      setAppModal({ view: undefined, appId: '', resourceId: '', open: false })
      findAppsResourceChannelSubscription(subscriptionId, uniqueChannelName)?.unsubscribe()
    }
  }, [runbookId])

  const updateAppViewsState = (response: AppsChannelResponse) => {
    const appId = response.app_id
    const resourceId = response.resource_id
    const view = response.view
    const state = response.state
    const type = view.type
    const context = `${resourceId}-${appId}`

    if (response.session_id && response.session_id !== window.sessionStorage.getItem('browserHash')) {
      return null
    }

    componentPropsStateHandler({ response, context, setComponentProps })

    if (view.close_modal) {
      setAppModal({ view: undefined, appId, resourceId, open: false })
    }

    if (!operations.includes(type)) {
      if (state) {
        setAppFieldValues(prevValues => {
          const updatedState = { ...prevValues, [context]: state }
          return updatedState as AppFieldValues
        })
      }

      switch (type) {
        // TODO: if you send a modal to a page directly it has a white background.
        case 'modal':
          if (!view.close_modal) {
            setModalLoading(false)
            setAppModal({ view, appId, resourceId, open: true })
          }
          break
        case 'page':
          response.view.content?.forEach(content => {
            if (content.type === 'modal') {
              setModalLoading(false)
              setAppModal({
                view: { ...content, appId, resourceId, id: '' },
                appId,
                resourceId,
                open: true
              })
            }
          })

          setAppViews(views => {
            const updatedView = {
              ...response.view,
              appId,
              resourceId
            }
            return { ...views, [context]: updatedView }
          })
          break

        // TODO: following are identical in apps-resource-channel-subscriber.tsx
        case 'header':
          setHeaderApp({ view, appId, resourceId })
          break
        case 'panel':
          setAppViews(views => {
            const existingView = views[context]
            const updatedView = {
              ...response.view,
              appId,
              resourceId,
              visible: existingView?.visible,
              order: existingView?.order
            }
            return { ...views, [context]: updatedView }
          })
          break
        default:
          console.warn(`User resource channel does not accept ${type} type nodes.`)
      }
    }
  }

  return <>{children}</>
}
