import * as yup from 'yup'

import { customFieldValidation } from 'main/components/shared/custom-field-form'

export type CreateRunbookFormType = yup.InferType<ReturnType<typeof buildCreateRunbookSchema>>
export const buildCreateRunbookSchema = (
  fieldValueValidation: ReturnType<typeof customFieldValidation>,
  isCreateFromTemplate: boolean
) => {
  return yup.object({
    _step: yup.number().oneOf([1, 2]),
    base_template: isCreateFromTemplate
      ? yup.object({
          id: yup.number().required(),
          name: yup.string(),
          project: yup.object({
            id: yup.number()
          }),
          field_values: yup.array(),
          template_status: yup
            .string()
            .oneOf(['template_draft', 'template_pending', 'template_approved', 'template_rejected'])
            .notRequired(),
          settings_lock_template_copies_to_folder: yup.boolean()
        })
      : yup
          .object({
            id: yup.number(),
            name: yup.string(),
            project: yup.object({
              id: yup.number()
            }),
            field_values: yup.array(),
            template_status: yup
              .string()
              .oneOf(['template_draft', 'template_pending', 'template_approved', 'template_rejected']),
            settings_lock_template_copies_to_folder: yup.boolean()
          })
          .nullable(),
    runbook: yup.object({
      name: yup.string().required(),
      account_id: yup.number().required(),
      runbook_type_id: yup.number().required(),
      project_id: yup.number(),
      is_template: yup.bool().required(),
      timezone: yup.string(),
      runbook_versions_attributes: yup.array().of(
        yup.object({
          start_scheduled: yup.date().notRequired(),
          end_scheduled: yup.date().notRequired()
        })
      )
    }),
    field_values: fieldValueValidation,
    role_types: yup.array().of(
      yup.object({
        id: yup.number(),
        description: yup.string().nullable(),
        name: yup.string(),
        users: yup.array().of(
          yup.object({
            role_id: yup.number(),
            id: yup.number()
          })
        )
      })
    ),
    shift_time: yup.bool(),
    ai_config: yup.object({
      purpose: yup.string(),
      supporting_ai_options: yup.string().oneOf(['none', 'text', 'file']),
      supporting_ai_text: yup.string(),
      supporting_ai_file: yup.mixed()
    })
  })
}
