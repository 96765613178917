import { ThemeType as GrommetThemeType } from 'grommet/themes'
import tc from 'tinycolor2'
import { LiteralUnion, SetRequired } from 'type-fest'

type GrommetThemeGlobal = SetRequired<GrommetThemeType, 'global'>['global']
type GrommetColors = SetRequired<GrommetThemeGlobal, 'colors'>['colors']

export const BASE_LIGHT_STRING = 'rgba(255,255,255,1)'
export const BASE_DARK_STRING = 'rgba(22,22,29,1)'

// We need to separate primitive colour defs from specific named uses. Right now this is a mess
// Eventually this needs to be an export/sync from Figma
// Do not calculate colours, they need to be defined specifically
// Note: primitives not intended to be semantic, like the named ones
// This object should be the only place actual colours are defined, anywhere
const PRIMITIVES = {
  brand: {
    100: '#e5ebf8', // selected (0.12)
    500: '#2a55c3' // primary
  },
  brandDark: {
    // dark mode alternative shade
    100: '#212945', // 0.2
    500: '#4C74E6'
  },
  orange: { base: { light: 'rgba(255,170,21,1)', dark: '#f89406' } },
  red: { base: { light: 'rgba(255,64,64,1)', dark: '#ff3300' } },
  green: { base: { light: 'rgba(0,199,129,1)', dark: '#5cb85c' }, alt: 'rgba(0,199,129,0.8)' },
  grey: {
    50: '#fafafa', // row stripe
    100: '#f6f6f6', // standard bg
    200: '#efefef',
    300: '#e8e8e8',
    400: '#e1e1e2',
    800: '#6b6b6f', // text light - min 4.5:1 on 200 bg
    900: '#16161d' // base (text dark)
  },
  greyAlpha: {
    100: 'rgba(22, 22, 29, 0.04)', // hover - needs to multiply
    200: 'rgba(22, 22, 29, 0.08)'
  },
  white: {
    50: '#1f1f26', // row stripe (.04)
    100: '#24242B', // standard bg (0.06)
    200: '#29292F', // (0.08)
    300: '#2D2D34', // (0.1)
    400: '#323238', // (0.12)
    700: '#909093', // text light (.48)
    800: '#e1e1e2', // text (.87)
    900: '#ffffff'
  },
  whiteAlpha: {
    100: 'rgba(255, 255, 255, 0.06)', // hover - needs to multiply
    200: 'rgba(255, 255, 255, 0.08)'
  }
}

const BASE_DARK = tc(BASE_DARK_STRING)
const BASE_LIGHT = tc(BASE_LIGHT_STRING)
const BASE_PRIMARY = tc('#2a55c3')
const BASE_PRIMARY_DARKMODE = tc('#7b96da')

const STATUS_COLOR = {
  success: {
    light: tc('#1F7e25'),
    dark: tc('#249936')
  },
  warning: {
    light: tc('#b95000'),
    dark: tc('#e06c00')
  },
  error: {
    light: tc('#d62300'),
    dark: tc('#f53d19')
  }
}

const MESSAGE_STATUS_COLOR = {
  error: {
    light: tc('#D62300'),
    dark: tc('#EB5133')
  },
  success: {
    light: tc('#157F25'),
    dark: tc('#249936')
  },
  warning: {
    light: tc('#B95000'),
    dark: tc('#E06C00')
  },
  info: {
    light: tc('#16161D'), // neutral-bg-2
    dark: tc('#FFFFFF')
  },
  'info-notify': {
    light: tc('#E1E1E2'),
    dark: tc('#16161D')
  },
  idea: {
    light: tc('#2A55C3'), // primary-bg
    dark: tc('#7B96DA')
  }
}

// red/amber/green brighter indicator colors
const RAG_GREEN = 'rgba(0,199,129,1)'
const RAG_AMBER = 'rgba(255,170,21,1)'
const RAG_RED = 'rgba(255,64,64,1)'

const RAG_INDICATOR_COLOR = {
  'rag-red': RAG_RED,
  'rag-green': RAG_GREEN,
  'rag-amber': RAG_AMBER,
  'rag-red-pulse': tc(RAG_RED).setAlpha(0.4).toRgbString(),
  'rag-amber-pulse': tc(RAG_AMBER).setAlpha(0.4).toRgbString(),
  'rag-green-pulse': tc(RAG_GREEN).setAlpha(0.4).toRgbString()
}

const FEATURE_COLORS = {
  orange: '#E47F2C',
  yellow: '#E4CF2C',
  'green-light': '#AAE42C',
  green: '#5AE42C',
  'green-bright': '#2CE44E',
  turquois: '#2CE49D',
  'blue-light': '#2CDBE4',
  blue: '#2C8BE4',
  'blue-bright': '#2C3BE4',
  violet: '#6C2CE4',
  purple: '#BC2CE4',
  pink: '#E42CBC'
}

const TEXT_COLORS = {
  text: {
    light: PRIMITIVES.grey['900'],
    dark: PRIMITIVES.white['800']
  },
  'text-light': {
    light: PRIMITIVES.grey['800'],
    dark: PRIMITIVES.white['700']
  },

  'text-disabled': {
    light: tc(BASE_DARK_STRING).setAlpha(0.37).toRgbString(), // #16161d5e rgba(22,22,29,0.37)
    dark: tc(BASE_LIGHT_STRING).setAlpha(0.26).toRgbString() // #ffffff42 rgba(255,255,255,0.26)
  },

  'text-on-primary': {
    light: BASE_LIGHT.toRgbString(), // #ffffff rgba(255,255,255,1)
    dark: BASE_DARK.toRgbString() // #16161d rgba(22,22,29,1)
  },

  'text-on-primary-light': {
    light: tc(BASE_LIGHT_STRING).setAlpha(0.76).toRgbString(), // #ffffffc1 rgba(255,255,255,0.76)
    dark: tc(BASE_DARK_STRING).setAlpha(0.8).toRgbString() // #16161dcc rgba(22,22,29,0.8)
  },

  'text-on-primary-disabled': {
    light: tc(BASE_LIGHT_STRING).setAlpha(0.4).toRgbString(), // #ffffff66 rgba(255,255,255,0.4)
    dark: tc(BASE_DARK_STRING).setAlpha(0.47).toRgbString() // #16161d77 rgba(22,22,29,0.47)
  }
}

// deorecate in favour of semantic
const BASE_COLORS = {
  // TODO: consider being consistent with whether the variations start with 1 (like bg here) or 2 (like primary)
  // after the base. Would need to be updated in figma as well.
  bg: {
    light: PRIMITIVES.white['900'],
    dark: PRIMITIVES.white['100']
  },
  'bg-0': {
    light: PRIMITIVES.grey['50'],
    dark: PRIMITIVES.white['50']
  },

  'bg-1': {
    light: PRIMITIVES.grey['100'],
    dark: PRIMITIVES.white['100']
  },
  'bg-1-alpha': {
    light: PRIMITIVES.greyAlpha['100'],
    dark: PRIMITIVES.whiteAlpha['100']
  },
  'bg-2': {
    light: PRIMITIVES.grey['200'],
    dark: PRIMITIVES.white['200']
  },
  'bg-2-alpha': {
    light: PRIMITIVES.greyAlpha['200'],
    dark: PRIMITIVES.whiteAlpha['200']
  },
  'bg-3': {
    light: PRIMITIVES.grey['300'],
    dark: PRIMITIVES.white['300']
  },
  'bg-4': {
    light: PRIMITIVES.grey['400'],
    dark: PRIMITIVES.white['400']
  },

  primary: {
    light: PRIMITIVES.brand['500'],
    dark: PRIMITIVES.brandDark['500']
  },
  'primary-1': {
    light: tc.mix(BASE_PRIMARY, BASE_DARK, 16).toRgbString(), // #274ba8 rgb(39,75,168)
    dark: tc.mix(BASE_PRIMARY_DARKMODE, BASE_LIGHT, 16).toRgbString() // #90a7e0 rgb(144,167,224)
  },
  'primary-2': {
    light: tc.mix(BASE_PRIMARY, BASE_DARK, 24).toRgbString(), // #25469b rgb(37,70,155)
    dark: tc.mix(BASE_PRIMARY_DARKMODE, BASE_LIGHT, 24).toRgbString() // #9bafe3 rgb(155,175,227)
  },
  'primary-3': {
    light: tc.mix(BASE_PRIMARY, BASE_DARK, 32).toRgbString(), // #24418e rgb(36,65,142)
    dark: tc.mix(BASE_PRIMARY_DARKMODE, BASE_LIGHT, 32).toRgbString() // #a5b8e6 rgb(165,184,230)
  },

  // FIXME:
  'primary-bg': {
    light: tc.mix(BASE_PRIMARY, BASE_LIGHT, 92).toRgbString(), // #eef1fa rgb(238,241,250)
    dark: tc.mix(BASE_PRIMARY_DARKMODE, BASE_DARK, 92).toRgbString() // #1e202c rgb(30, 32, 44)
  },
  'primary-bg-alpha': {
    // Note: needs to be fully sync'd with new design system later
    light: 'rgba(42,85,195,0.12)',
    dark: 'rgba(108,168,238,0.12)'
  },
  'primary-bg-2': {
    light: tc.mix(BASE_PRIMARY, BASE_LIGHT, 84).toRgbString(), // #dde4f5 rgb(221,228,245)
    dark: tc.mix(BASE_PRIMARY_DARKMODE, BASE_DARK, 84).toRgbString() // #262a3b rgb(38,42,59)
  },
  'primary-bg-3': {
    light: tc.mix(BASE_PRIMARY, BASE_LIGHT, 76).toRgbString(), // #ccd6f1 rgb(204,214,241)
    dark: tc.mix(BASE_PRIMARY_DARKMODE, BASE_DARK, 76).toRgbString() // #2e354a rgb(46,53,74)
  },
  'primary-bg-4': {
    light: tc.mix(BASE_PRIMARY, BASE_LIGHT, 68).toRgbString(), // #bbc9ec rgb(187,201,236)
    dark: tc.mix(BASE_PRIMARY_DARKMODE, BASE_DARK, 68).toRgbString() // #363f59 rgb(54,63,89)
  },

  /**
   * Notification colors (toasters)
   */
  'notify-text': {
    light: BASE_LIGHT.toRgbString(),
    dark: BASE_LIGHT.toRgbString()
  },
  'notify-info-text': {
    light: tc(MESSAGE_STATUS_COLOR.info.light).setAlpha(0.62).toRgbString(),
    dark: BASE_LIGHT.setAlpha(0.49).toRgbString()
  },
  'notify-info': {
    light: tc(MESSAGE_STATUS_COLOR['info-notify'].light).toRgbString(),
    dark: tc(MESSAGE_STATUS_COLOR['info-notify'].dark).toRgbString()
  },
  'notify-warning': {
    light: tc(MESSAGE_STATUS_COLOR.warning.light).toRgbString(),
    dark: tc(MESSAGE_STATUS_COLOR.warning.dark).toRgbString()
  },
  'notify-success': {
    light: tc(MESSAGE_STATUS_COLOR.success.light).toRgbString(),
    dark: tc(MESSAGE_STATUS_COLOR.success.dark).toRgbString()
  },
  'notify-error': {
    light: tc(MESSAGE_STATUS_COLOR.error.light).toRgbString(),
    dark: tc(MESSAGE_STATUS_COLOR.error.dark).toRgbString()
  },

  /**
   * Message theme - possibly substitute similar STATUS_COLOR literals
   */
  'message-error': {
    light: tc(MESSAGE_STATUS_COLOR.error.light).toRgbString(),
    dark: tc(MESSAGE_STATUS_COLOR.error.dark).toRgbString()
  },
  'message-error-bg': {
    light: tc(MESSAGE_STATUS_COLOR.error.light).setAlpha(0.08).toRgbString(),
    dark: tc(MESSAGE_STATUS_COLOR.error.dark).setAlpha(0.08).toRgbString()
  },
  'message-success': {
    light: tc(MESSAGE_STATUS_COLOR.success.light).toRgbString(),
    dark: tc(MESSAGE_STATUS_COLOR.success.dark).toRgbString()
  },
  'message-success-bg': {
    light: tc(MESSAGE_STATUS_COLOR.success.light).setAlpha(0.08).toRgbString(),
    dark: tc(MESSAGE_STATUS_COLOR.success.dark).setAlpha(0.08).toRgbString()
  },
  'message-warning': {
    light: tc(MESSAGE_STATUS_COLOR.warning.light).toRgbString(),
    dark: tc(MESSAGE_STATUS_COLOR.warning.dark).toRgbString()
  },
  'message-warning-bg': {
    light: tc(MESSAGE_STATUS_COLOR.warning.light).setAlpha(0.07).toRgbString(),
    dark: tc(MESSAGE_STATUS_COLOR.warning.dark).setAlpha(0.07).toRgbString()
  },
  'message-info': {
    light: tc(MESSAGE_STATUS_COLOR.info.light).setAlpha(0.62).toRgbString(),
    dark: tc(MESSAGE_STATUS_COLOR.info.dark).setAlpha(0.49).toRgbString()
  },
  'message-info-bg': {
    light: tc(MESSAGE_STATUS_COLOR.info.light).setAlpha(0.04).toRgbString(),
    dark: tc(MESSAGE_STATUS_COLOR.info.dark).setAlpha(0.04).toRgbString()
  },
  'message-idea': {
    light: tc(MESSAGE_STATUS_COLOR.idea.light).toRgbString(),
    dark: tc(MESSAGE_STATUS_COLOR.idea.dark).toRgbString()
  },
  'message-idea-bg': {
    light: tc(MESSAGE_STATUS_COLOR.idea.light).setAlpha(0.08).toRgbString(),
    dark: tc(MESSAGE_STATUS_COLOR.idea.dark).setAlpha(0.08).toRgbString()
  },

  success: {
    light: STATUS_COLOR.success.light.toRgbString(), // #1F7e25 rgb(31,126,37)
    dark: STATUS_COLOR.success.dark.toRgbString() // #249936 rgb(36,153,54)
  },
  'success-bg': {
    light: tc.mix(STATUS_COLOR.success.light, BASE_LIGHT, 92).toRgbString(), // #edf5ee rgb(237,245,238)
    dark: tc.mix(STATUS_COLOR.success.dark, BASE_DARK, 92).toRgbString() // #17201f rgb(23,32,31)
  },

  warning: {
    light: STATUS_COLOR.warning.light.toRgbString(), // #b95000 rgb(185,80,0)
    dark: STATUS_COLOR.warning.dark.toRgbString() // #e06c00 rgb(224,108,0)
  },
  'warning-bg': {
    light: tc.mix(STATUS_COLOR.warning.light, BASE_LIGHT, 93).toRgbString(), // #faf3ed rgb(250,243,237)
    dark: tc.mix(STATUS_COLOR.warning.dark, BASE_DARK, 92).toRgbString() // #261d1b rgb(38,29,27)
  },

  error: {
    light: STATUS_COLOR.error.light.toRgbString(), // #d62300 rgb(214,35,0)
    dark: STATUS_COLOR.error.dark.toRgbString() // #f53d19 rgb(245,61,25)
  },
  'error-bg': {
    light: tc.mix(STATUS_COLOR.error.light, BASE_LIGHT, 92).toRgbString(), // #fcedeb rgb(252, 237, 235)
    dark: tc.mix(STATUS_COLOR.error.dark, BASE_DARK, 92).toRgbString() // #28191d rgb(40, 25, 29)
  },

  icon: {
    light: BASE_DARK.toRgbString(), // #16161d rgba(22,22,29,1)
    dark: tc(BASE_LIGHT_STRING).setAlpha(0.87).toRgbString() // #ffffffdd rgba(255,255,255,0.87)
  },

  'feature-orange': FEATURE_COLORS.orange,
  'feature-yellow': FEATURE_COLORS.yellow,
  'feature-green-light': FEATURE_COLORS['green-light'],
  'feature-green': FEATURE_COLORS.green,
  'feature-green-bright': FEATURE_COLORS['green-bright'],
  'feature-turquois': FEATURE_COLORS.turquois,
  'feature-blue-light': FEATURE_COLORS['blue-light'],
  'feature-blue': FEATURE_COLORS.blue,
  'feature-blue-bright': FEATURE_COLORS['blue-bright'],
  'feature-violet': FEATURE_COLORS.violet,
  'feature-purple': FEATURE_COLORS.purple,
  'feature-pink': FEATURE_COLORS.pink
} as const

const STAR_COLOR = {
  star: {
    light: 'rgb(251,196,56)',
    dark: 'rgb(251,196,56)'
  },
  'star-bg-alpha': {
    light: 'rgba(251, 196, 56, 0.16)',
    dark: 'rgba(251, 196, 56, 0.16)'
  },
  'star-bg': {
    light: 'rgb(254, 246, 223)',
    dark: 'rgb(254, 246, 223)'
  }
}

const NAV_COLORS = {
  'nav-bg': {
    light: BASE_COLORS.primary.light,
    dark: BASE_COLORS['bg-2'].dark
  },
  'nav-search': {
    light: BASE_COLORS['primary-1'].light,
    dark: BASE_COLORS['bg-1'].dark
  },
  'nav-search-active': {
    light: BASE_COLORS['primary-2'].light,
    dark: BASE_COLORS['bg-2'].dark
  },
  'nav-search-placeholder': {
    light: TEXT_COLORS['text-on-primary-disabled'].light,
    dark: TEXT_COLORS['text-disabled'].dark
  },
  'nav-search-text': {
    light: TEXT_COLORS['text-on-primary'].light,
    dark: TEXT_COLORS['text'].dark
  },
  'nav-heading-color': {
    light: BASE_LIGHT_STRING,
    dark: BASE_LIGHT_STRING
  },
  'nav-item-bg': {
    light: BASE_COLORS.primary.light,
    dark: BASE_COLORS['bg'].dark
  },
  'nav-item-bg-hover': {
    light: BASE_COLORS['primary-1'].light,
    dark: BASE_COLORS['bg-1'].dark
  },
  'nav-item-bg-active': {
    light: BASE_COLORS['primary-2'].light,
    dark: BASE_COLORS['bg'].dark
  },
  'nav-focus': {
    light: BASE_COLORS['primary-3'].light,
    dark: BASE_COLORS['primary-3'].dark // ??
  },
  'nav-item-text': {
    light: TEXT_COLORS['text-on-primary-light'].light,
    dark: TEXT_COLORS['text-light'].dark
  },
  'nav-item-text-active': {
    light: TEXT_COLORS['text-on-primary'].light,
    dark: TEXT_COLORS['text'].dark
  },
  'nav-item-add-custom-group-button': {
    light: TEXT_COLORS['text-on-primary-disabled'].light,
    dark: TEXT_COLORS['text-disabled'].dark
  },
  'nav-item-add-custom-group-button-hover': {
    light: TEXT_COLORS['text-on-primary-light'].light,
    dark: TEXT_COLORS['text-on-primary-light'].light // actually targets in the light theme becuase the background is dark on primary
  }
} as const

const OVERLAY_COLORS = {
  'menu-bg': {
    light: BASE_LIGHT_STRING,
    dark: BASE_COLORS['bg-2'].dark
  },
  'modal-bg': {
    light: BASE_LIGHT_STRING,
    dark: BASE_COLORS['bg-2'].dark
  },
  'tooltip-bg': {
    light: BASE_DARK_STRING,
    dark: BASE_COLORS['bg-3'].dark
  },
  'tooltip-text': {
    light: BASE_COLORS['bg'].light,
    dark: BASE_COLORS['bg'].light
  }
}

// Use for progress bars / donuts
const PROGRESS_COLORS = {
  'progress-bg': {
    light: PRIMITIVES.greyAlpha['100'],
    dark: PRIMITIVES.whiteAlpha['100']
  },
  'progress-selected-bg': {
    light: PRIMITIVES.greyAlpha['100'],
    dark: PRIMITIVES.whiteAlpha['100']
  },
  'progress-default': {
    light: PRIMITIVES.grey['200'],
    dark: PRIMITIVES.white['200']
  },
  'progress-startable': {
    light: PRIMITIVES.green.alt,
    dark: PRIMITIVES.green.alt
  },
  'progress-inprogress': {
    light: PRIMITIVES.green.base.light,
    dark: PRIMITIVES.green.base.dark
  },
  'progress-complete': {
    light: PRIMITIVES.brand['500'],
    dark: PRIMITIVES.brandDark['500']
  },
  'progress-late': {
    light: PRIMITIVES.orange.base.light,
    dark: PRIMITIVES.orange.base.dark
  },
  'progress-planning': {
    light: PRIMITIVES.grey['300'],
    dark: PRIMITIVES.white['300']
  },
  'progress-cancelled': {
    light: PRIMITIVES.grey['100'],
    dark: PRIMITIVES.white['100']
  }
}

const SURFACE_COLORS = {
  surface: {
    light: PRIMITIVES.white['900'],
    dark: PRIMITIVES.white['100']
  },
  'surface-background': {
    light: PRIMITIVES.grey['100'],
    dark: PRIMITIVES.grey['900']
  },
  'surface-hover': {
    light: PRIMITIVES.greyAlpha['100'],
    dark: PRIMITIVES.whiteAlpha['100']
  },
  'surface-selected': {
    light: PRIMITIVES.brand['100'],
    dark: PRIMITIVES.brandDark['100']
  }
}

const BADGE_COLORS = {
  'badge-primary-bg': 'primary',
  'badge-primary-fg': 'text-on-primary',
  'badge-alert-bg': 'error',
  'badge-alert-fg': 'text-on-primary',
  'badge-dark-bg': {
    light: BASE_DARK_STRING,
    dark: BASE_LIGHT_STRING
  },
  'badge-dark-fg': 'text-on-primary',
  'badge-light-bg': 'bg-4',
  'badge-light-fg': 'text-light'
}

const TOGGLE_COLORS = {
  'toggle-bg': 'bg-3',
  'toggle-knob': 'bg',
  'toggle-accent': 'bg-3',
  'toggle-checked': 'success'
}

const BURN_CHART_COLORS = {
  'burn-chart-shaded-area': 'rgba(255, 153, 0, 1)'
}

const BAR_CHART_COLORS = {
  'bar-chart-primary': 'primary',
  'bar-chart-primary-light': 'rgb(135, 162, 229)',
  'bar-chart-grey': 'bg-4'
}

export const THEME_COLORS = {
  ...TEXT_COLORS,
  ...BASE_COLORS,
  ...NAV_COLORS,
  ...RAG_INDICATOR_COLOR,
  ...BADGE_COLORS,
  ...TOGGLE_COLORS,
  ...OVERLAY_COLORS,
  ...STAR_COLOR,
  ...BURN_CHART_COLORS,
  ...BAR_CHART_COLORS,
  ...PROGRESS_COLORS,
  ...SURFACE_COLORS
}

// Our colors mapped onto the required grommet configuration. This is not very compatible, but done to override
// the required color keys with at least recognizable colors. These may be used in component theme configuration
// defaults. (e.g., tab component theme configuration uses color 'control')
export const GROMMET_COLORS: GrommetColors = {
  active: 'bg-3', // ?
  background: 'bg',
  'background-back': 'bg-1',
  'background-front': 'bg',
  'background-contrast': 'bg',
  'active-background': 'background-contrast',
  black: '#000000',
  white: '#FFFFFF',
  border: 'bg-4',
  brand: 'primary',
  control: 'primary',
  focus: 'primary',
  placeholder: 'text-disabled',
  selected: 'primary',
  'text-strong': 'text',
  'text-weak': 'text-light',
  'text-xweak': 'text-disabled',
  'accent-1': 'feature-orange',
  'accent-2': 'feature-turquois',
  'accent-3': 'feature-violet',
  'accent-4': 'feature-pink',
  'dark-1': 'text', // ?
  'dark-2': 'text-light', // FIXME: bad logical color mapping
  'dark-3': 'text-disabled', // FIXME: bad logical color mapping
  'dark-4': 'text-disabled', // FIXME: bad logical color mapping
  'dark-5': 'text-disabled', // FIXME: bad logical color mapping
  'dark-6': 'text-disabled', // FIXME: bad logical color mapping
  'light-1': 'bg-1',
  'light-2': 'bg-2',
  'light-3': 'bg-3',
  'light-4': 'bg-4',
  'light-5': 'bg-4',
  'light-6': 'bg-4',
  'neutral-1': 'primary',
  'neutral-2': 'primary-1',
  'neutral-3': 'primary-2',
  'neutral-4': 'primary-3',
  'status-critical': 'error',
  'status-error': 'error',
  'status-warning': 'warning',
  'status-ok': 'success',
  'status-unknown': 'bg-4', // ?
  'status-disabled': 'bg-4' // ?
}

export type ThemeColors = typeof THEME_COLORS
export type NamedColor = keyof ThemeColors
export type CustomColor = { custom: string }
export type ColorPropType = LiteralUnion<NamedColor, undefined> | CustomColor
export type ColorProp = ColorPropType | { light?: ColorPropType; dark?: ColorPropType }
