import { Ref } from 'react'

import { MenuListItemProps } from '@cutover/react-ui'
import { runbookAtom } from 'main/recoil/shared/recoil-state-runbook-decorators'
import { LinkTemplateData } from 'main/components/runbook/modals/runbook-modals'
import { IntegrationActionItem, TaskListTask } from 'main/services/queries/types'

export type TaskCSVImportModalType = {
  id: never
  type: 'tasks-csv-import'
  data: { status?: 'submitting' | 'success' | 'error' | 'idle'; context?: string[] }
}

export type Notifications = 'on' | 'off'

type TasksCreateAsSnippetData = {
  runbookId: number
  taskInternalIds: number[]
}

type OptionalData<T> = T extends { data: never } ? Omit<T, 'data'> : T
type OptionalId<T> = T extends { id: never } ? Omit<T, 'id'> : T

export type ModalActiveTaskProgressionType =
  | { id: number; type: 'task-action'; data: never }
  | { id: number; type: 'task-override'; data: never }
  | { id: number; type: 'task-override-optional'; data: never }
  | { id: number; type: 'task-override-fixed-start'; data: never }
  | { id: number; type: 'task-finish-confirm'; data: never }
  | { id: never; type: 'task-unable-to-perform-action'; data: never }
  | { id: never; type: 'task-start-block'; data: never }
  | { id: number; type: 'task-abandon-confirm'; data: TaskAbandonConfirmData }
  | { id: number; type: 'task-oauth'; data: TaskOauthData }

export type ModalActiveTypeBase =
  | { type: 'audit-log-download'; id: never; data: never }
  | { type: 'cancel-approval'; id: never; data: never }
  | { type: 'integration-abort'; taskId: number; name: string; data: never }
  | { type: 'linked-runbook-add'; id: number; data: never }
  | { type: 'linked-template-selector'; id: never; data: LinkTemplateData }
  | { type: 'mark-as-approved'; id: never; data: never }
  | { type: 'request-approval'; id: never; data: never }
  | { type: 'request-reapproval'; id: never; data: never }
  | { type: 'run-pause'; id: never; data: never }
  | { type: 'run-resume'; id: never; data: never }
  | { type: 'run-start'; id: never; data: never }
  | { type: 'run-update'; id: never; data: never }
  | { type: 'runbook-ad-hoc-comms'; id: never; data: never }
  | { type: 'runbook-archive'; id: never; data: never }
  | { type: 'runbook-dashboard-share'; id: never; data: never }
  | { type: 'runbook-duplicate'; id: never; data: never }
  | { type: 'runbook-make-template'; id: never; data: never }
  | { type: 'runbook-merge'; id: never; data: never }
  | { type: 'runbook-restore'; id: never; data: never }
  | { type: 'runbook-save-as-saved-view'; id: never; data: never }
  | { type: 'runbook-schedule'; id: never; data: never }
  | { type: 'runbook-selected-timezone'; id: never; data: never }
  | { type: 'runbook-users-export'; id: never; data: ExportUsersData }
  | { type: 'runbook-version-restore'; id: never; data: { runbookId: number; runbookVersionId: number } }
  | { type: 'runbook-view-history'; id: never; data: never }
  | { type: 'snippet-add'; id: number; data: never }
  | { type: 'submit-review'; id: never; data: never }
  | { type: 'task-duplicate'; taskId: number; taskName: string; data: never }
  | { type: 'tasks-create-as-snippet'; id: never; data: TasksCreateAsSnippetData }
  | { type: 'tasks-delete'; id: number[]; data: never }
  | { type: 'tasks-export'; id: never; data: never }
  | { type: 'tasks-paste'; taskId?: number; taskName?: string; data: never }
  | { type: 'tasks-skip'; id: number[]; data: never }
  | { type: 'template-draft-version-delete'; id: never; data: never }
  | { type: 'version-create'; id: never; data: never }
  | { type: 'view-review'; id: never; data: never }
  | TaskCSVImportModalType
  | ModalActiveTaskProgressionType

export type ModalActiveType = OptionalId<OptionalData<ModalActiveTypeBase>>

export type TaskListMenu = {
  open: boolean
  taskId?: number
  type?: 'options' | 'predecessors' | 'successors'
  triggerRef?: Ref<HTMLElement>
  keyPrefix?: string
  isOpen?: boolean
  items: MenuListItemProps[]
  minWidth?: number
  maxWidth?: number
  maxHeight?: number
}

export type SortTasks = {
  option: 'start_latest_planned' | 'name' | 'end_display'
  direction?: 'asc' | 'desc'
}

export type IntegrationRequestType = 'refire' | 'cancel' | 'resumePolling'
export type IntegrationRequest = {
  taskId: number
  type: IntegrationRequestType
}

export type TaskAbandonConfirmData = {
  field_values?: any
  tasks: TaskListTask[]
  selected_successor_ids: number[]
}

type TaskOauthData = {
  integrationActionItem: IntegrationActionItem
}

type ExportUsersData = {
  runbookId: number
  runbookVersionId: number
  runbookName: string
}

export type RunbookViewStateType<TModalHistory extends object = {}> = {
  loadingIds: Record<number, boolean>
  notifications: Notifications
  selectedIds: number[]
  selectedEdges: string[]
  selectedTimezone?: string | null
  activeTimezone?: string | null
  copyIds: number[]
  highlightMode: boolean
  modal: {
    active?: ModalActiveType
    history: (ModalActiveType & { context?: TModalHistory })[]
  }
  menu: TaskListMenu
  sort: SortTasks
  newCommentsCount: number
  integrationRequest: { [x: number]: IntegrationRequestType }
  taskCreate: {
    name: string | undefined
    predecessor: undefined | number
  }
  nodeMap: {
    graphVersionFlag: number
    dataVersionFlag: number
  }
}

export const runbookViewStateLoadingIds_INTERNAL = runbookAtom<RunbookViewStateType['loadingIds']>({
  key: 'runbook-view-state:loading-ids',
  default: {}
})

export const runbookViewStateNotifications_INTERNAL = runbookAtom<RunbookViewStateType['notifications']>({
  key: 'runbook-view-state:notifications',
  default: 'on'
})

export const runbookViewStateSelectedIds_INTERNAL = runbookAtom<RunbookViewStateType['selectedIds']>({
  key: 'runbook-view-state:selected-ids',
  default: []
})

export const runbookViewStateSelectedEdges_INTERNAL = runbookAtom<RunbookViewStateType['selectedEdges']>({
  key: 'runbook-view-state:selected-edges',
  default: []
})

export const runbookViewStateSelectedTimezone_INTERNAL = runbookAtom<RunbookViewStateType['selectedTimezone']>({
  key: 'runbook-view-state:selected-timezone',
  default: undefined
})

export const runbookViewStateActiveTimezone_INTERNAL = runbookAtom<RunbookViewStateType['activeTimezone']>({
  key: 'runbook-view-state:active-timezone',
  default: undefined
})

export const runbookViewStateCopyIds_INTERNAL = runbookAtom<RunbookViewStateType['copyIds']>({
  key: 'runbook-view-state:copy-ids',
  default: []
})

export const runbookViewStateHighlightMode_INTERNAL = runbookAtom<RunbookViewStateType['highlightMode']>({
  key: 'runbook-view-state:highlight-mode',
  default: false
})

export const runbookViewStateModal_INTERNAL = runbookAtom<RunbookViewStateType['modal']>({
  key: 'runbook-view-state:modal',
  default: {
    active: undefined,
    history: []
  }
})

export const runbookViewStateMenu_INTERNAL = runbookAtom<RunbookViewStateType['menu']>({
  key: 'runbook-view-state:menu',
  default: {
    open: false,
    taskId: undefined,
    type: undefined,
    items: [],
    triggerRef: undefined,
    keyPrefix: undefined,
    minWidth: undefined,
    maxWidth: undefined,
    maxHeight: undefined
  }
})

export const runbookViewStateSort_INTERNAL = runbookAtom<RunbookViewStateType['sort']>({
  key: 'runbook-view-state:sort',
  default: { option: 'end_display', direction: 'asc' }
})

export const runbookViewStateNewCommentsCount_INTERNAL = runbookAtom<RunbookViewStateType['newCommentsCount']>({
  key: 'runbook-view-state:new-comments-count',
  default: 0
})

export const runbookViewStateIntegrationRequest_INTERNAL = runbookAtom<RunbookViewStateType['integrationRequest']>({
  key: 'runbook-view-state:integration-request',
  default: {}
})

export const runbookViewStateTaskCreate_INTERNAL = runbookAtom<RunbookViewStateType['taskCreate']>({
  key: 'runbook-view-state:task-create',
  default: { name: undefined, predecessor: undefined }
})

export const runbookViewStateNodeMap_INTERNAL = runbookAtom<RunbookViewStateType['nodeMap']>({
  key: 'runbook-view-state:node-map',
  default: { graphVersionFlag: 0, dataVersionFlag: 0 }
})
