import { useAppsModalValue } from 'main/recoil/data-access'
import { AppsModal } from './apps-modal'
import { AppsConnector, HeaderAppConnector, MobileHeaderAppConnector } from 'main/connectors/apps-connector'

export const AppsLayoutConnector = () => {
  const modal = useAppsModalValue()

  return (
    <>
      <AppsModal modal={modal} />
      <AppsConnector />
      <HeaderAppConnector />
      <MobileHeaderAppConnector />
    </>
  )
}
