import { ReactNode, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Modal, Text } from '@cutover/react-ui'
import { useGetRunbook } from 'main/services/queries/use-runbook'
import { useGetRunbookVersion } from 'main/services/queries/use-runbook-versions'
import { useGetTasks } from 'main/services/queries/use-tasks'
import { GlobalStateSetupModel } from 'main/data-access'
import { useAccountDataState } from 'main/services/api/data-providers/account/account-data'
import { useSentryPerformanceProfiling } from 'main/components/support-and-analytics/sentry'
import { LoadingPage } from 'main/components/authentication/loading-page'
import { useLanguage } from 'main/services/hooks'
import { ApiError } from 'main/services/api/http-gateway-adapter'

// NOTE: cache time is set to 0 bc we always want most up to date info whenever we enter a runbook
const CACHE_TIME = 0

const LOGIN_PATH = '/login'

export const RunbookPageRequests = ({
  children
}: {
  children: (context: { isLoading: boolean; isError: boolean }) => ReactNode
}) => {
  const { profileRunbookSize } = useSentryPerformanceProfiling()
  const { runbookId, runbookVersionId } = useParams<{
    runbookId: string
    runbookVersionId: string
  }>()
  const navigate = useNavigate()
  const { t } = useLanguage('runbook', { keyPrefix: 'permissionModal' })
  const setRunbookRequestData = GlobalStateSetupModel.useSet('runbook')
  const setRunbookVersionRequestData = GlobalStateSetupModel.useSet('runbookVersion')
  const setTaskListRequestData = GlobalStateSetupModel.useSet('tasks')

  const [blockAccess, setBlockAccess] = useState(false)

  const { isFetching: accountDataIsLoading, isError: accountDataError } = useAccountDataState()
  const {
    isLoading: runbookDataLoading,
    isError: runbookDataError,
    error: apiError
  } = useGetRunbook(
    { runbookId },
    {
      onSuccess: response => {
        setRunbookRequestData(response)
      },
      cacheTime: CACHE_TIME
    }
  )

  useEffect(() => {
    if ((apiError as ApiError & { response?: { status: number } })?.response?.status === 403) {
      setBlockAccess(true)
    }
  }, [apiError])

  const {
    data: runbookVersionData,
    isLoading: runbookVersionDataLoading,
    isError: runbookVersionDataError
  } = useGetRunbookVersion(
    { runbookId, runbookVersionId },
    {
      onSuccess: setRunbookVersionRequestData,
      cacheTime: CACHE_TIME
    }
  )

  const { isLoading: taskListDataLoading, isError: taskListDataError } = useGetTasks(
    { runbookId, runbookVersionId },
    // NOTE: make task list request when runbook version request has finished bc we need the runbook version response
    // to populate initial lookups (eg, task id to runbook component field value lookup)
    {
      enabled:
        !!runbookVersionData &&
        !runbookVersionDataLoading &&
        !runbookVersionDataError &&
        !!runbookId &&
        !!runbookVersionId,
      onSuccess: response => {
        profileRunbookSize(response.tasks.length)
        setTaskListRequestData(response)
      },
      cacheTime: CACHE_TIME
    }
  )

  const isLoading = runbookDataLoading || runbookVersionDataLoading || taskListDataLoading || accountDataIsLoading
  const isError = runbookVersionDataError || taskListDataError || runbookDataError || accountDataError

  return (
    <>
      {!blockAccess ? (
        children({
          // NOTE: we don't return data here because it's set in recoil state. If you need access to it, please
          // use the relevant models (eg. TaskModel, ActiveRunbookModel)
          isLoading,
          isError
        })
      ) : (
        <>
          <Modal
            title={t('title')}
            open
            zIndex={1001} // Display above loading page z-index of 1000
            hasCancelButton={false}
            confirmText={t('confirmText')}
            confirmIcon="chevron-right"
            onClickConfirm={() => navigate(LOGIN_PATH)}
            dismissible={false}
          >
            <Text>{t('description')}</Text>
          </Modal>
          <LoadingPage />
        </>
      )}
    </>
  )
}
