import { pick, unescape } from 'lodash'
import { useInfiniteQuery, UseInfiniteQueryOptions, useQuery, useQueryClient } from 'react-query'

import { QueryKeys } from './query-keys'
import { RunbookListRunbook, RunbooksTasksTask } from './types'
import { RunbooksResponse, RunbooksResponseMeta, RunbooksResponseMetaFull, ServerParams } from './use-runbooks'
import { apiClient } from '../api'
import {
  EXCLUDE_KEYS_FROM_RUNBOOKS_SERVER_QUERY,
  serverQueryObjectToServerQueryString
} from 'main/components/shared/filter/filter-params'
import { RunbooksFilter } from 'main/components/shared/filter/filter-types'

export type AccountRunbooksResponseMeta = Pick<
  RunbooksResponseMeta,
  'filtered_results_count' | 'all_filtered_runbook_ids' | 'total_results_count' | 'missing_ids'
>

const DEFAULT_LIMIT = 100

export const RUNBOOK_LIST_DEFAULTS = {
  sort_by: 'updated_at',
  sort_dir: 'desc',
  limit: DEFAULT_LIMIT,
  template_type: 'off'
}

let cacheKey: [string | undefined, string, Partial<Record<RunbooksFilter, any> & ServerParams> & { meta?: undefined }]

// TODO: filter_reload logic
// Warning: if you are looking for the full meta please use the hook useAccountRunbooksMetaFull
export function useAccountRunbooks<RunbookType extends RunbookListRunbook = RunbookListRunbook>({
  accountSlug,
  params = {},
  options = { enabled: true },
  includeMeta = true
}: {
  accountSlug?: string
  params?: Partial<Record<RunbooksFilter, any> & ServerParams>
  options?: UseInfiniteQueryOptions<RunbooksResponse<RunbookType, AccountRunbooksResponseMeta>, Error>
  includeMeta?: boolean
}) {
  const queryClient = useQueryClient()
  const { limit = DEFAULT_LIMIT, ...restParams } = params
  const serverParams = serverQueryObjectToServerQueryString({
    queryObject: restParams,
    defaults: {
      ...RUNBOOK_LIST_DEFAULTS,
      limit,
      accountId: accountSlug
    },
    excludeKeys: EXCLUDE_KEYS_FROM_RUNBOOKS_SERVER_QUERY
  })

  // TODO: should reduce meta to just the fields we need after first page (and remove from RUNBOOK_LIST_DEFAULTS).
  // Currently can reduce meta by excluding it from params but not exclude it entirely which is what we want here.
  const getRunbooks = async (offset: number = 0) => {
    let data: RunbooksResponse<RunbookType, AccountRunbooksResponseMeta>

    if (serverParams.length > 500) {
      const response = await apiClient.post<
        Partial<Record<RunbooksFilter, any> & ServerParams>,
        RunbooksResponse<RunbookType, AccountRunbooksResponseMeta>
      >({
        url: 'runbooks/search',
        data: {
          ...RUNBOOK_LIST_DEFAULTS,
          ...restParams,
          accountId: accountSlug,
          offset: offset,
          meta: offset === 0 && includeMeta
        }
      })

      if (!response?.data) {
        throw new Error('No data returned from server')
      }

      data = response.data
    } else {
      const response = await apiClient.get<RunbooksResponse<RunbookType, AccountRunbooksResponseMeta>>({
        url: `runbooks${serverParams}&offset=${offset}${offset === 0 && includeMeta ? '&meta=true' : ''}`
      })
      data = response.data
    }

    if (offset === 0 && includeMeta) {
      // Here we add the full meta to the cache manually
      // The reason for this is that the full response would otherwise get cached for this request, which means that if the
      // user ever clicks back to a view that matches the initial request, that original full meta will be returned and any new/updated
      // folders will be removed from the screen. Please see hook to get full meta info below.
      queryClient.setQueryData([accountSlug, QueryKeys.RunbooksMeta], data.meta)
    }

    data.meta = pick(data.meta, [
      'filtered_results_count',
      'all_filtered_runbook_ids',
      'total_results_count',
      'missing_ids'
    ])

    // TODO: remove this when strings in backend are no longer escaped
    data.runbooks = data.runbooks.map(runbook => {
      return {
        ...runbook,
        meta: runbook.meta
          ? {
              ...runbook.meta,
              linked_status: {
                ...runbook.meta.linked_status,
                parent_name: unescape(runbook.meta.linked_status?.parent_name)
              }
            }
          : undefined,
        name: unescape(runbook.name),
        project_name: unescape(runbook.project_name)
      }
    })

    return data
  }

  // make sure cacheKey excludes the exclude keys otherwise it will re-query on every change, defeating the point of exclude keys
  const filteredRestParams = Object.fromEntries(
    Object.entries(restParams).filter(([key]) => !EXCLUDE_KEYS_FROM_RUNBOOKS_SERVER_QUERY.includes(key))
  )

  cacheKey = [
    accountSlug,
    QueryKeys.Runbooks,
    {
      ...RUNBOOK_LIST_DEFAULTS,
      limit,
      ...filteredRestParams,
      ...{ meta: undefined }
    }
  ]

  // Yes the accountId is a key in the queryParamsArray and not a segment in the URL, but this allows
  // us to easily invalidate specifically all of the runbooks queries for only a given account vs all account runbooks queries
  return useInfiniteQuery<RunbooksResponse<RunbookType, AccountRunbooksResponseMeta>, Error>(
    cacheKey,
    ({ pageParam = 0 }) => getRunbooks(pageParam),
    {
      getNextPageParam: (_lastGroup, allGroups) => {
        const totalResultCount = allGroups[0]?.meta?.filtered_results_count
        const numericLimit = typeof limit === 'number' ? limit : DEFAULT_LIMIT // we should not allow a limit of 'none' in this context; if it's necessary, please use the normal runbooks list request
        const lastOffset = allGroups.length * numericLimit
        return totalResultCount > numericLimit && totalResultCount > lastOffset ? lastOffset : undefined
      },
      ...options,
      enabled: options.enabled && !!accountSlug
    }
  )
}

export const getInfiniteRunbookCacheKey = () => cacheKey

// Returns the full meta from the cache
// The reason for splitting this into its own hook is so the cache can be updated more simply during CRUD operations
// previously you would have had to loop through all the /runbooks requests and find all those with meta and update them all
export function useAccountRunbooksMetaFull(accountSlug?: string) {
  const queryClient = useQueryClient()

  // useQuery has been used so we subscribe to updates. Simply reading from the cache within a component does not cause a rerender if it updates
  return useQuery([accountSlug, QueryKeys.RunbooksMeta], () => {
    return queryClient.getQueryData([accountSlug, QueryKeys.RunbooksMeta]) as RunbooksResponseMetaFull
  })
}

export type RunbooksTasksResponseType = {
  tasks: RunbooksTasksTask[]
  meta: {
    total_results: number
    total_runbooks_count: number
    streams_lookup: any
  }
}

export function useAccountRunbooksTasks(params: Partial<Record<RunbooksFilter, any> & ServerParams> = {}) {
  const { limit = DEFAULT_LIMIT, ...restParams } = params
  const accountSlug = params.accountId
  const serverParams = serverQueryObjectToServerQueryString({
    queryObject: restParams,
    defaults: {
      ...RUNBOOK_LIST_DEFAULTS,
      limit,
      accountId: accountSlug
    },
    excludeKeys: EXCLUDE_KEYS_FROM_RUNBOOKS_SERVER_QUERY
  })

  const getTasks = async (offset: number = 0) => {
    let data: RunbooksTasksResponseType
    const response = await apiClient.get<RunbooksTasksResponseType>({
      url: `runbooks/all_tasks${serverParams}&offset=${offset}`
    })
    data = response.data

    return data
  }

  const filteredRestParams = Object.fromEntries(
    Object.entries(restParams).filter(([key]) => !EXCLUDE_KEYS_FROM_RUNBOOKS_SERVER_QUERY.includes(key))
  )

  cacheKey = [
    accountSlug,
    'runbooks-all-tasks',
    {
      ...RUNBOOK_LIST_DEFAULTS,
      limit,
      ...filteredRestParams,
      ...{ meta: undefined }
    }
  ]

  return useInfiniteQuery<RunbooksTasksResponseType, Error>(cacheKey, ({ pageParam = 0 }) => getTasks(pageParam), {
    getNextPageParam: (_lastGroup, allGroups) => {
      const totalResultCount = allGroups[0]?.meta?.total_results
      const numericLimit = typeof limit === 'number' ? limit : DEFAULT_LIMIT // we should not allow a limit of 'none' in this context; if it's necessary, please use the normal runbooks list request
      const lastOffset = allGroups.length * numericLimit
      return totalResultCount > numericLimit && totalResultCount > lastOffset ? lastOffset : undefined
    },
    enabled: !!accountSlug
  })
}
