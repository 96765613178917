import { memo } from 'react'

import { Box } from '@cutover/react-ui'
import { SectionFavorites } from './section-favorites'
import { SectionLinks } from './section-links'
import { SectionWorkspaces } from './section-workspaces'
import { useAccountsQuery } from 'main/services/queries/use-accounts'

export const NavDefault = memo(({ compact, hidden }: { compact: boolean; hidden: boolean }) => {
  // const queryClient = useQueryClient()
  // const { leftSidebarSize, toggleLeftSidebar } = useSidebarNavContext()
  // const isReactWorkspace = ConfigModel.useIsFeatureEnabled('react_workspace')
  // const navigate = useSidebarNavigate()
  const { data: accountsData } = useAccountsQuery()

  // const slug = ActiveAccountModel.useSlug()
  // const { slug } = useRecoilValue_TRANSITION_SUPPORT_UNSTABLE(accountState)
  // const activeWorkspace = accountsData?.accounts.find(w => w.slug === slug)

  // const currentState = useWorkspaceView()
  // const previousState = usePreviousDistinct(currentState)
  // const activeSavedView = useActiveSavedView(activeWorkspace?.id)
  // const [searchParams] = useSearchParams()
  // const templateType = searchParams.get('template_type')
  // const previousTemplateType = usePrevious(templateType)

  // useEffect(() => {
  //   if (activeWorkspace) {
  //     eventManager.emit('trigger-angular-fetch-saved-views', {
  //       accountId: activeWorkspace.id,
  //       reset: true,
  //       activeSavedView
  //     })
  //   }

  //   if (activeWorkspace && leftSidebarSize === 'small') {
  //     toggleLeftSidebar(false)
  //   }
  // }, [activeWorkspace])

  // need to track the filter state to update angular's saved view logic and  keep the navigation logic in
  // TODO: add more notes and improve variable names
  // TODO: type things better
  // useEffect(() => {
  //   const navigateOffSavedView = () => {
  //     navigate(window.location.hash.substring(1))
  //   }
  //   eventManager.on('angular-clear-saved-view', navigateOffSavedView)
  //   return () => {
  //     eventManager.off('angular-clear-saved-view', navigateOffSavedView)
  //   }
  // }, [])

  // useEffect(() => {
  //   const refreshSavedViews = async ({
  //     accountId,
  //     accountSlug,
  //     savedView
  //   }: {
  //     accountSlug: string
  //     accountId: number
  //     savedView?: SavedView
  //   }) => {
  //     await queryClient.refetchQueries(['accounts', String(accountId), 'saved_view_groups'])
  //     if (savedView) {
  //       const queryObject = JSON.parse(savedView.query_string)
  //       if (queryObject._display === 'runbook') {
  //         const { runbookVersionId, runbookId } = queryObject
  //         navigate(`/app/${accountSlug}/runbooks/${runbookId}/${runbookVersionId}/tasks/list`, {
  //           state: {
  //             activeSavedView: savedView
  //           }
  //         })
  //       } else {
  //         const viewQueryString = serverQueryObjectToBrowserQueryString({
  //           queryObject,
  //           excludeKeys: [
  //             'accountId',
  //             'runbookId',
  //             'projectId',
  //             'runbookVersionId',
  //             'dashboardId',
  //             'disable_components',
  //             '_display'
  //           ]
  //         })

  //         navigate(`/app/${accountSlug}/runbooks/${queryObject._display}${encodeURI(viewQueryString)}`, {
  //           state: {
  //             activeSavedView: savedView
  //           }
  //         })
  //       }
  //     }
  //   }
  //   eventManager.on('trigger-react-refetch-saved-views', refreshSavedViews)

  //   return () => {
  //     eventManager.off('trigger-react-refetch-saved-views', refreshSavedViews)
  //   }
  // }, [])

  // useEffect(() => {
  //   if (!isReactWorkspace) {
  //     eventManager.emit('saved-view-nav', {
  //       view: activeSavedView,
  //       previousState
  //     })
  //   }
  // }, [activeSavedView?.id])

  // useEffect(() => {
  //   if (!currentState || previousTemplateType === templateType) return

  //   eventManager.emit('runbooks-template-type-changed', { templateType })
  // }, [templateType, previousTemplateType, currentState])

  return (
    <Box
      css={`
        display: ${hidden ? 'none' : 'block'};
      `}
      fill="vertical"
      role="navigation"
      aria-label="Sidebar navigation"
    >
      <SectionLinks compact={compact} />
      <SectionFavorites hidden={compact} />
      <SectionWorkspaces hidden={compact} workspaces={accountsData?.accounts ?? []} />
    </Box>
  )
})
