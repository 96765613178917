import { ReactNode, SyntheticEvent, useMemo, useState } from 'react'
import { GroupedVirtuoso } from 'react-virtuoso'
import { InfiniteData } from 'react-query'

import {
  Box,
  Button,
  Icon,
  IconButton,
  ListItem,
  LoadingPanel,
  Menu,
  MenuListItem,
  MenuListItemProps,
  RightPanel
} from '@cutover/react-ui'
import { useRightPanelTypeState } from 'main/components/layout/right-panel'
import { useLanguage } from 'main/services/hooks'
import { RunbooksTasksResponseType, useAccountRunbooksTasks } from 'main/services/queries/use-runbooks-infinite'
import { useAccount, useAccountTaskTypes } from 'main/services/api/data-providers/account/account-data'
import { TaskLineItem } from 'main/components/shared/task-line-item'
import { ActiveDashboardItemType } from 'main/components/dashboards/dashboard-widget-collection'
import { useRouting } from 'main/services/routing'

export const DashboardTaskListPanel = () => {
  const { t } = useLanguage('common', { keyPrefix: 'task' })
  const { t: tAlt } = useLanguage('dashboard', { keyPrefix: 'taskList' })
  const [{ params, contextParams, activeItem }, { closeRightPanel, updateRightPanel }] =
    useRightPanelTypeState('dashboard-task-list')
  const { data, hasNextPage, fetchNextPage, isLoading, isFetching, isFetchingNextPage, refetch } =
    useAccountRunbooksTasks({ ...params, ...contextParams })

  if (!params || !activeItem || !data || !contextParams) return <LoadingPanel />

  const showStageFilters = activeItem?.subFilterKey === 'stage'
  const handleClickStage = (value?: string) => {
    const updatedActiveItem = { ...activeItem, subFilterKey: 'stage', subFilterValue: value }
    const updatedParams = { ...params, 'task[stage]': value }
    updateRightPanel({ params: updatedParams, contextParams, activeItem: updatedActiveItem })
  }

  const stageFilters = [
    { label: t('all'), value: undefined },
    { label: t('default'), value: 'default' },
    { label: t('startable'), value: 'startable' },
    { label: t('inProgress'), value: 'in-progress' },
    { label: t('complete'), value: 'complete' }
  ]

  const selectedItem = stageFilters.find(option => option.value === activeItem?.subFilterValue)

  return (
    <DashboardTaskList
      data={data}
      hasNextPage={hasNextPage}
      isFetching={isFetching || isLoading}
      refetch={refetch}
      isFetchingNextPage={isFetchingNextPage}
      fetchNextPage={fetchNextPage}
      onClose={closeRightPanel}
      activeItem={activeItem}
    >
      {showStageFilters && (
        <Box css="margin-bottom: 8px; flex-shrink: 0; align-self: start;" background="surface-background" round="20px">
          <Menu
            trigger={
              <Button
                tertiary
                reverse
                size="medium"
                icon="caret-down"
                label={tAlt('stageFilter', { activeStage: selectedItem?.label || t('all') })}
                active={!!selectedItem}
              />
            }
          >
            {stageFilters.map(option => {
              return (
                <MenuListItem
                  label={option.label}
                  key={option.label}
                  onClick={() => handleClickStage(option.value)}
                  selected={selectedItem?.value === option.value}
                />
              )
            })}
          </Menu>
        </Box>
      )}
    </DashboardTaskList>
  )
}

export type DashboardTaskListProps = {
  data?: InfiniteData<RunbooksTasksResponseType>
  onClose: () => void
  isLoading?: boolean
  isFetchingNextPage?: boolean
  isFetching?: boolean
  hasNextPage?: boolean
  fetchNextPage?: () => Promise<any>
  refetch: () => Promise<any>
  activeItem?: ActiveDashboardItemType
  children?: ReactNode
}

// Note: this grouped infinite scroll will be incorporated into the InfiniteList comp later
export const DashboardTaskList = ({
  onClose,
  data,
  hasNextPage,
  isFetching,
  refetch,
  isFetchingNextPage,
  fetchNextPage,
  children
}: DashboardTaskListProps) => {
  const { t } = useLanguage('dashboard', { keyPrefix: 'taskList' })
  const { taskTypeLookup } = useAccountTaskTypes()
  const [expandedGroups, setExpandedGroups] = useState<Record<string, boolean>>({})
  const { toRunbook } = useRouting()
  const { account } = useAccount()

  const tasks = data?.pages.flatMap(page => page.tasks) || []

  const taskGroups = useMemo(() => {
    const groups: Record<string, { name: string; tasks: any[] }> = {}

    for (const task of tasks) {
      if (!groups[task.runbook_id]) {
        groups[task.runbook_id] = { name: task.runbook_name, tasks: [] }
      }
      groups[task.runbook_id].tasks.push(task)
    }

    return groups
  }, [tasks])

  const groupIds = useMemo(() => Object.keys(taskGroups), [taskGroups])

  // Recalculate counts for expanded groups
  // Note: setting collapsed groups to 0, rather than actual counts then hiding on render if collapsed
  // Since presumably the internals rely on groupCounts being correct to determine height/reloading etc
  const groupCounts = useMemo(
    () => groupIds.map(id => (expandedGroups[id] === false ? 0 : taskGroups[id].tasks.length)),
    [groupIds, taskGroups, expandedGroups]
  )

  const visibleTasks = useMemo(() => {
    return groupIds.flatMap(id => (expandedGroups[id] === false ? [] : taskGroups[id].tasks))
  }, [groupIds, expandedGroups, taskGroups])

  const totalTasksCount = data?.pages[0]?.meta?.total_results
  const totalRunbooksCount = data?.pages[0]?.meta?.total_runbooks_count
  const streamsLookup = data?.pages[0]?.meta?.streams_lookup

  const expandAll = () => {
    setExpandedGroups(prev => {
      const updatedGroups = { ...prev }
      groupIds.forEach(id => {
        updatedGroups[id] = true
      })
      return updatedGroups
    })
  }

  const collapseAll = () => {
    setExpandedGroups(prev => {
      const updatedGroups = { ...prev }
      groupIds.forEach(id => {
        updatedGroups[id] = false
      })
      return updatedGroups
    })
  }

  const menuItems: MenuListItemProps[] = [
    {
      icon: 'expand' as const,
      label: t('expandAll'),
      onClick: expandAll
    },
    {
      icon: 'collapse' as const,
      label: t('collapseAll'),
      onClick: collapseAll
    },
    { icon: 'refresh' as const, label: t('reload'), onClick: () => refetch() }
  ]

  const handleClickOpenRunbook = (groupId: number, event: SyntheticEvent) => {
    if (!account) return
    event.stopPropagation()
    const url = toRunbook({
      accountSlug: account.slug,
      runbookId: groupId
    })
    window.open('#' + url, '_blank')
  }

  return (
    <RightPanel
      title={data ? t('title', { tasksCount: totalTasksCount, runbooksCount: totalRunbooksCount }) : undefined}
      onClose={onClose}
      loading={isFetching && !isFetchingNextPage}
      headerItems={[
        <Menu trigger={<IconButton tertiary label={t('moreOptions')} icon="more-vertical" />}>
          {menuItems.map(item => (
            <MenuListItem icon={item.icon} label={item.label} key={item.label} onClick={item.onClick} />
          ))}
        </Menu>
      ]}
    >
      {children}
      {tasks && tasks.length > 0 && (
        <GroupedVirtuoso
          defaultItemHeight={32}
          fixedItemHeight={32}
          endReached={() => {
            if (hasNextPage && !isFetchingNextPage && fetchNextPage) {
              fetchNextPage()
            }
          }}
          groupCounts={groupCounts}
          groupContent={groupIndex => {
            const groupId = groupIds[groupIndex]
            const groupData = taskGroups[groupId]
            const isCollapsed = expandedGroups[groupId] === false
            return (
              <Box background="bg">
                <ListItem
                  tabIndex={undefined}
                  size="small"
                  startComponent={
                    <Icon
                      size="large"
                      icon={isCollapsed ? 'caret-right' : 'caret-down'}
                      color={isCollapsed ? 'text-light' : 'text'}
                    />
                  }
                  endComponents={[
                    <IconButton
                      label={t('openRunbook')}
                      icon="open-new"
                      size="small"
                      onClick={(e: SyntheticEvent) => handleClickOpenRunbook(Number(groupId), e)}
                    />
                  ]}
                  onClick={() =>
                    setExpandedGroups(prev => ({
                      ...prev,
                      [groupId]: prev[groupId] === undefined ? false : !prev[groupId]
                    }))
                  }
                  a11yTitle={groupData.name}
                  prominence={isCollapsed ? 'default' : 'high'}
                  title={groupData.name}
                  titleSuffix={`(${groupData.tasks.length})`}
                  role="listitem"
                />
              </Box>
            )
          }}
          increaseViewportBy={256}
          itemContent={index => {
            const task = visibleTasks[index]
            const taskType = taskTypeLookup?.[task.task_type_id]
            return taskType ? (
              <TaskLineItem task={task} stream={streamsLookup[task.stream_id]} taskType={taskType} showDuration />
            ) : null
          }}
          style={{ height: '100%' }}
        />
      )}
    </RightPanel>
  )
}
