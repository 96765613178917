import { UserListItem } from '@cutover/react-ui'
import { MrdDashboardWidget } from '../../../widgets/account/mrd-dashboard-widget'
import { DashboardComponent, DashboardMediaType } from '../../types'

export type RunbookAdminWidgetProps = {
  media: DashboardMediaType
  data: DashboardComponent
}

export const RunbookAdminWidget = ({ data }: RunbookAdminWidgetProps) => {
  const { width, name } = data
  const fullWidth = width === 100 || !width

  // i18n cannot be used for the title, as this widget is reused in email contexts where useLanguage is unsupported and leads to errors.
  return (
    <MrdDashboardWidget title={name && name.trim().length > 0 ? name : 'Runbook Admins'} fullWidth={fullWidth}>
      <RunbookAdminWidgetContent componentData={data}></RunbookAdminWidgetContent>
    </MrdDashboardWidget>
  )
}

export const RunbookAdminWidgetContent = ({ componentData }: { componentData: DashboardComponent }) => {
  const admins = componentData?.users.filter(
    user => user.is_admin === true && user.archived === false && user.status === 'active'
  )
  return (
    <>
      {admins?.map(user => (
        <UserListItem
          key={`user${user.id}`}
          size="small"
          id={user.id}
          name={user.name}
          color={user.color || ''}
          handle={user.handle}
          draggable={false}
        />
      ))}
    </>
  )
}
