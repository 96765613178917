import { ReactNode } from 'react'
import styled from 'styled-components'

import { Box, breakpoints, Text } from '@cutover/react-ui'

export type MrdDashboardWidgetProps = {
  title: string
  height?: 300
  fullWidth?: boolean
  notes?: string | null | undefined
  errors?: string[] | null
  children: ReactNode
  pageBreakType?: 'full-width' | 'never'
}

export const MrdDashboardWidget = ({
  title,
  fullWidth,
  children,
  errors,
  notes,
  pageBreakType = 'full-width'
}: MrdDashboardWidgetProps) => {
  return (
    <Widget fullWidth={fullWidth} pageBreakType={pageBreakType} data-testid={`component-${title}`}>
      <Box margin={{ bottom: '8px' }}>
        <Text
          as="h3"
          size="18px"
          weight={600}
          color="text"
          data-testid="dashboard-widget"
          margin={{ top: '0px', bottom: '8px' }}
          truncate="tip"
        >
          {title}
        </Text>
        {notes && (
          <Text color="text-light" size="13px" truncate="tip">
            {notes}
          </Text>
        )}
        {errors && (
          <Text color="error" size="13px" truncate="tip">
            {errors}
          </Text>
        )}
      </Box>
      {children}
    </Widget>
  )
}

const Widget = styled(Box)<{ fullWidth?: boolean; pageBreakType: 'full-width' | 'never' }>`
  display: block;
  width: 100%;
  padding: 8px;
  /* using padding rather than margin to give donut-chart legends some breathing room */
  padding-bottom: 24px;
  overflow-y: auto;

  // TODO: screen breakpoints are not optimized so fixing width for now.
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '50%')};

  @media print, ${breakpoints.gtLg} {
    float: left;
    width: ${({ fullWidth }) => (fullWidth ? '100%' : '50%')};
    break-inside: ${({ fullWidth, pageBreakType }) => (fullWidth && pageBreakType === 'full-width' ? 'auto' : 'avoid')};
  }
`
