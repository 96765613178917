import { memo, useCallback, useEffect, useState } from 'react'
import { useMatch } from 'react-router-dom'

import { Modal, Text } from '@cutover/react-ui'
import { useSidebarNavigate } from '../hooks'
import { NavItem, NavItemInfoText, SidebarSection } from '../nav-item'
import { useLanguage, useUserWebsocket } from 'main/services/hooks'
import { Favorite } from 'main/services/queries/types'
import { MarkedIncidentSensitiveResponse } from 'main/services/api/data-providers/user/user-channel-response-types'
import { useFavorites, useToggleFavorite } from 'main/services/queries/use-favorites'
import { useRouting } from 'main/services/routing'

export const SectionFavorites = memo(({ hidden = false }: { hidden?: boolean } = {}) => {
  const { t } = useLanguage('sidebarNav', { keyPrefix: 'defaultNav' })
  const { data: favorites, isLoading } = useFavorites()
  const { mutate: removeFavorite } = useToggleFavorite('off')
  const { listen } = useUserWebsocket()

  const handleWebsocketResponse = (data: MarkedIncidentSensitiveResponse) => {
    if (data?.meta?.headers.request_method === 'runbook_marked_restricted') {
      const sensitiveRunbookIndex = favorites?.findIndex(fav => fav.id === data.runbook_id)
      if (sensitiveRunbookIndex !== -1) {
        removeFavorite(data.runbook_id)
      }
    }
  }

  useEffect(() => {
    listen(data => {
      handleWebsocketResponse(data as MarkedIncidentSensitiveResponse)
    })
  }, [favorites])

  return (
    <SidebarSection heading={t('title', { context: 'favorites' })} hidden={hidden}>
      {!isLoading && !favorites?.length ? (
        <NavItemInfoText level={0}>{t('starredInfo')}</NavItemInfoText>
      ) : (
        favorites?.map(favorite => {
          return <NavItemFavorite key={favorite.id} runbook={favorite} />
        })
      )}
    </SidebarSection>
  )
})

// Right now we only star runbooks. Will need to make this generic and show/link to items of different types
// in the future.
const NavItemFavorite = memo(({ runbook }: { runbook: Favorite }) => {
  const { toRunbook } = useRouting()
  const { t } = useLanguage('sidebarNav', { keyPrefix: 'favorites.archivedModal' })
  const [isArchivedModalOpen, setIsArchivedModalOpen] = useState(false)
  // have favorite links link to the react-runbook task list when feauture flag is enabled
  // NOTE: will need to link to the runbook's defualt view
  const link = toRunbook({ accountSlug: runbook.account_slug, runbookId: runbook.id })
  const navigate = useSidebarNavigate()
  const isActive = useMatch({ path: `/app/${runbook.account_slug}/runbooks/${runbook.id}/current_version`, end: false })
  const { mutate: removeFavorite } = useToggleFavorite('off')

  const handleClickFavorite = useCallback(() => {
    if (runbook.archived) {
      setIsArchivedModalOpen(true)
    } else if (!isActive) {
      navigate(link)
    }
  }, [isActive, link, navigate, runbook.archived])

  const handleClickRemove = useCallback(() => {
    removeFavorite(runbook.id)
  }, [removeFavorite, runbook.id])

  return (
    <>
      <NavItem
        icon={runbook.is_template ? 'template' : runbook.template_type === 'snippet' ? 'snippet' : 'runbook'}
        label={runbook.name}
        isActive={!!isActive}
        onClick={handleClickFavorite}
        onRemove={handleClickRemove}
      />
      {isArchivedModalOpen && (
        <Modal
          open
          onClose={() => setIsArchivedModalOpen(false)}
          onClickConfirm={() => setIsArchivedModalOpen(false)}
          confirmText={t('confirm')}
          confirmIcon="chevron-right"
          title={t('title', { type: runbook.template_type === 'snippet' ? 'snippet' : 'runbook' })}
          hasCancelButton={false}
        >
          <Text>{t('message', { type: runbook.template_type === 'snippet' ? 'snippet' : 'runbook' })}</Text>
        </Modal>
      )}
    </>
  )
})
