import { atomFamily, selectorFamily } from 'recoil'

import { DataSourceValue, DataSourceValuesResponse } from 'main/services/queries/types'
import { apiClient_UNSTABLE } from 'main/services/api'
import { applyFilterPipeline } from 'main/services/data-source-values/filtering/filter-map'
import { appliedFilterState } from '../../filters'
import { buildDataSourceValueFilterContext } from 'main/services/data-source-values/filtering/filter-context'

export const dataSourceValues_INTERNAL = atomFamily<
  DataSourceValuesResponse,
  { customFieldId: number; accountId: number }
>({
  key: 'data-source-values',
  default: async ({ customFieldId, accountId }) => {
    const { data } = await apiClient_UNSTABLE.get<DataSourceValuesResponse>(
      `data_source_values?cf_id=${customFieldId}&account_id=${accountId}`
    )

    return data
  }
})

export const dataSourceValuesFilter = selectorFamily<DataSourceValue[], { customFieldId: number; accountId: number }>({
  key: 'data-source-values:filter-data',
  get:
    ({ customFieldId, accountId }) =>
    ({ get }) => {
      const filters = get(appliedFilterState)
      const { data_source_values, data_source_values_map } = get(
        dataSourceValues_INTERNAL({ customFieldId, accountId })
      )

      const filteredValues = applyFilterPipeline(
        data_source_values,
        filters,
        buildDataSourceValueFilterContext(filters, { data_source_values_map, data_source_values })
      )
      return filteredValues.map(value => ({
        ...value,
        templates: (data_source_values_map.templates as Record<number, number>)[value.id] || 0
      }))
    }
})

export const dataSourceValuesCount = selectorFamily<
  { valuesCount: number; filteredCount: number },
  { customFieldId: number; accountId: number }
>({
  key: 'data-source-values:count',
  get:
    ({ customFieldId, accountId }) =>
    ({ get }) => {
      const { data_source_values } = get(dataSourceValues_INTERNAL({ customFieldId, accountId }))
      const filtered = get(dataSourceValuesFilter({ customFieldId, accountId }))

      return {
        valuesCount: data_source_values.length,
        filteredCount: filtered.length
      }
    }
})
