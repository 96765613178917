import { memo, Suspense } from 'react'
import { useParams } from 'react-router-dom'

import { FilterAccordion, FilterAccordionPanel } from '@cutover/react-ui'
import { FilterPanel } from 'main/components/layout/filter-panel'
import { IDS as LAYOUT_IDS } from 'main/components/layout/layout-constants'
import { GroupsHeader } from 'main/components/shared/filter/filter-groups/groups-header'
import { CustomFieldTextSearchControl } from 'main/recoil/shared/filters/controls'
import { useAccountCustomFields } from 'main/services/api/data-providers/account/account-data'
import { CustomField } from 'main/services/queries/types'
import { useCustomFieldAppliedFiltersState } from 'main/recoil/data-access'

export const DataSourceFilters = () => {
  const { customFieldId } = useParams<{ customFieldId?: string }>()
  const { customFieldsLookup } = useAccountCustomFields()

  return (
    <>
      {customFieldId && customFieldsLookup && (
        <Suspense fallback={null}>
          <DataSourceFiltersInner customFieldId={parseInt(customFieldId)} customFieldsLookup={customFieldsLookup} />
        </Suspense>
      )}
    </>
  )
}

const DataSourceFiltersInner = ({
  customFieldId,
  customFieldsLookup
}: {
  customFieldId: number
  customFieldsLookup: Record<number, CustomField>
}) => {
  const customFieldFilters = useCustomFieldAppliedFiltersState()

  const filterCustomFields = () => {
    const customField = customFieldsLookup[customFieldId]
    return [
      customField,
      ...Object.values(customFieldsLookup).filter(cf => (cf as CustomField).source_custom_field_id === customField.id)
    ]
  }

  return (
    <FilterPanel>
      <FilterAccordion
        a11yTitle="Filter panel"
        renderMode="active"
        isLayoutFilter
        scrollContainer={LAYOUT_IDS.FILTER_PANEL}
      >
        <GroupsHeader key="header" />
        {filterCustomFields().map(customField => {
          return (
            <CustomFieldGroup
              customField={customField}
              applied={customFieldFilters && customField.id in customFieldFilters}
              key={customField.id}
            />
          )
        })}
      </FilterAccordion>
    </FilterPanel>
  )
}

// TODO: substitute CustomFieldTextSearchControl for custom control
const CustomFieldGroup = memo(({ customField, applied }: { customField: CustomField; applied?: boolean }) => {
  return (
    <FilterAccordionPanel applied={applied} label={customField.display_name ?? customField.name} key={customField.id}>
      {customField.field_type.slug === 'text' ? <CustomFieldTextSearchControl id={customField.id} /> : null}
    </FilterAccordionPanel>
  )
})
