import { SyntheticEvent, useState } from 'react'
import styled from 'styled-components/macro'

import { ColorProp, themeColor } from '../../theme'
import { Box } from '../../layout'
import { Text } from '../../typography'
import { RagIndicator } from '../../rag-indicator'
import { Icon } from '../../icon'
import { Tooltip } from '../../overlays'

type BarTableProps = {
  data: BarTableData
  media: 'screen' | 'email' | 'print'
  onRowClick?: (id: string | number) => void
  onSegmentClick?: (id: string | number, displayStage?: string) => void // currently only used in Runbook Completion Widget
  mainLabelAsComponent?: boolean
  nameAsId?: boolean
  selectedRowId?: string | number | null
}

export type BarTableData = {
  rowData: RowData[]
}

export type RowData = {
  id: number
  name?: string
  selected?: boolean
  mainLabel: string | JSX.Element
  rowInfo: string | null
  additionalRowInfo?: { [key: string]: string } | null
  values: Values[]
  optionalComponent?: JSX.Element // optional component to the side (i.e. rag status component)
  status?: RagStatus
}

export type RagStatus = 'amber' | 'green' | 'refreshing' | 'red' | 'complete' | 'unknown' | 'off'

export type Values = {
  main?: boolean // if main bar, adds percentages overlay and puts a checkmark once 100%
  width: number
  color: string
  displayStage?: string
  tooltipText?: string
}

export const BarTable = ({ data, onRowClick, onSegmentClick, media, nameAsId, selectedRowId }: BarTableProps) => {
  const { rowData } = data
  const [barsOnDisplay, setBarsOnDisplay] = useState(10) // initial number of rows to display.

  const handleShowMoreClick = () =>
    setBarsOnDisplay(
      rowData.length === barsOnDisplay
        ? rowData.length
        : rowData.length > barsOnDisplay
        ? barsOnDisplay + 10 < rowData.length
          ? barsOnDisplay + 10
          : rowData.length
        : rowData.length
    )

  const isScreen = media === 'screen'

  const rowItem = (
    rowData: RowData,
    rowItemIndex: number,
    onSegmentClick?: (id: string | number, displayStage?: string) => void,
    onRowClick?: (id: string | number) => void
  ) => {
    const { mainLabel, rowInfo, additionalRowInfo, values, optionalComponent, name } = rowData

    // 0% segments are causing render issues with the border radius on the right end of bar
    const filteredValues = values.filter(value => value.width > 0)
    const rowId = nameAsId && rowData.name ? rowData.name : rowData.id
    const selected = selectedRowId === rowId

    return (
      <BarWrap
        key={`r-${rowItemIndex}`}
        direction="row"
        background={selected ? 'surface-selected' : undefined}
        className={selected ? 'selected' : undefined}
        onClick={() => onRowClick?.(rowId)}
      >
        <Box width="50%" data-testid="bar-table-row-label" gap="16px" direction="row" justify="between">
          <Box direction="row" gap="4px" align="center">
            {/* To get around TS error of 'refreshing' | 'complete' | 'unknown' being added to normal rag... */}
            {(rowData.status === 'red' || rowData.status === 'amber' || rowData.status === 'green') && (
              <RagIndicator status={rowData.status} />
            )}
            <Text truncate="tip" tip={name}>
              {mainLabel}
            </Text>
          </Box>
          {isScreen && rowInfo && (
            <Box direction="row" css="white-space: nowrap; flex-shrink: 0; max-width: 50%; overflow: hidden;">
              <Text color="text-light" truncate>
                {rowInfo}
                {additionalRowInfo && (
                  <Text color={additionalRowInfo?.color} css="padding-left: 4px;">
                    {additionalRowInfo.value}
                  </Text>
                )}
              </Text>
            </Box>
          )}
        </Box>
        <Box width="50%" direction="row">
          <ProgressBar id={rowId} values={filteredValues} onClickSegment={onSegmentClick} />
          {optionalComponent && optionalComponent}
        </Box>
      </BarWrap>
    )
  }

  return (
    <Box data-testid="bar-table" css="margin-top: 16px;">
      {rowData &&
        rowData.length > 0 &&
        rowData.slice(0, barsOnDisplay).map((row, index) => rowItem(row, index, onSegmentClick, onRowClick))}
      {rowData.length > barsOnDisplay && isScreen && <ShowMore onClick={handleShowMoreClick}>Show more...</ShowMore>}
    </Box>
  )
}

type ProgressBarProps = {
  id: string | number
  values: Values[]
  onClickSegment?: (id: string | number, displayStage?: string) => void
}

// TODO: make into its own react-ui comp + story
const ProgressBar = ({ id, values, onClickSegment }: ProgressBarProps) => {
  const contents = (value: Values) => {
    return value.width === 100 ? (
      <Icon icon="check-solid" size="small" css="vertical-align: bottom;" />
    ) : (
      <Text size="small" css="line-height: 16px; vertical-align: bottom;">{`${Math.round(value.width)}%`}</Text>
    )
  }

  return (
    <Box
      background="progress-bg"
      round="8px"
      height="16px"
      fill
      direction="row"
      align="center"
      css={`
        overflow: hidden;
        box-shadow: 0px 0px 0px 1px ${themeColor('surface')};
      `}
    >
      {values.map((value, index) => {
        return (
          <Box
            onClick={(e: SyntheticEvent) => {
              e.stopPropagation()
              onClickSegment?.(id, value.displayStage)
            }}
            key={`f-${index}`}
            background={value.color as ColorProp}
            width={`${value.width}%`}
            justify="end"
            height="16px"
            css={`
              box-shadow: 0 0 0 1px ${themeColor('surface')};
              text-align: right;
              padding-right: 3px;
            `}
          >
            <Tooltip content={value.tooltipText} placement="top" disableOnClickAsTriggerProp>
              {value.displayStage === 'complete' ? contents(value) : <Box css="height: 16px; width: 100%;" />}
            </Tooltip>
          </Box>
        )
      })}
    </Box>
  )
}

const BarWrap = styled(Box).attrs({
  round: '8px',
  align: 'center',
  gap: '8px'
})`
  padding: 0 8px;
  height: 32px;
  &:not(.selected):hover {
    background-color: ${themeColor('surface-hover')};
  }
`

const ShowMore = styled(Text)`
  display: flex;
  justify-content: center;
  line-height: 32px;
  color: ${themeColor('text-light')};
  cursor: pointer;
  &:hover {
    color: ${themeColor('text')};
  }
`
