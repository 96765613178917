import { useCallback, useState } from 'react'

export const useForceRerender = () => {
  const [renderKey, setRenderKey] = useState<number>(1)

  const triggerRender = useCallback(() => {
    setRenderKey(prev => prev + 1)
  }, [])

  return { renderKey, triggerRender }
}
