import { memo, useCallback } from 'react'
import { useMatch } from 'react-router-dom'

import { Box, Pill, useTheme } from '@cutover/react-ui'
import { useSidebarNavigate, useViewPermissions } from '../hooks'
import { useSidebarNavContext } from '../nav-context'
import { NavItem, SidebarSection } from '../nav-item'
import { useLanguage } from 'main/services/hooks'
import { ConfigModel } from 'main/data-access'

export const SectionLinks = memo(({ compact }: { compact: boolean }) => {
  const { t } = useLanguage('sidebarNav', { keyPrefix: 'defaultNav' })
  const viewPermissions = useViewPermissions()
  const showAccessManagement = viewPermissions.access
  const { leftSidebarSize, toggleLeftSidebar } = useSidebarNavContext()
  const navigate = useSidebarNavigate()
  const isMyTasksEnabled = ConfigModel.useIsFeatureEnabled('my_tasks')
  const isHomeActive = useMatch('app/my-cutover')
  const isUsersActive = useMatch('app/access/users/list')
  const isMyTasksActive = useMatch('app/my-tasks')
  const theme = useTheme()

  const handleClickLink = useCallback((link: 'home' | 'users' | 'myTasks') => {
    const isActive =
      (link === 'home' && isHomeActive) ||
      (link === 'users' && isUsersActive) ||
      (link === 'myTasks' && isMyTasksActive)
    if (!isActive) {
      if (link === 'home') {
        navigate('/app/my-cutover')
      } else if (link === 'myTasks') {
        navigate('/app/my-tasks?run_type=live')
      } else {
        navigate('/app/access/users/list')
      }
    } else if (leftSidebarSize === 'full') {
      toggleLeftSidebar()
    }
  }, [])

  const handleClickHome = useCallback(() => {
    handleClickLink('home')
  }, [handleClickLink])

  const handleClickMyTasks = useCallback(() => {
    handleClickLink('myTasks')
  }, [handleClickLink])

  const handleClickAccessManagement = useCallback(() => {
    handleClickLink('users')
  }, [handleClickLink])

  return (
    <SidebarSection>
      <NavItem compact={compact} label={t('home')} isActive={!!isHomeActive} icon="home" onClick={handleClickHome} />
      {isMyTasksEnabled && (
        <NavItem
          compact={compact}
          label={t('myWork')}
          isActive={!!isMyTasksActive}
          icon="task-list"
          onClick={handleClickMyTasks}
          suffix={
            <Box pad="medium">
              <Pill label="New" color="feature-pink" size="small" themeType={theme.dark ? 'dark' : 'light'} />
            </Box>
          }
        />
      )}
      {showAccessManagement && (
        <NavItem
          label={t('users')}
          compact={compact}
          isActive={!!isUsersActive}
          icon="user-list"
          onClick={handleClickAccessManagement}
        />
      )}
    </SidebarSection>
  )
})
