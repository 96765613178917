import { memo, useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { Box, LoadingPanel, Message, RightPanel, Text } from '@cutover/react-ui'
import { RunbookListItem } from 'main/components/shared/runbook-list-item'
import { useRightPanelTypeValue, useSetActiveRightPanelState } from 'main/components/layout/right-panel'
import { useLanguage } from 'main/services/hooks'
import { useRouting } from 'main/services/routing/hooks'
import { useRunbooks } from 'main/services/queries/use-runbooks'
import { CustomField } from 'main/services/queries/types'

export const RunbookTemplatesWithDatasourcesPanel = () => {
  const { key, customField, templates } = useRightPanelTypeValue('runbook-templates-with-datasources')

  return customField && key ? (
    <TemplatesWithDataSources filterKey={key} customField={customField} templateCount={templates} />
  ) : null
}

type TemplatesWithDataSourcesProps = {
  filterKey: string
  customField: CustomField
  templateCount: number
}

const TemplatesWithDataSources = memo(({ filterKey, customField, templateCount }: TemplatesWithDataSourcesProps) => {
  const { t } = useLanguage('dataSources', { keyPrefix: 'workspacePanel' })
  const { closeRightPanel } = useSetActiveRightPanelState()

  const { accountId: accountSlug } = useParams<{ accountId: string }>()
  const { toRunbook } = useRouting()

  const createLinkTo = useCallback(
    (id: number) => toRunbook({ accountSlug: accountSlug as string, runbookId: id }),
    [accountSlug]
  )

  const fieldOption = customField.field_options.find(option => option.name === filterKey)
  const fieldOptionId = fieldOption?.id

  const serveryQuery = {
    f: {
      [customField.id]: [fieldOptionId]
    },
    accountId: accountSlug,
    is_template: true
  }

  const { data, isLoading } = useRunbooks(serveryQuery)
  const hiddenTemplatesCount = templateCount - (data?.runbooks?.length || 0)

  return isLoading ? (
    <LoadingPanel />
  ) : (
    <RightPanel title={t('title')} onClose={closeRightPanel}>
      {hiddenTemplatesCount > 0 && (
        <Box margin={{ bottom: 'medium' }}>
          {templateCount > 0 && <Message type="info" message={t('permissionInfo', { count: hiddenTemplatesCount })} />}
        </Box>
      )}
      <Box direction="row" gap="xsmall" css="margin-bottom: 8px;">
        <Text weight="bold">{customField.display_name || customField.name}:</Text>
        <Text truncate="tip">{filterKey}</Text>
      </Box>
      <Box>
        {data?.runbooks?.length === 0 && hiddenTemplatesCount === 0 && <Text>{t('noTemplates')}</Text>}
        {data?.runbooks?.map(template => {
          return <RunbookListItem key={template.id} runbook={template} createLinkTo={createLinkTo} />
        })}
      </Box>
    </RightPanel>
  )
})
