import { memo, useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
// TODO: mothballed feature, see below.
// import { isEmpty } from 'lodash'

import { Box, LoadingPanel, Message } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { CutoverLogo, PageWrap } from '../authentication/login-styled'
import { getIntegrationActionItem, usePostAppEvents } from './apps-api'
import { AppViews, ContentNode } from './apps-types'
import { AppsComponentParser } from './apps-component-parser'
import { useAppViewWithContextValue, useSetAppViews } from 'main/recoil/data-access'

export const AppsPage = () => {
  // integrationActionItemId here is actually the integration_action_item.key, per appId being keys.
  const { integrationActionItemId } = useParams()
  const [configError, setConfigError] = useState(false)
  const [searchParams] = useSearchParams()
  const { t } = useLanguage('apps')
  const pageStatusCode = searchParams.get('page_status_code')
  const runbookId = searchParams.get('runbook_id')
  const setAppViews = useSetAppViews()

  const postAppEvents = usePostAppEvents()
  const context = `${runbookId}-${integrationActionItemId}`

  useEffect(() => {
    if (integrationActionItemId && runbookId) {
      const initWithIntegrationActionId = async () => {
        // Despite having everything we need, worth using show call to ensure the id is correct
        const actionItem = await getIntegrationActionItem(integrationActionItemId)
        const appId = actionItem.integration_action_item.key

        if (actionItem.integration_action_item.settings.location !== 'Page') {
          throw new Error('We can only allow Page type apps')
        }

        if (actionItem.integration_action_item.archived) {
          throw new Error('This integration is archived')
        }

        const eventContext = pageStatusCode ? { page_status_code: pageStatusCode } : {}

        postAppEvents({
          context: eventContext,
          runbook_id: runbookId,
          app_id: appId
        })

        // Required to ensure visible boolean is set
        const appViews: AppViews = {}
        appViews[context] = {
          appId,
          resourceId: runbookId,
          content: [],
          visible: true,
          order: 1
        }
        setAppViews(appViews)
      }

      initWithIntegrationActionId().catch(() => {
        setConfigError(true)
      })
    }
  }, [integrationActionItemId, runbookId])

  const renderAppsContainerInnerComponent = () => {
    return (
      <>
        {integrationActionItemId && runbookId && (
          <>
            <AppsPageInner appId={integrationActionItemId} resourceId={runbookId} />
          </>
        )}
        {configError && (
          <Box margin={{ bottom: '12px' }}>
            <Message message={t('invalidAppsPage')} type="error" />
          </Box>
        )}
      </>
    )
  }

  return (
    <PageWrap>
      <CutoverLogo src="/branding/cutover/cutover-logo.png" alt="Cutover" />
      {renderAppsContainerInnerComponent()}
    </PageWrap>
  )
}

type AppsPageInnerProps = {
  appId: string
  resourceId?: string
}

const AppsPageInner = memo(({ appId, resourceId }: AppsPageInnerProps) => {
  const context = `${resourceId}-${appId}`
  const view = useAppViewWithContextValue(context)

  if (!view) {
    return null
  }

  // TODO: view never re-populates with new content.
  const appLoading = false // isEmpty(view.content)

  return (
    <>
      {appLoading ? (
        <LoadingPanel />
      ) : (
        <Box>
          <AppsComponentParser content={view.content as ContentNode[]} appId={appId} resourceId={resourceId} />
        </Box>
      )}
    </>
  )
})
